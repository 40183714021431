import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core'
import { SideNavService } from 'src/app/services/side-nav.service'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss', './header.scss'],
  animations: [
    trigger('panelOpen', [
      state('open', style({
        opacity: 1,
        pointerEvents: 'auto'
      })),
      transition('open => *', [
        animate('0.2s')
      ]),
      transition('* => open', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class PageHeaderComponent implements OnInit {
  @Output() logout = new EventEmitter<void>()
  account: any // AccountInfo TODO:
  showAdminPanel = false
  showHelpPanel = false
  breakPointWide = 1193
  canClickMenu=true

  constructor(
    private authService: AuthService,
    private sideNavService: SideNavService,
  ) { }

  ngOnInit(): void {
    this.account = this.authService.Account
    //サイドメニュー非表示切り替えの変更検知(Viewerはサイドメニュー非表示)
    this.sideNavService.navSource$.subscribe(ret => {
      this.canClickMenu=this.sideNavService.IsShowNave
    })
    this.resizeSetting()
  }

  get groupName(): string {
    // 先頭組織を表示(仮)
    return this.account.organizations[0].OrganizationName
  }

  get menuOpen(): boolean {
    return this.sideNavService.NavOpen
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.resizeSetting()
  }

  resizeSetting(): void {
    const open = window.innerWidth > this.breakPointWide ? true : false
    this.sideNavService.setNavOpen(open)
  }

  clickMenuBtn(): void {
    this.sideNavService.setNavOpen(!this.sideNavService.NavOpen)
    this.sideNavService.setNavClick(!this.sideNavService.NavOpen)
  }

  clickLogoutBtn(): void {
    this.logout.emit()
  }

  linkParam(): any {
    const param: any = {}
    // const sectionName = this.mdResultBaseService.DefaultGroup.sectionGroupName TODO:
    // param.section = sectionName  TODO:
    // return param TODO:

    return param.section = 'Test' // TODO:
  }

  mouseleaveAccountArea(event: any): void {
    if (!event.relatedTarget || event.relatedTarget.id !== 'headerAdminPanel') {
      this.showAdminPanel = false
    }
  }

  mouseleaveHelpPanel(event: any): void {
    if (!event.relatedTarget ||
      event.relatedTarget.id !== 'helpPanel') {
      this.showHelpPanel = false
    }
  }
}
