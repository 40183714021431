import { Component, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { wbsModel } from 'src/app/interfaces/wbs.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { YesNoDialogComponent } from 'src/app/components/dialogs/yes-no-dialog/yes-no-dialog.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { WbsService } from 'src/app/services/wbs.service';
import { UploadDialogComponent } from 'src/app/components/dialogs/upload-dialog/upload-dialog.component';
import { environment } from 'src/environments/environment';
import { BackendWbsProtoApiService } from 'src/app/api/backend-wbs-proto-api';
import { AuthService } from 'src/app/services/auth.service';
import { FileDLService } from 'src/app/api/file-dl.service';
import { SideNavService } from 'src/app/services/side-nav.service';
import { wbsViewerData } from '../localdata';
import { FrontEndParams } from '@interserve/igx-angular';

@Component({
  selector: 'app-wbs-viewer',
  templateUrl: './wbs-viewer.component.html',
  styleUrls: ['./wbs-viewer.component.scss']
})
export class WBSViewerComponent implements OnInit {
  @ViewChild('createWbsDialog') createWbsDialog?: YesNoDialogComponent
  @ViewChild('uploadFileDialog') uploadFileDialog?: UploadDialogComponent;
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  @ViewChild('yesNoDialog') yesNoDialog?: UploadDialogComponent;  

  public frontendCd="wbsViewerV2"
  public title='WBS Viewer' 

  public apiLoading=false
  public loading=false
  public isDialogOpen=false
  public isShowArea=true  //true=>エリア表示、false=>カセット表示
  public customerId=''
  public organizaionId=''

  public selectedArea:any
  
  public dialogParams =
  {
    messageCd: 'cassetteView',
    title: '',
    message: '',
    buttonId: '',
    yesButtonCaption:'アップロード',
    noButtonCaption:'閉じる'
  }
  public dialogParams_del_file =
  {
    messageCd: 'del_file',
    title: 'ファイル削除',
    message: '実行しますか？',
    buttonId: 'del_file',
    yesButtonCaption:'はい',
    noButtonCaption:'キャンセル'
  } 
  
  public targetWbs:wbsModel | undefined

  //WBSライブラリ用
  public frontEndParams!: FrontEndParams;
  
  constructor(
    private wbsService:WbsService,
    private backendWbsProtoApiService:BackendWbsProtoApiService,
    private fd: FileDLService,
    private activatedRoute: ActivatedRoute,
    private sideNavService: SideNavService,
    private router: Router,
  ) {}
  public selectedAreaId=''
  public updatable=false

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.selectedAreaId=params.get('areaId') ?? ''
      this.updatable=params.get('updatable')==='0'?false:true //0:閲覧のみ、1:編集可
      if(this.updatable===false){
        this.sideNavService.setNavOpen(false)
        this.sideNavService.setIsShowNave(false)
      }
    })    
    this.initialize() 
  }
  async initialize(){
    this.loading=true
    try {
        //フロントエンド設定をWBSに渡す
        let param={fileSystemId:this.selectedAreaId}
        this.frontEndParams = this.wbsService.setFrontendParams(this.frontendCd, param)

        this.loading=false

        if(this.updatable){
          this.title='ドキュメント管理／カセット一覧（ファイルアップロード・ダウンロード・削除）' //カセット表示
        }
        else{
          this.title='WBS Viewer'
        }
    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }
  dialogClose(){    
    this.createWbsDialog?.close()
    this.isDialogOpen=false
  }
  openUploadDialog(){
    this.uploadFileDialog?.open()
  }
  uploadError(value:any){
    this.snackbar.error(value)
  }
  async addFile(uploadObject: any){
    if(uploadObject!==null){
      this.apiLoading = true
      let uploadTitle=uploadObject.uploadTitle
      let uploadFile=uploadObject.File
      // APIでファイルアップロード
      const formData = new FormData();
      formData.append('file', uploadFile);  //キー名、データファイル、ファイル名
      console.log(formData.get('file'));

      if(this.targetWbs===undefined) return
      let cassetteId=this.targetWbs.id

      const postret$ = this.backendWbsProtoApiService.PostFileUpload(cassetteId,formData,uploadTitle)
      let postret  = await lastValueFrom(postret$).catch(() => {
        this.apiLoading = false
      })


      if(postret===undefined){
        this.snackbar.error("アップロードが失敗しました")
        this.apiLoading = false
        return
      }

      this.snackbar.success("ファイルがアップロードされました")
      this.apiLoading = false

      this.initialize()
    }
  }
  async downloadFile(selectedFile: any){
    if(this.apiLoading) return
    this.apiLoading = true

    const getFileData$ = this.backendWbsProtoApiService.GetFileDownload(selectedFile.id)
    let getret  = await lastValueFrom(getFileData$).catch(() => {
        this.apiLoading = false
    })
    if (getret) {     
      let downloadFileName=selectedFile.profiles.fileName       
      this.fd.downLoadFile(getret,getret.body.type,downloadFileName);       
    }

    this.apiLoading =false
  }
  clickSubMenu(arg: any){
    //アップロード
    if(arg.selectedMenu===1){
      if(arg.selectedData!==undefined){
        this.targetWbs=arg.selectedData
        this.isDialogOpen=true
        this.uploadFileDialog?.open()
      }       
    }
    //ダウンロード
    if(arg.selectedMenu===2){
      this.downloadFile(arg.selectedData)
    }
    //ファイルを削除
    if(arg.selectedMenu===6){
      this.targetWbs= arg.selectedData
      this.yesNoDialog?.open()
    }
  }
  gotoArea(){
    this.router.navigate([ `/select-area`]);
  }
  gotoCassetteList(){
    this.router.navigate(["/create-cassette"],{ queryParams: { id: this.selectedAreaId} });
  }
  async buttonClick(id: string){
    if(id==='del_file'){
      if(this.targetWbs!==undefined){  
        if(this.apiLoading) return
        this.apiLoading=true
        //カセット削除処理
        let delTree=[structuredClone(this.targetWbs)]          
        let delData=this.wbsService.getDelData(delTree)

        if(delTree.length>0){
          const delCassettes$ = this.backendWbsProtoApiService.DeleteCassetteMany(delData)
          const delCassettes = await lastValueFrom(delCassettes$).catch(() => {
            this.apiLoading=false
            return null
          })
          if(delCassettes){
            this.yesNoDialog?.close()
            this.snackbar.success('ファイルを削除しました')
            this.apiLoading=false
            this.initialize() 
          }    
        }
      }
    }
    this.apiLoading=false
  }
  dialogCancel(){
    this.yesNoDialog?.close()
  }
}
