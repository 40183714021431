<div class="container">
  <div class="form-group">
    <label>ファイル名</label>
    <input type="text" [(ngModel)]="fileName" />
  </div>

  <div class="form-group">
    <label>納品日</label>
    <div class="date-range">
      <input type="date" [(ngModel)]="startDate" />
      <span>~</span>
      <input type="date" [(ngModel)]="endDate" />
    </div>
  </div>

  <div class="form-group">
    <label>変更日</label>
    <input type="date" [(ngModel)]="dueDate" />
  </div>

  <div class="button-container">
    <button (click)="createContent()">作成</button>
    <button (click)="openTestDataHub()">選択</button>
  </div>

  <div style="margin: 20px;">
    <is-igx-hub
      *ngIf="!reload"
      [mappingData]="selectTestDataHubSettings"
      [wbsStaticData]="staticSelectTestData"
      [actions]="selectTestDataHubActions"
      #selectDataHub
    ></is-igx-hub>
  </div>

  <div class="message" *ngIf="showMessage">{{ message }}</div>
</div>
