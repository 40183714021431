<div class="wbs">
  <div class="area" [ngClass]="getAreaClass()">  
    <!-- <section class="leftWbs" *ngIf="leftWbsLayout && leftWbsData.length"> -->
    <section class="leftWbs" *ngIf="leftWbsLayout && leftWbsData.length>=0">
      @if(leftWbsLayout?.header){
      <header class="wbs-header">
        {{ leftWbsLayout?.header?.label }}
      </header>
      }

      <is-wbs-tree-grid
        *ngIf="!reloadFlg"
        #leftWbs
        [layout]="leftWbsLayout"
        [actions]="leftWbsActions"
        [wbsRules]="wbsRules"
        [gridRules]="leftWbsGridRules"
        [gridData]="leftWbsData"
        [id]="'leftWbs'"
        (clickSubMenu)="_clickSubMenu($event)"
      >
      </is-wbs-tree-grid>
    </section>

    <ng-container *ngIf="layout?.style?.direction === 'row' && layout?.rightWbs">
      <ng-container *ngTemplateOutlet="arrowRowLayout"></ng-container>
    </ng-container>
    <ng-container *ngIf="layout?.style?.direction === 'column' && layout?.rightWbs">
      <ng-container *ngTemplateOutlet="arrowColumnLayout"></ng-container>
    </ng-container>

    <!-- <section class="rightWbs" *ngIf="layout?.rightWbs && rightWbsData.length"> -->
    <section class="rightWbs" *ngIf="layout?.rightWbs">
      <is-wbs-tree-grid
        *ngIf="!reloadFlg"
        #rightWbs
        [layout]="layout?.rightWbs"
        [actions]="rightWbsActions"
        [gridRules]="rightWbsGridRules"
        [gridData]="rightWbsData"
        [id]="'rightWbs'"
      ></is-wbs-tree-grid>
      <div style="text-align: center;">
        <is-igx-button
          [buttonData]="hubButtonSetting"
          (click)="callHub()">
        </is-igx-button>
        <is-igx-button
          [buttonData]="catalogHubButtonSetting"
          (click)="callCatalogHub()">
        </is-igx-button>
      </div>
    </section>
  </div>
  
  <footer class="wbs-footer">
    @if(layout?.footer?.buttons){
      <p *ngFor="let button of layout?.footer?.buttons">
        <is-igx-button
          [buttonData]="button"
          (click)="footerAction(button.action)">
        </is-igx-button>
      </p>
    }
  </footer>
</div>

<ng-template #arrowRowLayout>
  <!-- <section class="arrow-area" [ngClass]="getAreaClass()"> -->
  <section class="arrow-area direction-column" >
    <div (click)="rightArrowClick()" class="rightArrow">
      <button class="arrow-btn arrow-btn-row">→</button>
    </div>
    <div (click)="leftArrowClick()" class="leftArrow">
      <button class="arrow-btn arrow-btn-row">←</button>
    </div>
  </section>
</ng-template>

<ng-template #arrowColumnLayout>
  <!-- <section class="arrow-area" [ngClass]="getAreaClass()"> -->
  <section class="arrow-area">
    <div (click)="rightArrowClick()" class="rightArrow">
      <button>↓</button>
    </div>
    <div (click)="leftArrowClick()" class="leftArrow">
      <button>↑</button>
    </div>
  </section>
</ng-template>  

<is-igx-hub
  #hub
  [mappingData]="mappingDataHub"
  [wbsStaticData]="hubWorkData"
  (wbsOrginalData)="getOrginalHubData($event)"
  [actions]="hubActions">
</is-igx-hub>

<is-igx-hub
  #catalogHub
  [mappingData]="mappingDataCatalogHub"
  [wbsStaticData]="catalogHubWorkData"
  (wbsOrginalData)="getOrginalHubData($event)"
  [actions]="catalogHubActions  ">
</is-igx-hub>

<is-igx-hub
  #selectedCatalogHub
  [mappingData]="selectedCatalogHubData"
  [wbsStaticData]="catalogHubWorkData"
  (wbsOrginalData)="getOrginalHubData($event)"
  [actions]="selectedCatalogHubActions  ">
</is-igx-hub>

<app-add-element-dialog
  #addDialog
  [element]="addElement"
  (clickAdd)="_clickAdd($event)"
></app-add-element-dialog>

<is-igx-dialog-message
    #messageDialog
    [messageCd]="dialogMessageCd"
    (click)="footerAction($event)" 
></is-igx-dialog-message>