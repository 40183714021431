import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
// import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SelectedFileDialogComponent } from '../selected-file-dialog/selected-file-dialog.component';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
    @ViewChild('uploadDialog', { read: IgxDialogComponent, static: true })
    uploadDialog: IgxDialogComponent | undefined;
    
    @ViewChild('selectedFileDialog', { read: SelectedFileDialogComponent, static: true })
    selectedFileDialog: SelectedFileDialogComponent | undefined;
    
    // @ViewChild('errorDialog', { read: ErrorDialogComponent, static: true })
    // errorDialog: ErrorDialogComponent | undefined;    

    @ViewChild('file', { static: true }) 
    public file!: ElementRef;
    @Input() fileType:string='text/csv'
    @Output() error = new EventEmitter()
    @Output() clickUpload = new EventEmitter()

    constructor(
    ) { }
    public inputUrl=''  
    public uploadfile: File | undefined;
    // public fileType='text/csv'    
    // public fileType="image/png,image/jpeg,image/gif,application/pdf"
    public fileTypeExpanded=''

    private maxFileSize = 5242880 //5242880byte=5MB

    ngOnInit(): void {
    }
    
    open() {
        if (this.uploadDialog) this.uploadDialog.open();
      }
    close() {
        if (this.uploadDialog) this.uploadDialog.close();   
    }

    /**
     * ファイルをドロップしたときのイベント
     * @param event - ドラッグされたファイル
     */
    onDrop(event: DragEvent) {
      
      event.preventDefault(); //これがないと画像をブラウザで開いてしまいます。
      let file = event.dataTransfer?.files;
      if(file){
        if (file.length) {
          let fileList = Object.entries(file).map((f) => f[1]);
          this.uploadfile = fileList[0];
          
          //ファイルタイプチェック
          if(!this.checkFileType(this.uploadfile)) return
          //サイズチェック　とりあえずサイズ制限なし
          if(!this.checkFileSize(this.uploadfile)) return

          if(this.uploadfile&&this.selectedFileDialog){
            //確認用ダイアログを表示
            this.selectedFileDialog.open();        
          }
        }
      }
    }

    dragOver(event: DragEvent) {
      event.preventDefault();
    }

    change(fileList: any) {
      //ファイルタイプチェック
      if(!this.checkFileType(fileList[0])) return
      //サイズチェック　とりあえずサイズ制限なし
      if(!this.checkFileSize(fileList[0])) return

      this.uploadfile = fileList[0];
      if(this.uploadfile&&this.selectedFileDialog){
        //確認用ダイアログを表示
        this.selectedFileDialog.open();        
      }
      this.file.nativeElement.value = '';
    }

    checkFileType(file:any):boolean{
      //ファイルチェック    
      // if(['text/csv'].includes(file.type)){
      let fileTypeArray=this.fileType.split(',')
      if(fileTypeArray.includes(file.type)){    
        return true
      }
      this.error.emit('サポート対象外のファイルタイプです')
      return false      
    }

    checkFileSize(file:any):boolean{
      //ファイルサイズチェック
      if(file.size>this.maxFileSize){
        this.error.emit('ファイルサイズは最大5MBまでです')
        return false
      }
      return true
    }

    //確認ダイアログでアップロード実行クリックされたら
    upload(uploadTitle:string){
      let uploadObject={
        uploadTitle:uploadTitle,
        File:this.uploadfile
      }
      //アップロード処理
      this.clickUpload.emit(uploadObject)
      this.close()
    }
}
