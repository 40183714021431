<!-- <igx-dialog #hubDialog>
    <is-igx-hub
        [mappingData]="hubSettings"
        [actions]="wbsHubActions"
        [useIgxDialog]="false">    
    </is-igx-hub> 
</igx-dialog> -->

<is-igx-hub #hubDialog
    [mappingData]="hubSettings"
    [actions]="wbsHubActions">    
</is-igx-hub> 