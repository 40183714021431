<div class="l-main__contents">
    <div id="wrapper">
      <div class="c-text-1 h-margin-b24">{{title}}</div>
      <div class="grid-area">
        <div class ="loading" *ngIf="loading">
          <app-loding-circle></app-loding-circle>
        </div>
          <div class="wbs-area">
            <!-- <is-igx-mapping *ngIf="!loading" 
              [mappingData]="mappingData"
              [mappingDataHub]="mappingDataHub"    
              (clickSubMenu)="clickSubMenu($event)"
              (clickPlusElement)="clickPlusElement($event)"
              >
            </is-igx-mapping>  -->
            <is-igx-mapping *ngIf="!loading" 
                [frontEndParams]="mappingParams"
                [frontEndHubParams]="hubParams"    
                (clickSubMenu)="clickSubMenu($event)"
                (clickPlusElement)="clickPlusElement($event)"
                >
            </is-igx-mapping> 
          </div>
      </div>
    </div>
</div>
<app-snackbar #snackbar [autoHide]="true"></app-snackbar>