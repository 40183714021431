import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { IsIgxHubComponent } from '@interserve/igx-angular';

@Component({
  selector: 'app-hub-dialog',
  templateUrl: './hub-dialog.component.html',
  styleUrls: ['./hub-dialog.component.scss']
})
export class HubDialogComponent implements OnInit {
    @Input() hubSettings:any|undefined  
    @Output() clickSelectItem = new EventEmitter<any>()    
    @Output() closed = new EventEmitter() 
    // @ViewChild('hubDialog', { static: true }) public hubDialog: IgxDialogComponent | undefined
    @ViewChild('hubDialog', { static: true }) public hubDialog: IsIgxHubComponent | undefined

    constructor(
    ) { }

    public selectedItem: any
    public inputTitle:string=''

    wbsHubActions = {
      cellClick: (event: any) => {
        if (event.newSelection.length) {
          this.selectedItem = event.newSelection
        } else {
          this.selectedItem = []
        }     
      },
      leftWbsLeftFooterAction: (event: any) => {
        this.hubDialog?.close()
      },
      leftWbsRightFooterAction: (event: any) => {
        if (this.selectedItem.length===0) {
        //   alert('選択してください。')
        } else {   
          this.clickSelectItem.emit(this.selectedItem)
          this.close()
        }
      },
    }
    ngOnInit(): void {
    }
    open() {      
      this.hubDialog?.open()
    }
    close() {
      if (this.hubDialog) this.hubDialog.close()
    }
}
