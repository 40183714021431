import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-selected-file-dialog',
  templateUrl: './selected-file-dialog.component.html',
  styleUrls: ['./selected-file-dialog.component.scss']
})
export class SelectedFileDialogComponent implements OnInit {
    @ViewChild('selectedFileDialog', { read: IgxDialogComponent, static: true })
    selectedFileDialog: IgxDialogComponent | undefined;
    
    @Input() uploadfile: File | undefined
    @Output() clickUpload = new EventEmitter()

    constructor(
    ) { }
    public inputUrl=''  
    public uploadTitle=''
    public buttonData: any = [
      {
        "key": "cancel",
        "name": "キャンセル",
        "color": "cancel",
        "disabled":false
      },
      {
        "key": "run",
        "name": "実行",
        "color": "primary",
        "disabled":false
      },
    ]
    ngOnInit(): void {
    }
    
    open() {
        this.uploadTitle=this.uploadfile?.name??'' //タイトルのデフォルトはファイル名  
        if (this.selectedFileDialog) this.selectedFileDialog.open();
        
        //アップロードボタンを有効にする
        // const button = <HTMLInputElement>document.getElementById('UploadButton');
        // button.disabled = false;
      }
    close() {
        if (this.selectedFileDialog) this.selectedFileDialog.close();
    }

    ngOnChanges(changes: SimpleChanges){
      if (changes['uploadfile']) {
        if(this.uploadfile!==undefined){
          this.uploadTitle=this.uploadfile.name  //タイトルのデフォルトはファイル名 
        }        
      }      
    }
    
    async buttonClick(id: string) {    
      switch (id){
        case 'cancel':        
        this.close()
          break
        case 'run':
          if(this.uploadTitle==='') this.uploadTitle=this.uploadfile?.name??''
          this.clickUpload.emit(this.uploadTitle)      
          this.close()  
          break   
      }    
    }
}
