import { Component, OnInit } from '@angular/core'
import { environment } from '../../environments/environment'
import { AuthService } from '../services/auth.service'

declare const window: { location: { origin: string }; BeLoginCore: { login: () => void } }
@Component({
  selector: 'app-isbeauth',
  template: '',
})
export class IsbeauthComponent implements OnInit {
  loginUrl = location.protocol +  environment.login.isbeLogin
  baseUrl = window.location.origin
  core: any
  appLogo = `${document.baseURI}assets/images/common/logo.png`
  isAppCd = 'garage_sales_support'
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    //ログイン処理
    const that = this
    const isbeLoginScriptPath = environment.login.isbeLoginScript
    const isbeLoginScriptElem = document.createElement('script')

    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)
    isbeLoginScriptElem.onload = function () {
      that.core = window.BeLoginCore
      that.core.initialize(that)
      isbeLoginScriptElem.onload = null
    }
  }
  // onBeginLogin = (): void => {
  //   // alert('セッションが切れました、ログインし直してください。')
  //   location.href = environment.myidea
  //   // window.BeLoginCore.login();
  // }

  // ログイン後処理
  onEndLogin = (account: string): void => {
    
    this.authService.setAccount(this.convertCamel(account))
    this.authService.readStrage()

  }

  convertCamel = (stringJson: string) => {
    //jsonKeyを小文字始まりに変換
    const json = JSON.parse(stringJson, function (key, value) {
      if (value && typeof value === 'object')
        for (var k in value) {
          if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
            value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k]
            delete value[k]
          }
        }
      return value
    })
    return json
  }
  onLogout(): void {
    window.BeLoginCore.login();
  }
}
