import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  private navOpenSource = new Subject<boolean>()
  public navOpenSource$ = this.navOpenSource.asObservable()
  private navClickSource = new Subject<boolean>()
  public navClickSource$ = this.navClickSource.asObservable()
  breakPointWide = 1193
  private navOpen: boolean = window.innerWidth > this.breakPointWide ? true : false

  private isShowNave:boolean=true
  private navSource = new Subject<boolean>()
  navSource$ = this.navSource.asObservable()

  constructor() { }

  get NavOpen(): boolean {
    return this.navOpen
  }
  setNavOpen(isOpen: boolean): void {
    this.navOpenSource.next(isOpen)
    this.navOpen = isOpen
  }
  setNavClick(isOpen: boolean): void {
    this.navClickSource.next(isOpen)
  }
  get IsShowNave(): boolean {
    return this.isShowNave
  }
  setIsShowNave(isShowNave: boolean): void {
    this.isShowNave=isShowNave
    this.navSource.next(isShowNave)
  }
}
