<igx-dialog
  #selectedFileDialog
  [closeOnOutsideSelect]="false"
  >
  <div class="dialog-header">
    <div class="dialog-description">
      アップロード
    </div>
    <div class="position-right">
      <a (click)="close()">
          <span class="material-icons icon-style">
              cancel
          </span>
      </a>  
    </div>
  </div>
  
  <div class="dialog-text">
    タイトル
  </div>
  <div class="selected-filename">
    <input class="input-title" [(ngModel)]="uploadTitle">
  </div>

  <div class="dialog-text">
    選択されたファイル
  </div>
  <div *ngIf="uploadfile" class="selected-filename" unselectable="on">
    <span>{{ uploadfile.name }}</span>
  </div>

  <footer class="modal__footer">
    <ul class="flex-center">
      <li class="c-bottom-nav__item button-margin" *ngFor="let button of buttonData">
        <app-button
          [disable]="button.disable"
          [hidden]="button.hidden || false"
          [color]="button.color"
          (click)="buttonClick(button.key)"
        >
          {{ button.name }}
        </app-button>
      </li>
    </ul>
  </footer>
</igx-dialog>