import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
  } from '@angular/core';
import { ButtonSetting } from '../frontEndSetting';
import { AddElementSettingComponent } from '../add-element-setting/add-element-setting.component';
import { IsIgxDialogComponent } from '@interserve/igx-angular';
import { IsIgxButtonComponent } from '../is-igx-button/is-igx-button.component';

  @Component({
    selector: 'app-add-element-dialog',
    standalone: true,
    templateUrl: './add-element-dialog.component.html',
    styleUrls: ['./add-element-dialog.component.scss'],
    imports: [CommonModule, IsIgxDialogComponent,AddElementSettingComponent,IsIgxButtonComponent],  
  })
  export class AddElementDialogComponent implements OnInit {
    @ViewChild('modalDialog', { static: true }) public modalDialog: IsIgxDialogComponent | undefined
    @ViewChild('addSetting', { read: AddElementSettingComponent, static: true }) addSetting!: AddElementSettingComponent;    
    @Input() element:any | undefined;
    @Output() clickAdd = new EventEmitter<any>();
    @Output() closed = new EventEmitter();
    
    constructor(
    ) { }

    buttonData:ButtonSetting[] = [
      {
        key: "cancel",
        id:"0",
        name: "cancel-button",
        label: "キャンセル",
        color: "cancel",
        disable: false,
        hidden: false,
        action:'cancel'
      },
      {
        key: "select",
        id:"1",
        name: "add-button",
        label: "追加",
        color: "primary",
        disable: false,
        hidden: false,
        action: "select"
      }
    ]
    ngOnInit(): void {        
     }
    open() {
        if (this.modalDialog) this.modalDialog.open();
    }
    close() {
        this.closed.emit()
        if (this.modalDialog) this.modalDialog.close();
    }   
    _clickButton(value:any){
      switch(value){
        case '0':
          this.close()
          break
        case '1':
          if(this.element.name.length>0){          
            this.clickAdd.emit(this.element)
            this.close()
          }
          break
      }
    }
  }
  