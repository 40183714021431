<div class="l-main__contents">
    <div id="wrapper">
      <div class="c-text-1 h-margin-b24">{{title}}</div>
      <div class="grid-area">
        <div class ="loading" *ngIf="loading">
          <app-loding-circle></app-loding-circle>
        </div>
        <div class="cassette-list-header">
          <a class="goto-area" (click)="gotoArea()">
            エリア選択に戻る
          </a>
          <a class="goto-cassette-list" (click)="gotoCassetteList()">
            カセット一覧（ファイルUL・DL・削除）へ
          </a>
        </div>
        <!-- <is-igx-mapping *ngIf="!loading" 
          [mappingData]="mappingData"
          [mappingDataHub]="mappingDataHub"    
          (clickSubMenu)="clickSubMenu($event)"
          (clickPlusElement)="clickPlusElement($event)"
          >
        </is-igx-mapping>  -->
        <is-igx-mapping *ngIf="!loading" 
            [frontEndParams]="mappingParams"
            [frontEndHubParams]="hubParams"    
            (clickSubMenu)="clickSubMenu($event)"
            (clickPlusElement)="clickPlusElement($event)"
            >
        </is-igx-mapping> 
      </div>
    </div>
  </div>