import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { PetaboComponent } from '../../petabo/petabo.component';

@Component({
  selector: 'app-petabo-dialog',
  templateUrl: './petabo-dialog.component.html',
  styleUrls: ['./petabo-dialog.component.scss'],
})
export class PetaboDialogComponent implements OnInit {
  @ViewChild('modaldialog', { read: IgxDialogComponent, static: true })
  modaldialog: IgxDialogComponent | undefined;
  @ViewChild('petabo') petabo!: PetaboComponent
  
  @Input() headerMessage = '';
  @Input() message = '';
  @Input() buttonId = '';
  @Input() isShowCloseButton = true;
  @Input() yesButtonCaption = 'はい';
  @Input() noButtonCaption = 'いいえ';
  @Output() clickYesButton = new EventEmitter();
  @Output() clickNoButton = new EventEmitter();
  @Output() closed = new EventEmitter();
  isOpen = false;
  constructor() { }

  ngOnInit(): void { }
  open(petaboFqdn: string, title: string, openMode:string) {
    this.petabo.open(petaboFqdn, title, openMode) //ペタボIDとタイトルを指定
    if (this.modaldialog) this.modaldialog.open();
  }
  close() {
    if (this.modaldialog) this.modaldialog.close();
  }
  _clickYesButton() {
    this.clickYesButton.emit(this.buttonId);
  }
  _clickNoButton() {
    this.clickNoButton.emit(this.buttonId);
  }
  /**
   * 閉じたあとのイベント
   */
  _closed() {
    this.closed.emit()
  }
}
