<is-igx-dialog #modalDialog>
    <header class="dialog-title">要素の追加</header>
    <app-add-element-setting
        #addSetting
        *ngIf="element"
        [(element)]="element"
    ></app-add-element-setting>
    <div class="wbs-footer">    
        <p *ngFor="let button of buttonData">
            <is-igx-button
              [buttonData]="button"
              (click)="_clickButton(button.id)">
            </is-igx-button>
          </p>
    </div>
</is-igx-dialog>