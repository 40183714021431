<div [ngClass]="buttonColor" *ngIf="!hidden">
  <a *ngIf="!loding; else lodingTemp">
    <ng-content></ng-content>
  </a>
</div>

<ng-template #lodingTemp>
  <a style="display: flex; justify-content: center; align-content: center">
    <app-loding-circle></app-loding-circle>
  </a>
</ng-template>
