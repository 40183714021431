import { Injectable } from '@angular/core'
import { WBSModel } from '../interfaces/wbs.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  /**
   * フラットから階層構造に変換
   * @param WBSModel[]
   * @returns
   */    
  public getNests(m: WBSModel[]) {
    const s = this
    let targets = m.filter((e: any) => {
      return !e.parentCellId
    })
    let targets_c = targets.sort(function(a, b) {
      return (a.priority < b.priority) ? -1 : 1;  //オブジェクトの昇順ソート
    });
    targets = JSON.parse(JSON.stringify(targets_c))
    for (let i = 0; i < targets.length; i++) {
      s.setChildren(m, targets[i])
    }
    return targets
  }
  private setChildren(m: WBSModel[], o: WBSModel) {
    let children = m.filter((e: any) => {
      return e.parentCellId == o.cellId
    })
    let sorted_c = children.sort(function(a, b) {
      return (a.priority < b.priority) ? -1 : 1;  //オブジェクトの昇順ソート
    });  
    children = JSON.parse(JSON.stringify(sorted_c))
    if (children.length) {
      for (let i = 0; i < children.length; i++) {
        this.setChildren(m, children[i])
      }
    }
    o['children'] = children
  }

  
}
