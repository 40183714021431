import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CreateAreaV1Component } from './views/create-area-v1/create-area-v1.component'
import { CreateCassetteComponent } from './views/create-cassette/create-cassette.component'
import { SelectAreaComponent } from './views/select-area/select-area.component'
import { DashboardComponent } from './views/dashboard/dashboard.component'
import { WBSViewerComponent } from './views/wbs-viewer/wbs-viewer.component'
import { SelectAreaV1Component } from './views/select-area-v1/select-area-v1.component'
import { WBSItemViewerComponent } from './views/wbs-item-viewer/wbs-item-viewer.component'
import { SelectWbsItemComponent } from './views/select-wbs-item/select-wbs-item.component'
import { CreateAreaComponent } from './views/create-area/create-area.component'
import { IsIgxWbsSampleComponent } from './views/test/is-igx-wbs-sample.component'
import { SampleTestDataCreateComponent } from './views/sample-testdata-create/SampleTestDataCreateComponent'
import { SampleTestDataWorkDate } from './views/sample-testdata-workdate/SampleTestDataWorkDate'
import { UpdateWbsItemComponent } from './views/update-wbs-item/update-wbs-item.component';

const routes: Routes = [
    {
      path: 'select-area',
      component:SelectAreaComponent,
      data: { title: 'ドキュメント管理'},
    },
    {
      path: 'create-area',
      component:CreateAreaComponent,
      data: { title: 'エリア作成'},
    },
    // {
    //   path: 'create-area-v1',
    //   component:CreateAreaV1Component,
    //   data: { title: 'エリア作成(iframe版)'},
    // },
    {
      path: 'create-cassette',
      component:CreateCassetteComponent,
      // data: { title: 'カセット作成'},
    },
    {
      path: 'wbs-viewer/:areaId/:updatable',
      component:WBSViewerComponent,
    },
    {
      path: 'select-wbs-item/:areaId',
      component:SelectWbsItemComponent,
    },
    {
      path: 'wbs-item-viewer/:areaId/:wbsItemId/:updatable',
      component:WBSItemViewerComponent,
    },
    {
      path: 'update-wbs-item/:areaId/:wbsItemId',
      component:UpdateWbsItemComponent,
    },
    //後で消す
    {
      path: 'dashboard',
      component:DashboardComponent,
      data: { title: 'ペタボ連携確認用'},
    },
    //後で消す
    {
      path: 'mapping-sample',
      component:IsIgxWbsSampleComponent,
      data: { title: '物流支援サンプル'},
    },
    {
      path: 'テストデータ作成',
      component:SampleTestDataCreateComponent,
      data: { title: 'テストデータ作成'},
    },
  {
    path: 'テストデータ選択',
    component:SampleTestDataCreateComponent,
    data: { title: 'テストデータ選択'},
  },
  {
    path: '納品日指定',
    component:SampleTestDataWorkDate,
    data: { title: '納品日指定'},
  },

    { path: '',
      redirectTo: '/select-area',
      pathMatch: 'full'
    },
]
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
