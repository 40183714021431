export const selectAreaData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetAreas",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs"],
      "height":"400px",
      "width":"600px",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ],
      "showMenu": true
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": false,
      "rowSelection": "single",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    }
  },
  "rules": {
    "area": {
      "menu": [
          {  "menuCd": 3, "menuName": "カセット表示"},
          {  "menuCd": 4, "menuName": "カセット作成・移動・削除"},
          {  "menuCd": 7, "menuName": "アップロードアイテム表示" }
      ]
    }
  },
  "actions": {
    "GetAreas": {
      "apiInfo": {
        "url": "//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/{customerId}",
        "urlObject": {
          "customerId":""
        }
      }
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "save": {
      "event": "onSave"
    },
    "cancel": {
      "event": "footerCancel"
    },
    "click": {}
  }
}

export const wbsViewerData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetWbs",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs"],
      "height":"400px",
      "width":"600px",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ],
      "showMenu": true        
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": false,
      "rowSelection": "single",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    }
  },
  "rules": {
    "cassette": {
      "menu": [
          {  "menuCd": 1, "menuName": "ファイルアップロード"}
      ]
    },
    "file": {
      "menu": [
          {  "menuCd": 2, "menuName": "ファイルダウンロード"},
          {  "menuCd": 6, "menuName": "ファイルを削除" }
      ]
    }
  },    
  "actions": {
    "GetWbs": {
      "apiInfo": {
        "url": "//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexWbs/nodeObjects/all/",
        "urlObject": {            
          "queryParams": {
            "fileSystemId": "",
            "rootOnly": false,
            "map": "files"
          }
        }
      }
    },      
    "load": {        
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "save": {
      "event": "onSave"
    },
    "cancel": {
      "event": "footerCancel"
    },
    "click": {}
  }
}

export const SelectwbsItemData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetWbs",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs"],
      "height":"400px",
      "width":"600px",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ],
      "showMenu": true
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": false,
      "rowSelection": "single",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    }
  },
  "rules": {
    "wbs-item": {
      "menu": [
        { "menuCd": 7, "menuName": "アップロードアイテム表示" }
      ]
    }
  },    
  "actions": {
    "GetWbs": {
      "apiInfo": {
        "url": "//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/wbsItem/list",
        "urlObject": {            
          "queryParams": {
            "fileSystemId": ""
          }
        }
      }
    },      
    "load": {
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "save": {
      "event": "onSave"
    },
    "cancel": {
      "event": "footerCancel"
    },
    "click": {}      
  }
}

export const wbsItemViewerData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetWbs",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs"],
      "height":"400px",
      "width":"600px",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ],
      "showMenu": true
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": false,
      "rowSelection": "single",
      "fields": [
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    }
  },
  "rules": {      
    "file": {
      "menu": [
        { "menuCd": 2, "menuName": "ファイルダウンロード" }
      ]
    }
  },    
  "actions": {      
    "GetWbs": {
      "apiInfo": {
        "url": "//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexWbs/nodeObjects/all/",
        "urlObject": {            
          "queryParams": {
            "fileSystemId": "",
            "rootOnly": false,
            "map": "files"
          }
        }
      }
    },      
    "load": {        
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "save": {
      "event": "onSave"
    },
    "cancel": {
      "event": "footerCancel"
    },
    "click": {}      
  }
}

export const dashboardCatalogHubInfo = {
  "items ": {
    "type ": "list",
    "dialog ": {		
      "width":"100%",
      "maxHeight":"100%"
    }
  },
  "layout": {
    "leftWbs": {
    "loadAction": "getWbsItem",
    "header": {
        "label": "商品カタログを選択"
    },
    "footer": {
      "rightButton": {        
        "key": "select",
        "name": "right-button",
        "label": "選択",
        "color": "primary",
        "disabled":false
      },
      "leftButton": {
        "key": "cancel",
        "name": "left-button",
        "label": "キャンセル",
        "color": "cancel",
        "disabled":false
      }
    }
    },
    "leftWbsRules": {
    "childDataKey": "nodeObjects",
    "hideHeader": false,
    "height":"300px",
    "width":"400px",
    "rowSelection": "single",
    "noSelectionWbsTypes": ["catalog"],
    "fields": [
        {
        "name": "name",
        "label": "名称",
        "width": "calc(100% - 60px)",
        "dataType": "string"
        }
    ]
    }
},
"rules": {
    "template": {}
},
"actions": {
    "getWbsItem": {
    "event": "",
    "apiInfo": {
        "url": "//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexWbs/fqdn",
        "urlObject": {            
          "queryParams": {
            "fqdn": "catalog.prototype.nameCardList.narrowing.services"            
          }          
        }
      }
    }
}
}
  
export const AreaMappingData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetAreas",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs","plusButton"],
      "canMoveTopNode":false,
      "height":"300px",
      "width":"100%",
      "showMenu":false,
      "fields": [        
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    },
    "rightWbs": {
      "loadAction": "",
      "header": {}
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs","plusButton"],
      "height":"300px",
      "width":"100%",
      "showMenu":false,
      "fields": [        
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    },
    "footer":{
      "buttons ": [
          {"key": "cancel",
          "id": "1",
          "name": "cancel-button",
          "label": "元に戻す",
          "color": "cancel",
          "disable": false,
          "hidden": false,
          "action":"revert"},
          {"key": "register",
          "id": "2",
          "name": "save-button",
          "label": "登録する",
          "color": "primary",
          "disable": false,
          "hidden": false,
          "action":"dialogBeforesave"}
      ]
    }
  },
  "rules": {
    "area": {
      "rightArrow": {
        "action": "backward"
      },
      "leftArrow": {
        "action": "forward"
      }      
    }
  },
  "actions": {
    "GetAreas": {
      "apiInfo": {
        "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/{customerId}",
        "urlObject": {
          "customerId":""
        }
      }
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "dialogBeforesave":{
      "event": "showSaveDialog",
      "messageCd": "confirmRun"
    },
    "save": {
      "event": "onSave",
      "apiInfo":[ 
        {"apiNo":0,
          "apiName":"PostAreaMany",
          "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/children",
          "payload": {},
          "apiMethod":"post"            
        },
        {"apiNo":1,
          "apiName":"DeleteAreaMany",
          "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/children",
          "payload": {},
          "apiMethod":"delete"            
        }
      ]
    },
    "cancel": {
      "event": "dialogCancel"
    },
    "revert": {
      "event": "footerCancel"
    },
    "ok":{
      "event": "dialogCancel"
    },
    "click": {}
  }
}

export const AreaMappingHubData = {
  "items": {
      "type": "list",
      "dialog": {
          "width": "500px",
          "maxHeight": "100%"
      }
  },
  "layout": {
      "leftWbs": {
          "loadAction": "",
          "addButtonAction": "plusButtonAction",
          "header": {
              "label": "エリアを追加"
          },
          "footer": {
              "rightButton": {
                  "key": "select",
                  "id": "1",
                  "name": "select-button",
                  "label": "選択",
                  "color": "primary",
                  "disable": false,
                  "hidden": false
              },
              "leftButton": {
                  "key": "cancel",
                  "id": "0",
                  "name": "cancel-button",
                  "label": "キャンセル",
                  "color": "cancel",
                  "disable": false,
                  "hidden": false
              }
          }
      },
      "leftWbsRules": {
          "childDataKey": "",
          "hideHeader": false,
          "height": "300px",
          "width": "100%",
          "rowSelection": "multiple",
          "noSelectionWbsTypes": [
              "plusButton"
          ],
          "canAddElement": true,
          "addElementType": "area",
          "canUseAddDialog": true,
          "fields": [
              {
                  "name": "name",
                  "label": "名称",
                  "width": "100%",
                  "dataType": "string"
              }
          ]
      }
  },
  "rules": {
      "template": {}
  },
  "actions": {
      "plusButtonAction": {
          "event": "onClickPlus"
      },
      "loadHub": {
          "event": "onLoadHub",
          "defaultData": []
      }
  }
}
export const CassetteMappingData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetAreaCassettes",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs","plusButton"],
      "canMoveTopNode":false,
      "height":"300px",
      "width":"100%",
      "showMenu":false,
      "fields": [        
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    },
    "rightWbs": {
      "loadAction": "",
      "header": {}
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs","plusButton"],
      "height":"300px",
      "width":"100%",
      "showMenu":false,
      "fields": [        
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    },
    "footer":{
      "buttons": [
          {"key": "cancel",
          "id": "1",
          "name": "cancel-button",
          "label": "元に戻す",
          "color": "cancel",
          "disable": false,
          "hidden": false,
          "action":"revert"},
          {"key": "register",
          "id": "2",
          "name": "save-button",
          "label": "登録する",
          "color": "primary",
          "disable": false,
          "hidden": false,
          "action":"dialogBeforesave"}
      ]
    }
  },
  "rules": {
    "cassette": {
      "rightArrow": {
        "action": "backward"
      },
      "leftArrow": {
        "action": "forward"
      }      
    }
  },
  "actions": {
    "GetAreaCassettes": {
      "apiInfo": {
        "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/cassette/areamixed/list/{customerId}",
        "urlObject": {
          "customerId":"",
          "queryParams": {
            "areaId": ""
          }
        }
      }
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "dialogBeforesave":{
      "event": "showSaveDialog",
      "messageCd": "confirmRun"
    },
    "save": {
      "event": "onSave",
      "apiInfo":[ 
        {"apiNo":0,
          "apiName":"PostCassetteMany",
          "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/cassette/many",
          "payload": {},
          "apiMethod":"post"
        },
        {"apiNo":1,
          "apiName":"DeleteCassetteMany",
          "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/cassette/relation/many",
          "payload": {},
          "apiMethod":"delete"
        }
      ]
    },
    "cancel": {
      "event": "dialogCancel"
    },
    "revert": {
      "event": "footerCancel"
    },
    "ok":{
      "event": "dialogCancel"
    },
    "click": {}
  }
}
export const CassetteMappingHubData = {
  "items": {
    "type":"list",
    "dialog": {		
      "width":"500px",
      "maxHeight":"100%"
    }
  },
  "layout": {    
    "leftWbs": {
      "loadAction":"",
      "addButtonAction":"plusButtonAction",
      "header": {
        "label":"カセットを追加"
      },
      "footer": {
        "rightButton": {
          "key":"select",
          "id":"1",
          "name":"select-button",
          "label":"選択",
          "color":"primary",
          "disable": false,
          "hidden": false
        },
        "leftButton": {
          "key":"cancel",
          "id":"0",
          "name":"cancel-button",
          "label":"キャンセル",
          "color":"cancel",
          "disable": false,
          "hidden": false
        }
      }
    },
    "leftWbsRules": {
      "childDataKey":"",
      "hideHeader": false,
      "height":"300px",
      "width":"100%",
      "rowSelection":"multiple",
      "noSelectionWbsTypes": ["plusButton"],
      "canAddElement": true,
      "addElementType":"cassette",
      "canUseAddDialog":true,
      "fields": [
        {
          "name":"name",
          "label":"名称",
          "width":"100%",
          "dataType":"string"
        }
      ]
    }
  },
  "rules": {
    "template": {}
  },
  "actions": {
    "plusButtonAction": {
      "event":"onClickPlus"
    },
    "loadHub": {
      "event":"onLoadHub",
      "defaultData":[]        
    }
  }
}

// ここから下、FrontEnd設定バージョン2
// (ベースパス・アドレス・画面レイアウトでファイルを分ける)
export const documentWbsBasePath =
{
  "indexAppCd": "documentWbs",
  "frontendCd": "basePaths",
  "name": "APIのベースパス情報",
  "settingType": "api",
  "target": "service",
  "items": {
    "dev": {
      "documentWbsApi": "//backend-wbsprotoapi.backend.out.nakamenosakura.com",
    },
    "live": {
      "documentWbsApi": "//xxxxxx",
    }
  }
}
// アドレス
export const documentWbsApiInfo ={
  "indexAppCd": "documentWbs",
  "frontendCd": "address",
  "name": "APIアドレス情報",
  "settingType": "api",
  "target": "service",
  "items": {
      "endpoints": {
          "getAreas": {
              "name": "エリア情報取得",
              "basePathKey": "documentWbsApi",
              "path": "/api/area/{customerId}",
              "bodyKey": null,
              "method": "get"
          },
          "postAreaMany": {
              "name": "エリア情報登録",
              "basePathKey": "documentWbsApi",
              "path": "/api/area/children",
              "bodyKey": "payload",
              "method": "post"
          },
          "deleteAreaMany": {
              "name": "エリア情報削除",
              "basePathKey": "documentWbsApi",
              "path": "/api/area/children",
              "bodyKey": "payload",
              "method": "delete"
          },
          "getAreaCassettes": {
              "name": "カセット情報取得",
              "basePathKey": "documentWbsApi",
              "path": "/api/cassette/areamixed/list/{customerId}?areaId={areaId}",
              "bodyKey": null,
              "method": "get"
          },
          "postCassetteMany": {
              "name": "カセット情報登録",
              "basePathKey": "documentWbsApi",
              "path": "/api/cassette/many",
              "bodyKey": "payload",
              "method": "post"
          },
          "deleteCassetteMany": {
              "name": "カセット情報削除",
              "basePathKey": "documentWbsApi",
              "path": "/api/cassette/relation/many",
              "bodyKey": "payload",
              "method": "delete"
          },
          "getWbsItem": {
              "name": "Wbsアイテムリスト取得",
              "basePathKey": "documentWbsApi",
              "path": "/api/wbsItem/list?fileSystemId={fileSystemId}",
              "bodyKey": null,
              "method": "get"
          },
          "getWbs": {
              "name": "Wbsアイテム取得",
              "basePathKey": "documentWbsApi",
              "path": "/api/wbsItem/wbs?wbsItemId={wbsItemId}",
              "bodyKey": null,
              "method": "get"
          },
          "postWbsItemAll": {
              "name": "Wbsアイテム更新",
              "basePathKey": "documentWbsApi",
              "path": "/api/wbsItem/wbs",
              "bodyKey": "postItems",
              "method": "post"
          },
          "getCatalogForWbsItem": {
              "name": "Wbsアイテム更新のHubカタログ",
              "basePathKey": "documentWbsApi",
              "path": "/api/indexWbs/fqdn?fqdn=catalog.prototype.nameCardList.narrowing.services",
              "bodyKey": null,
              "method": "get"
          },
          "getWbsViwer": {
              "name": "WbsアイテムViewer",
              "basePathKey": "documentWbsApi",
              "path": "/api/indexWbs/nodeObjects/all/?fileSystemId={fileSystemId}&rootOnly=false&map=files",
              "bodyKey": null,
              "method": "get"
          }
      }
  },
  "modifiedAt": "2024-03-13T03:30:48.7409952+00:00"
}
// エリア選択
export const selectAreaDataDevV2 = {
  "indexAppCd": "documentWbs",
  "frontendCd": "selectAreaV2",
  "name": "エリア選択",
  "settingType": "wbsType",   
  "items": {
    "settingFileVersion":2,  
    "layout": {
      "style": {
        "direction": "row"
      },
      "leftWbs": {
        "loadAction": "getAreas",
        "header": {}
      },
      "leftWbsRules": {
        "childDataKey": "nodeObjects",
        "hideHeader": true,
        "rowSelection": "single",
        "hideRowSelectors": true,
        "noSelectionWbsTypes": [
          "wbs"
        ],
        "height": "400px",
        "width": "600px",
        "fields": [
          {
            "name": "name",
            "label": "名前",
            "dataType": "string"
          }
        ],
        "showMenu": true
      },
      "rightWbsRules": {
        "childDataKey": "nodeObjects",
        "hideHeader": false,
        "rowSelection": "single",
        "fields": [
          {
            "name": "name",
            "label": "名前",
            "dataType": "string"
          }
        ]
      }
    },
    "rules": {
      "area": {
        "menu": [
          {
            "menuCd": 3,
            "menuName": "カセット表示"
          },
          {
            "menuCd": 4,
            "menuName": "カセット作成・移動・削除"
          },
          {
            "menuCd": 7,
            "menuName": "アップロードアイテム表示"
          }
        ]
      }
    },
    "actions": {
      "getAreas":{
        "apiDomain": "documentWbs.dev.getAreas"
      },
      "onSelectMenu": {
        "event": "selectRowMenu"
      },
      "save": {
        "event": "onSave"
      },
      "cancel": {
        "event": "footerCancel"
      },
      "click": {}
    }
  },
  "modifiedAt": "2024-02-27T06:40:22.6524578+00:00"
}
// エリア作成
export const AreaMappingDataV2 ={
  "indexAppCd": "documentWbs",
  "frontendCd": "areaMappingV2",
  "name": "エリア作成",
  "settingType": "uxMappingWbs",
  "items": {
      "settingFileVersion": 2,
      "layout": {
          "style": {
              "direction": "row"
          },
          "leftWbs": {
              "loadAction": "GetAreas",
              "header": {}
          },
          "leftWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs",
                  "plusButton"
              ],
              "canMoveTopNode": false,
              "height": "300px",
              "width": "100%",
              "showMenu": false,
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          },
          "rightWbs": {
              "loadAction": "",
              "header": {}
          },
          "rightWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs",
                  "plusButton"
              ],
              "height": "300px",
              "width": "100%",
              "showMenu": false,
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          },
          "footer": {
              "buttons": [
                  {
                      "key": "cancel",
                      "id": "1",
                      "name": "cancel-button",
                      "label": "元に戻す",
                      "color": "cancel",
                      "disable": false,
                      "hidden": false,
                      "action": "revert"
                  },
                  {
                      "key": "register",
                      "id": "2",
                      "name": "save-button",
                      "label": "登録する",
                      "color": "primary",
                      "disable": false,
                      "hidden": false,
                      "action": "dialogBeforesave"
                  }
              ]
          }
      },
      "rules": {
          "area": {
              "rightArrow": {
                  "action": "backward"
              },
              "leftArrow": {
                  "action": "forward"
              }
          }
      },
      "actions": {
          "GetAreas": {
              "apiDomain": "documentWbs.dev.getAreas"
          },
          "onSelectMenu": {
              "event": "selectRowMenu"
          },
          "dialogBeforesave": {
              "event": "showSaveDialog",
              "messageCd": "confirmRun"
          },
          "save": {
              "event": "onSave",
              "apiInfo": {
                  "asyncFlg": false,
                  "apiList": [
                      {
                          "name": "エリア登録",
                          "type": "register",
                          "domain": "documentWbs.dev.postAreaMany"
                      },
                      {
                          "name": "エリア削除",
                          "type": "delete",
                          "domain": "documentWbs.dev.deleteAreaMany"
                      }
                  ]
              }
          },
          "cancel": {
              "event": "dialogCancel"
          },
          "revert": {
              "event": "footerCancel"
          },
          "ok": {
              "event": "dialogCancel"
          },
          "click": {}
      }
  },
  "modifiedAt": "2024-03-12T07:24:14.4885019+00:00"
}
// カセット一覧
export const wbsViewerDataV2 ={
  "indexAppCd": "documentWbs",
  "frontendCd": "wbsViewerV2",
  "name": "WbsViewer",
  "settingType": "wbsType",
  "target": "service",
  "items": {
      "settingFileVersion": 2,
      "layout": {
          "style": {
              "direction": "row"
          },
          "leftWbs": {
              "loadAction": "GetWbs",
              "header": {}
          },
          "leftWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs"
              ],
              "height": "400px",
              "width": "600px",
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ],
              "showMenu": true
          },
          "rightWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": false,
              "rowSelection": "single",
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          }
      },
      "rules": {
          "cassette": {
              "menu": [
                  {
                      "menuCd": 1,
                      "menuName": "ファイルアップロード"
                  }
              ]
          },
          "file": {
              "menu": [
                  {
                      "menuCd": 2,
                      "menuName": "ファイルダウンロード"
                  },
                  {
                      "menuCd": 6,
                      "menuName": "ファイルを削除"
                  }
              ]
          }
      },
      "actions": {
          "GetWbs": {
              "apiDomain": "documentWbs.dev.getWbsViwer"
          },
          "load": {},
          "onSelectMenu": {
              "event": "selectRowMenu"
          },
          "save": {
              "event": "onSave"
          },
          "cancel": {
              "event": "footerCancel"
          },
          "click": {}
      }
  },
  "modifiedAt": "2024-03-13T01:42:47.7370336+00:00"
}
// Wbsアイテム選択
export const SelectwbsItemDataV2 ={
  "indexAppCd": "documentWbs",
  "frontendCd": "selectWbsItemV2",
  "name": "Wbsアイテム選択",
  "settingType": "wbsType",
  "items": {
      "settingFileVersion": 2,
      "layout": {
          "style": {
              "direction": "row"
          },
          "leftWbs": {
              "loadAction": "GetWbs",
              "header": {}
          },
          "leftWbsRules": {
              "childDataKey": "",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs"
              ],
              "height": "400px",
              "width": "600px",
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ],
              "showMenu": true
          },
          "rightWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": false,
              "rowSelection": "single",
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          }
      },
      "rules": {
          "wbs-item": {
              "menu": [
                  {
                      "menuCd": 7,
                      "menuName": "アップロードアイテム表示"
                  }
              ]
          }
      },
      "actions": {
          "GetWbs": {
              "apiDomain": "documentWbs.dev.getWbsItem"
          },
          "load": {},
          "onSelectMenu": {
              "event": "selectRowMenu"
          },
          "save": {
              "event": "onSave"
          },
          "cancel": {
              "event": "footerCancel"
          },
          "click": {}
      }
  },
  "modifiedAt": "2024-03-13T02:22:50.9073945+00:00"
}
// Wbsアイテム一覧
export const wbsItemViewerDataV2 ={
  "indexAppCd": "documentWbs",
  "frontendCd": "wbsItemViewerV2",
  "name": "WbsアイテムViewer",
  "settingType": "wbsType",
  "items": {
      "settingFileVersion": 2,
      "layout": {
          "style": {
              "direction": "row"
          },
          "leftWbs": {
              "loadAction": "GetWbs",
              "header": {}
          },
          "leftWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs"
              ],
              "height": "400px",
              "width": "600px",
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ],
              "showMenu": true
          },
          "rightWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": false,
              "rowSelection": "single",
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          }
      },
      "rules": {
          "file": {
              "menu": [
                  {
                      "menuCd": 2,
                      "menuName": "ファイルダウンロード"
                  }
              ]
          }
      },
      "actions": {
          "GetWbs": {
              "apiDomain": "documentWbs.dev.getWbsViwer"
          },
          "load": {},
          "onSelectMenu": {
              "event": "selectRowMenu"
          },
          "save": {
              "event": "onSave"
          },
          "cancel": {
              "event": "footerCancel"
          },
          "click": {}
      }
  },
  "modifiedAt": "2024-03-13T02:33:53.8043894+00:00"
}
// カセット作成
export const cassetteMappingDataV2 ={
  "indexAppCd": "documentWbs",
  "frontendCd": "cassetteMappingV2",
  "name": "カセット作成",
  "settingType": "uxMappingWbs",
  "items": {
      "settingFileVersion": 2,
      "layout": {
          "style": {
              "direction": "row"
          },
          "leftWbs": {
              "loadAction": "GetAreaCassettes",
              "header": {}
          },
          "leftWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs",
                  "plusButton"
              ],
              "canMoveTopNode": false,
              "height": "300px",
              "width": "100%",
              "showMenu": false,
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          },
          "rightWbs": {
              "loadAction": "",
              "header": {}
          },
          "rightWbsRules": {
              "childDataKey": "nodeObjects",
              "hideHeader": true,
              "rowSelection": "single",
              "hideRowSelectors": true,
              "noSelectionWbsTypes": [
                  "wbs",
                  "plusButton"
              ],
              "height": "300px",
              "width": "100%",
              "showMenu": false,
              "fields": [
                  {
                      "name": "name",
                      "label": "名前",
                      "dataType": "string"
                  }
              ]
          },
          "footer": {
              "buttons": [
                  {
                      "key": "cancel",
                      "id": "1",
                      "name": "cancel-button",
                      "label": "元に戻す",
                      "color": "cancel",
                      "disable": false,
                      "hidden": false,
                      "action": "revert"
                  },
                  {
                      "key": "register",
                      "id": "2",
                      "name": "save-button",
                      "label": "登録する",
                      "color": "primary",
                      "disable": false,
                      "hidden": false,
                      "action": "dialogBeforesave"
                  }
              ]
          }
      },
      "rules": {
          "cassette": {
              "rightArrow": {
                  "action": "backward"
              },
              "leftArrow": {
                  "action": "forward"
              }
          }
      },
      "actions": {
          "GetAreaCassettes": {
              "apiDomain": "documentWbs.dev.getAreaCassettes"
          },
          "onSelectMenu": {
              "event": "selectRowMenu"
          },
          "dialogBeforesave": {
              "event": "showSaveDialog",
              "messageCd": "confirmRun"
          },
          "save": {
              "event": "onSave",
              "apiInfo": {
                  "asyncFlg": false,
                  "apiList": [
                      {
                          "name": "カセット登録",
                          "type": "register",
                          "domain": "documentWbs.dev.postCassetteMany"
                      },
                      {
                          "name": "カセット削除",
                          "type": "delete",
                          "domain": "documentWbs.dev.deleteCassetteMany"
                      }
                  ]
              }
          },
          "cancel": {
              "event": "dialogCancel"
          },
          "revert": {
              "event": "footerCancel"
          },
          "ok": {
              "event": "dialogCancel"
          },
          "click": {}
      }
  },
  "modifiedAt": "2024-03-13T02:56:19.7800244+00:00"
}