import { Component, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { YesNoDialogComponent } from 'src/app/components/dialogs/yes-no-dialog/yes-no-dialog.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { CommonService } from 'src/app/services/common.service';
import { WbsService } from 'src/app/services/wbs.service';
import { PetaboComponent } from 'src/app/components/petabo/petabo.component';
import { PetaboDialogComponent } from 'src/app/components/dialogs/petabo-dialog/petabo-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { dashboardCatalogHubInfo } from '../localdata';
import { HubDialogComponent } from 'src/app/components/dialogs/hub-dialog/hub-dialog.component';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    @ViewChild('petabo') petabo!: PetaboComponent
    @ViewChild('petabodialog') petabodialog!: PetaboDialogComponent
    // @ViewChild('catalogHub', { static: true }) public catalogHub: HubDialogComponent | undefined
    @ViewChild('catalogHub', { static: true }) public catalogHub: IgxDialogComponent | undefined
    hubSettings:any=dashboardCatalogHubInfo
    selectedCatalog:any[]=[]  
    
    constructor(
        private commonService: CommonService,
        private wbsService:WbsService,
        private router: Router,
        private authService: AuthService,
    ) {}

    public title = 'ペタボ連携確認'
    public buttonData: any = [
        {
          "key": "petabo",
          "name": "ペタボ",
          "color": "primary"
        },
        {
          "key": "petabo-dialog",
          "name": "ペタボ（モーダル）",
          "color": "primary"
        },
        {
          "key": "call-hub",
          "name": "カタログHUB呼び出し",
          "color": "cancel"
        }
    ]
    public orgList = [
      {
        "key": "373c9b59-097a-46f6-aa04-b0f9d39185ba",
        "fqdn":"petabo.prototype.management-org",
        "name": "管理部（仮）",
      },
      {
        "key": "07645786-420c-452e-b9e6-b5de73b49340",
        "fqdn":"petabo.prototype.system-org3",
        "name": "システム部（仮）",
      },
      // {
      //   "key": "9e56b55b-2ed3-49ea-b5bb-1f71f0169604",
      //   "fqdn":"petabo.prototype.kiban-temp2",
      //   "name": "基盤開発チーム（仮）",
      // },
      {
        "key": "6ad97939-9ac1-498c-96f7-2ccb8e0392e4",        
        "fqdn":"petabo.prototype.kumakawa-test",
        "name": "熊川テスト",
      },
      {
        "key": "f9f39ef7-8587-45fd-850b-f433264a866c",        
        "fqdn":"petabo.prototype.339118d6-2000-470f-9174-c5954864b2ef",
        "name": "IS/基盤開発チーム",
      }
    ]
    public modelist = [
      {
        "key": "8f4a74d3-d887-4668-84a9-5bc69ba79ad2",
        "value":"0",
        "name": "閲覧のみ",
      },
      {
        "key": "cdfa940f-a0c3-4fd1-a110-4b69da657d76",
        "value":"1",
        "name": "追加可能",
      },
    ]    

    public selectedOrgFqdn=this.orgList[2].fqdn
    public selectedPetaboMode=this.modelist[1].value
    public customerId=''
    public petaboTitle=''
    public petaboSettingId=''

    async ngOnInit(): Promise<void> {

      this.customerId=this.authService.Account?.customerId??''
      this.initialize()
    
    }
    async initialize(wbsId?:string){

    }
    async buttonClick(id: string) {    
        switch (id){
          case 'petabo':
            await this.createPetaboSettings()
            this.openPetabo()
            break
          case 'petabo-dialog':
            await this.createPetaboSettings()
            this.openPetaboDialog()
            break
          case 'call-hub':
            this.openCatalogHub()
            break
        }    
    }
    async createPetaboSettings(){
      let index = this.orgList.findIndex((x: { fqdn: any; })=>x.fqdn===this.selectedOrgFqdn)      
      this.petaboTitle = index!==-1 ? this.orgList[index].name : ''

      let payload={
        customerId:this.customerId,
        openMode:this.selectedPetaboMode
      }
      this.petaboSettingId = await this.wbsService.createFile(payload)
    }
    openPetabo(){
      this.petabo.open(this.selectedOrgFqdn,this.petaboSettingId, this.petaboTitle) //ペタボID、設定値を指定、タイトル
    }
    openPetaboDialog(){
      this.petabodialog.open(this.selectedOrgFqdn,this.petaboSettingId, this.petaboTitle) //ペタボID、設定値を指定、タイトル
    }
    openCatalogHub(){
      // this.hubSettings=dashboardCatalogHubInfo
      this.catalogHub?.open()
    }
    selectItem(item: any){
      this.selectedCatalog=item
    }
    
}