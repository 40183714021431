import { wbsModel } from "../interfaces/wbs.model"

export const BC_ORG_WBS:wbsModel[]=[
    {
        "id": "501a710a-6eaf-422d-802e-780de28dd75e",
        "name": "埼玉倉庫",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    }
]

export const AREA_WBS:wbsModel[]=[
    {
        "id": "82ac4aa7-4a6b-453d-87ec-84b6bfd70e24",
        "name": "BC管理エリア",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    }
]

export const HEALTH_CHCECK_AREA_ONLY:wbsModel[]=[
    {
        "id": "82ac4aa7-4a6b-453d-87ec-84b6bfd70e24",
        "name": "BC管理エリア",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "children":[],
        "nodeObjects":[
            {
                "id": "18c367b7-8288-4058-bf15-fcddf09685e6",
                "name": "健康診断エリア",
                "parentId":"",
                "profiles":{"showSubMenu":true,"subMenu":[{ menuCd: 3, menuName: 'カセットを表示' }]},
                "nodes":[],
                "nodeObjects":[],
                "children":[                    
                ]  
            }
        ]
    }
]

// export const HEALTH_CHCECK_CASSETTES_ONLY:wbsModel[]=[
//     {
//         "id": "291f0348-c258-41cf-bf98-ab6e93f5d513",
//         "name": "健康診断を提出してねカセット（2023）",
//         "parentId":"",
//         "profiles":null,
//         "nodes":[],
//         "children":[],
//         "nodeObjects":[
//             {
//                 "id": "8d7d69f9-f2b9-4e70-8360-290fc21c5f4a",
//                 "name": "熊川カセット",
//                 "parentId":"",
//                 "profiles":{"showSubMenu":true,"subMenu":[{ menuCd: 1, menuName: 'ファイルアップロード' }]},
//                 "nodes":[],
//                 "children":[],
//                 "nodeObjects":[
//                     {
//                         "id": "e3ad3853-af96-4b03-a4e4-b719128e30dd",
//                         "name": "20230801_kumakawa_結果.jpg",
//                         "parentId":"",
//                         "profiles":{"canDownload":true,"subMenu":[{ menuCd: 2, menuName: 'ファイルダウンロード' }]},
//                         "nodes":[],
//                         "nodeObjects":[],
//                         "children":[]  
//                     }
//                 ]  
//             }
//         ]  
//     }
// ]
export const HEALTH_CHCECK_CASSETTES_ONLY:wbsModel[]=[
    {
        "id": "291f0348-c258-41cf-bf98-ab6e93f5d513",
        "name": "健康診断を提出してねカセット（2023）",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "children":[],
        "nodeObjects":[
            {
                "id": "8d7d69f9-f2b9-4e70-8360-290fc21c5f4a",
                "name": "熊川カセット",
                "parentId":"",
                "profiles":null,
                "nodes":[],
                "children":[],
                "nodeObjects":[
                    {
                        "id": "e3ad3853-af96-4b03-a4e4-b719128e30dd",
                        "name": "20230801_kumakawa_結果.jpg",
                        "parentId":"",
                        // "profiles":{"canDownload":true,"subMenu":[{ menuCd: 2, menuName: 'ファイルダウンロード' }]},
                        "profiles":{"type":"file"},
                        "nodes":[],
                        "nodeObjects":[],
                        "children":[]  
                    }
                ]  
            }
        ]  
    }
]
export const HEALTH_CHCECK_WBS:wbsModel[]=[
    {
        "id": "82ac4aa7-4a6b-453d-87ec-84b6bfd70e24",
        "name": "BC管理エリア",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "children":[],
        "nodeObjects":[
            {
                "id": "18c367b7-8288-4058-bf15-fcddf09685e6",
                "name": "健康診断エリア",
                "parentId":"",
                "profiles":{"showSubMenu":true,"subMenu":[{ menuCd: 3, menuName: 'カセットを表示' }]},
                "nodes":[],
                "children":[],
                "nodeObjects":[
                    {
                        "id": "291f0348-c258-41cf-bf98-ab6e93f5d513",
                        "name": "健康診断を提出してねカセット（2023）",
                        "parentId":"",
                        "profiles":null,
                        "nodes":[],
                        "children":[],
                        "nodeObjects":[
                            {
                                "id": "8d7d69f9-f2b9-4e70-8360-290fc21c5f4a",
                                "name": "熊川カセット",
                                "parentId":"",
                                "profiles":{"showSubMenu":true},
                                "nodes":[],
                                "nodeObjects":[],
                                "children":[]  
                            }
                        ]  
                    }
                ]  
            }
        ]
    }
]
export const HEALTH_CHCECK_FILE_WBS:wbsModel[]=[
    {
        "id": "82ac4aa7-4a6b-453d-87ec-84b6bfd70e24",
        "name": "BC管理エリア",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "children":[],
        "nodeObjects":[
            {
                "id": "18c367b7-8288-4058-bf15-fcddf09685e6",
                "name": "健康診断エリア",
                "parentId":"",
                "profiles":{"showSubMenu":true,"subMenu":[{ menuCd: 3, menuName: 'カセットを表示' }]},
                "nodes":[],
                "children":[],
                "nodeObjects":[
                    {
                        "id": "291f0348-c258-41cf-bf98-ab6e93f5d513",
                        "name": "健康診断を提出してねカセット（2023）",
                        "parentId":"",
                        "profiles":null,
                        "nodes":[],
                        "children":[],
                        "nodeObjects":[
                            {
                                "id": "8d7d69f9-f2b9-4e70-8360-290fc21c5f4a",
                                "name": "熊川カセット",
                                "parentId":"",
                                "profiles":{"showSubMenu":true,"subMenu":[{ menuCd: 1, menuName: 'ファイルアップロード' }]},
                                "nodes":[],
                                "children":[],
                                "nodeObjects":[
                                    {
                                        "id": "e3ad3853-af96-4b03-a4e4-b719128e30dd",
                                        "name": "20230801_kumakawa_結果.jpg",
                                        "parentId":"",
                                        "profiles":{"canDownload":true,"subMenu":[{ menuCd: 2, menuName: 'ファイルダウンロード' }]},
                                        "nodes":[],
                                        "nodeObjects":[],
                                        "children":[]  
                                    }
                                ]  
                            }
                        ]  
                    }
                ]  
            }
        ]
    }
]

export const HEALTH_CHCECK_ADMIN_WBS:wbsModel[]=[
    {
        "id": "82ac4aa7-4a6b-453d-87ec-84b6bfd70e24",
        "name": "BC管理エリア",
        "parentId":"",
        "profiles":{},
        "nodes":[],
        "nodeObjects":[],
        "children":[
            {
                "id": "18c367b7-8288-4058-bf15-fcddf09685e6",
                "name": "健康診断エリア",
                "parentId":"",
                "profiles":{},
                "nodes":[],
                "nodeObjects":[],
                "children":[
                    {
                        "id": "291f0348-c258-41cf-bf98-ab6e93f5d513",
                        "name": "健康診断を提出してねカセット（2023）",
                        "parentId":"",
                        "profiles":{},
                        "nodes":[],
                        "nodeObjects":[],
                        "children":[
                            {
                                "id": "71400789-f3e4-4158-8c63-406efd1c2b6a",
                                "name": "水野カセット",
                                "parentId":"",
                                "profiles":{},
                                "nodes":[],
                                "nodeObjects":[],
                                "children":[]  
                            },
                            {
                                "id": "8d7d69f9-f2b9-4e70-8360-290fc21c5f4a",
                                "name": "熊川カセット",
                                "parentId":"",
                                "profiles":{},
                                "nodes":[],
                                "nodeObjects":[],
                                "children":[]  
                            }
                        ]  
                    }
                ]  
            }
        ]
    }
]
export const USER_LIST:wbsModel[]=[
    {
        "id": "52ac00ca-0317-48ba-9b5a-6d56ce94220f",
        "name": "社員リスト",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[
            {
                "id": "02728290-81e3-4c09-8c50-1aad0cc849e4",
                "name": "山田潤",
                "parentId":"",
                "profiles":null,
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
            {
                "id": "76c6ec9d-0257-4528-886a-207ad5eaaa26",
                "name": "水野卓也",
                "parentId":"",
                "profiles":null,
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
            {
                "id": "d40c784c-45ed-45d9-bc77-83cac84b8d70",
                "name": "熊川晶子",
                "parentId":"",
                "profiles":null,
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
        ]
    }
]


// export const AREA_SETTINGS = {
//     indexAppCd: 'myidea',
//     frontendCd: 'form-actuate-org',
//     name: '稼働組織登録',
//     settingType: 'form',
//     target: '',
//     items: {
//         item1: { name: 'code', displayName: 'コード', type: 'string', maxLength: '12', inputType: 'text' },
//         item2: { name: 'address', displayName: '住所', type: 'string', maxLength: '100', inputType: 'text' },
//         item3: { name: 'tel', displayName: '郵便番号', type: 'number', maxLength: '7', inputType: 'number' },
//     },
//     modifiedAt: '2023-07-25',
// }

export const AREA_SETTINGS = {
    indexAppCd: 'myidea',
    frontendCd: 'form-cassette',
    name: 'カセット登録',
    settingType: 'form',
    target: '',
    items: {
        item1: { name: 'owner', displayName: '所有者', type: 'string', maxLength: '50', inputType: 'text', canSearch:true },
    },
    modifiedAt: '2023-07-25',
}

//TempFileId=c8a34392-58c6-41e5-aa1f-d1b033a593b4
export const CATALOG_LIST_BC_ALL_AREA:wbsModel[]=
[
    {
        "id": "e5aa534a-683a-4595-9cfb-0367dd4908e4",
        "name": "インターサーブエリア",
        "parentId":"",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },  
    {
        "id": "e7372483-eef0-41fe-81a8-f735b4d17eb5",
        "name": "BC管理部エリア",
        "parentId":"",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":[
		    {
		        "id": "58f925cf-1825-49dd-a0f7-199c70c50ef9",
		        "name": "健康診断エリア",
		        "parentId":"",
		        "profiles":{"icon":"area"},
		        "nodes":[],
		        "nodeObjects":[],
		        "children":[]  
		    }
        ],
        "children":[]  
    },  
    {
        "id": "1fa9a90a-c483-48d0-9587-088e295672d5",
        "name": "個人エリア（熊川）",
        "parentId":"",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":[
        	{
		        "id": "1e464cad-b2a0-4985-a794-2fb6c6b55cf4",
		        "name": "開発エリア",
		        "parentId":"",
		        "profiles":{"icon":"area"},
		        "nodes":[],
		        "nodeObjects":[],
		        "children":[]  
		    },
		    {
		        "id": "3ecc7f03-4dcf-4356-b83d-6775e4ba1ff5",
		        "name": "毎年設定が必要なカタログエリア",
		        "parentId":"",
		        "profiles":{"icon":"area"},
		        "nodes":[],
		        "nodeObjects":[
				    {
				        "id": "2bbd056a-a6fb-41e4-b049-d650c58e6c90",
				        "name": "健康診断カタログ",
				        "parentId":"",
				        "profiles":{"icon":"catalog"},
				        "nodes":[],
				        "nodeObjects":[],
				        "children":[]  
				    },
				    {
				        "id": "1e242bcb-e596-452b-ae27-5a4e1a223500",
				        "name": "交通費カタログ",
				        "parentId":"",
				        "profiles":{"icon":"catalog"},
				        "nodes":[],
				        "nodeObjects":[],
				        "children":[]  
				    },
				    {
				        "id": "8f43be9f-ba2c-4387-b2dd-0750e2341c23",
				        "name": "研修カタログ",
				        "parentId":"",
				        "profiles":{"icon":"catalog"},
				        "nodes":[],
				        "nodeObjects":[],
				        "children":[]  
				    }
		        ],
		        "children":[]  
		    },
            {
                "id": "baae4e81-e2c8-4854-b5fa-0f54d7732015",
                "name": "社内ルール確認できる便利カタログ",
                "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
                "profiles":{"icon":"catalog"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
        ],
        "children":[]  
    }
]
// //TempFileId=78292ca0-31e2-47d9-b601-d2ca8551529b
// export const CATALOG_LIST_AREA_1:wbsModel[]=
// [
//     {
//         "id": "e5aa534a-683a-4595-9cfb-0367dd4908e4",
//         "name": "インターサーブエリア",
//         "parentId":"",
//         "profiles":{"icon":"area"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },  
//     {
//         "id": "daa8f533-d841-48b3-96a5-90fd64845b46",
//         "name": "BC管理部エリア",
//         "parentId":"",
//         "profiles":{"icon":"area"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },  
//     {
//         "id": "2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
//         "name": "個人エリア（熊川）",
//         "parentId":"",
//         "profiles":{"icon":"area"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     }
// ]
// //TempFileId=daa8f533-d841-48b3-96a5-90fd64845b46
// export const CATALOG_LIST_AREA_2:wbsModel[]=
// [
//     {
//         "id": "58f925cf-1825-49dd-a0f7-199c70c50ef9",
//         "name": "健康診断エリア",
//         "parentId":"daa8f533-d841-48b3-96a5-90fd64845b46",
//         "profiles":{"icon":"area"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     }
// ]
// //TempFileId=2350bafe-3f69-4ec1-be54-c61c2d89b6bc
// export const CATALOG_LIST_AREA_3:wbsModel[]=
// [
// 	{
//         "id": "1e464cad-b2a0-4985-a794-2fb6c6b55cf4",
//         "name": "開発エリア",
//         "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
//         "profiles":{"icon":"area"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },
//     {
//         "id": "cf688ddb-b706-410b-afaa-bf29b042a0af",
//         "name": "毎年設定が必要なカタログエリア",
//         "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
//         "profiles":{"icon":"area"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },
//     {
//         "id": "baae4e81-e2c8-4854-b5fa-0f54d7732015",
//         "name": "社内ルール確認できる便利カタログ",
//         "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
//         "profiles":{"icon":"catalog"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },
// ]
// //TempFileId=cf688ddb-b706-410b-afaa-bf29b042a0af
// export const CATALOG_LIST_AREA_4:wbsModel[]=
// [
// 	{
//         "id": "2bbd056a-a6fb-41e4-b049-d650c58e6c90",
//         "name": "健康診断カタログ",
//         "parentId":"cf688ddb-b706-410b-afaa-bf29b042a0af",
//         "profiles":{"icon":"catalog"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },
//     {
//         "id": "1e242bcb-e596-452b-ae27-5a4e1a223500",
//         "name": "交通費カタログ",
//         "parentId":"cf688ddb-b706-410b-afaa-bf29b042a0af",
//         "profiles":{"icon":"catalog"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     },
//     {
//         "id": "8f43be9f-ba2c-4387-b2dd-0750e2341c23",
//         "name": "研修カタログ",
//         "parentId":"cf688ddb-b706-410b-afaa-bf29b042a0af",
//         "profiles":{"icon":"catalog"},
//         "nodes":[],
//         "nodeObjects":[],
//         "children":[]  
//     }
// ]

//TempFileId=78292ca0-31e2-47d9-b601-d2ca8551529b
export const CATALOG_LIST_AREA_1:wbsModel[]=
[
    {
        "id": "78292ca0-31e2-47d9-b601-d2ca8551529b",
        "name": "エリア一覧",
        "parentId":"",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":
        [
            {
                "id": "e5aa534a-683a-4595-9cfb-0367dd4908e4",
                "name": "インターサーブエリア",
                "parentId":"78292ca0-31e2-47d9-b601-d2ca8551529b",
                "profiles":{"icon":"area"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },  
            {
                "id": "daa8f533-d841-48b3-96a5-90fd64845b46",
                "name": "BC管理部エリア",
                "parentId":"78292ca0-31e2-47d9-b601-d2ca8551529b",
                "profiles":{"icon":"area"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },  
            {
                "id": "2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
                "name": "個人エリア（熊川）",
                "parentId":"78292ca0-31e2-47d9-b601-d2ca8551529b",
                "profiles":{"icon":"area"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            }
        ],
        "children":[]  
    }
]
//TempFileId=daa8f533-d841-48b3-96a5-90fd64845b46
export const CATALOG_LIST_AREA_2:wbsModel[]=
[
    {
        "id": "daa8f533-d841-48b3-96a5-90fd64845b46",
        "name": "BC管理部エリア",
        "parentId":"78292ca0-31e2-47d9-b601-d2ca8551529b",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":
        [
            {
                "id": "58f925cf-1825-49dd-a0f7-199c70c50ef9",
                "name": "健康診断エリア",
                "parentId":"daa8f533-d841-48b3-96a5-90fd64845b46",
                "profiles":{"icon":"area"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            }
        ],
        "children":[]  
    }
]
//TempFileId=2350bafe-3f69-4ec1-be54-c61c2d89b6bc
export const CATALOG_LIST_AREA_3:wbsModel[]=
[
    {
        "id": "2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
        "name": "個人エリア（熊川）",
        "parentId":"78292ca0-31e2-47d9-b601-d2ca8551529b",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":
        [
            {
                "id": "1e464cad-b2a0-4985-a794-2fb6c6b55cf4",
                "name": "開発エリア",
                "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
                "profiles":{"icon":"area"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
            {
                "id": "cf688ddb-b706-410b-afaa-bf29b042a0af",
                "name": "毎年設定が必要なカタログエリア",
                "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
                "profiles":{"icon":"area"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
            {
                "id": "baae4e81-e2c8-4854-b5fa-0f54d7732015",
                "name": "社内ルール確認できる便利カタログ",
                "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
                "profiles":{"icon":"catalog"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            }
        ],
        "children":[]  
    }
]

//TempFileId=cf688ddb-b706-410b-afaa-bf29b042a0af
export const CATALOG_LIST_AREA_4:wbsModel[]=
[
    {
        "id": "cf688ddb-b706-410b-afaa-bf29b042a0af",
        "name": "毎年設定が必要なカタログエリア",
        "parentId":"2350bafe-3f69-4ec1-be54-c61c2d89b6bc",
        "profiles":{"icon":"area"},
        "nodes":[],
        "nodeObjects":
        [
            {
                "id": "2bbd056a-a6fb-41e4-b049-d650c58e6c90",
                "name": "健康診断カタログ",
                "parentId":"cf688ddb-b706-410b-afaa-bf29b042a0af",
                "profiles":{"icon":"catalog"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
            {
                "id": "1e242bcb-e596-452b-ae27-5a4e1a223500",
                "name": "交通費カタログ",
                "parentId":"cf688ddb-b706-410b-afaa-bf29b042a0af",
                "profiles":{"icon":"catalog"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            },
            {
                "id": "8f43be9f-ba2c-4387-b2dd-0750e2341c23",
                "name": "研修カタログ",
                "parentId":"cf688ddb-b706-410b-afaa-bf29b042a0af",
                "profiles":{"icon":"catalog"},
                "nodes":[],
                "nodeObjects":[],
                "children":[]  
            }
        ],
        "children":[]  
    }
]

// 健康診断カタログ ★★はりぼて用　一時保存ファイルID 2bbd056a-a6fb-41e4-b049-d650c58e6c90
// 交通費カタログ ★★はりぼて用　一時保存ファイルID 1e242bcb-e596-452b-ae27-5a4e1a223500
// 研修カタログ ★★はりぼて用　一時保存ファイルID 8f43be9f-ba2c-4387-b2dd-0750e2341c23
export const CATALOG_LIST_BC_KANRI:wbsModel[]=[
    {
        // "id": "02728290-81e3-4c09-8c50-1aad0cc849e4",
        "id": "2bbd056a-a6fb-41e4-b049-d650c58e6c90",
        "name": "健康診断カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },    
    {
        // "id": "ceae68cf-2823-487d-8aca-b9a21d2e7126",
        "id": "1e242bcb-e596-452b-ae27-5a4e1a223500",
        "name": "交通費カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
    {
        // "id": "b6e1c8c2-f358-4a45-81f0-167857b1e72f",
        "id": "8f43be9f-ba2c-4387-b2dd-0750e2341c23",
        "name": "研修カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
]
export const CATALOG_LIST_BC_KANRI2:wbsModel[]=[
    {
        "id": "02728290-81e3-4c09-8c50-1aad0cc849e4",
        "name": "健康診断2022カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
    {
        "id": "951db762-d7f5-4f6b-b70d-69f70dfb47f8",
        "name": "健康診断2021カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
    {
        "id": "ceae68cf-2823-487d-8aca-b9a21d2e7126",
        "name": "交通費2022カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
    {
        "id": "ac253dd7-a0cd-45fd-929d-ac487b31234f",
        "name": "交通費2021カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
    {
        "id": "b6e1c8c2-f358-4a45-81f0-167857b1e72f",
        "name": "研修2022カタログ",
        "parentId":"",
        "profiles":null,
        "nodes":[],
        "nodeObjects":[],
        "children":[]  
    },
]

// 健診アプリカセット作成（紐づけ）設定
export const MAPPING_CASETTES:any={
    indexAppCd:'myidea-healsCheck',
    frontendCd:'createCasetteLeftWbs',
    settingType:'uxMappingWbs',
    name:'健康診断カセット作成',
    items:{},
    layout:{
        header:{},
        frondendCdleft:'createCasetteLeftWbs',
        frondendCdRight:'createCasetteRightWbs',
        rightArrow:{visible:true,action:{}},
        leftArrow:{visible:true,action:{}},
        footer:{}
    },
    isImmediateUpdate:false,
    actions:{},
    parameter:{
        organizationCd:'',
        customerCd:''
    }
}

// 健診アプリカセット作成 WBS左枠設定
export const WBS_LEFT:any={
    indexAppCd:'myidea-healsCheck',
    frontendCd:'createCasetteLeftWbs',
    settingType:'uxWbsHub',
    name:'健康診断カセット作成左枠',
    items:{
        gridSettings:{
            canUpdateElement:false,
            canAddTop:false,
            addObjectType:'',
            canChangeOrder:false,
            draggableOn:true,
            childDataKey:'nodeObjects',
            canMapMultiple:false,
            hideRowSelectors:true},
        showHubButton:false,
        showCatalogButton:false,
        hubFrontendCd:'',
        catalogFrontendCd:'',
    apiInfo:[
            {getWbsData:{
                methodType:'get',
                url:'//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/areamixed/{areaId}',
                param:{'customerId':'','areaId':''},
                payload	:'null',
                returnType:'multiple',
                returnModel:'wbsModel'}
            }
        ]     
    }
 }

// 健診アプリ WBS右枠設定
export const WBS_RIGHT:any={
    indexAppCd:'myidea-healsCheck',
    frontendCd:'createCasetteRightWbs',
    settingType:'uxWbsHub',
    name:'健康診断カセット作成右枠',
    items:{
        gridSettings:{
            canUpdateElement:true,
            canAddTop:false,
            addObjectType:'cassette',
            canChangeOrder:false,
            draggableOn:true,
            childDataKey:'nodeObjects',
            canMapMultiple:false,
            hideRowSelectors:true},
        showHubButton:true,
        showCatalogButton:true,
        hubFrontendCd:'createCasetteHub',
        catalogFrontendCd:'createCatalogHub',
    apiInfo:[{getWbsData:null}]     
    }
}

// 健診アプリ カタログ設定
export const CATALOG_HUB:any={
    indexAppCd:'myidea-healsCheck',
    frontendCd:'createCasetteCatalogHub',
    settingType:'uxWbsHub',
    name:'健康診断カセット作成カタログHUB',
    items:{
        gridSettings:{
            canUpdateElement:false,
            canAddTop:false,
            addObjectType:'',
            canChangeOrder:false,
            draggableOn:false,
            childDataKey:'nodeObjects',
            canMapMultiple:false,
            hideRowSelectors:false},
        showHubButton:false,
        showCatalogButton:false,
        hubFrontendCd:'',
        catalogFrontendCd:'',
    apiInfo:[
            {getWbsData:null},
            {getCatalog:{
                methodType:'get',
                url:'//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/areamixed/{areaId}',
                param:{'customerId':'','areaId':''},
                payload	:'null',
                returnType:'multiple',
                returnModel:'wbsModel'}
            }        
    ]     
    }
}

// 健診アプリ HUB設定
export const HUB:any={
    indexAppCd:'myidea-healsCheck',
    frontendCd:'createCasetteHub',
    settingType:'uxWbsHub',
    name:'健康診断カセット作成HUB',
    items:{
        gridSettings:{
            canUpdateElement:true,
            canAddTop:false,
            addObjectType:'cassette',
            canChangeOrder:false,
            draggableOn:false,
            childDataKey:'nodeObjects',
            canMapMultiple:false,
            hideRowSelectors:false},
        showHubButton:false,
        showCatalogButton:false,
        hubFrontendCd:'',
        catalogFrontendCd:'',
    apiInfo:[
            {getWbsData:null},
            {getCatalog:{
                methodType:'get',
                url:'//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/{catalogId}',
                param: {"customerId":"","areaId":"","catalogId":""},
                payload	:'null',
                returnType:'multiple',
                returnModel:'wbsModel'}
            }        
    ]     
    }
}