<!-- <is-igx-dialog 
  #modalDialog 
  [closeOnOutsideSelect]="true"  
  >
  <div class="modal-style" [ngClass]="{'modal-style-l': modalSize==='large'}"> -->
<is-igx-dialog 
  #modalDialog 
  [width]="dialogWidth" [maxHeight]="dialogMaxHeight"
  >
  <div class="modal-style">
    <div class="position-right">
        <a (click)="_closed(); modalDialog.close();">
            <span class="material-icons icon-style">
                close
            </span>
        </a>  
    </div>
    <div class="dialog-title">
      {{ dialogParam?.title }}
    </div>
    {{ dialogParam?.message }}
    <ng-content></ng-content>
    <footer class="modal__footer">
      <div class="flex-center button-area">
        <p *ngFor="let button of dialogParam?.buttonData">
          <is-igx-button
            [buttonData]="button"
            (click)="_clickButton(button.action)">
          </is-igx-button>
        </p>
      </div>
    </footer>
  </div>
</is-igx-dialog>