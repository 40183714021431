import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ResultInfo, ResultInfoSingle} from 'src/app/interfaces'
import { environment } from 'src/environments/environment'
import { wbsModel } from '../interfaces/wbs.model'

@Injectable({
  providedIn: 'root'
})
export class IndexCoreMyideaApiService {
  private path = environment.indexCoreMyidea
  constructor(
    private http: HttpClient
  ) { }

  /**
  *Wbs取得
  * @param wbsId
  * @param jsonType
  */
  GetIndexWbs(fileSystemId: string): Observable<ResultInfo<wbsModel>> {
    // https://indexcore-myidea.isbe.out.nakamenosakura.com/api/indexWbs?fileSystemId=8c067acd-7d51-41c5-b5de-5ca8ab8e10d5
    let requestUrl = `${this.path}/api/indexWbs/?fileSystemId=${fileSystemId}`
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }

  /**
  *Frontend設定取得
  * @param indexAppCd
  * @param frontendCd
  */
  GetFrontendItems(indexAppCd : string,frontendCd :string): Observable<ResultInfoSingle<any>> {
    // 'https://indexcore-myidea.isbe.out.nakamenosakura.com/api/appSchemas/documentWbs/frontends/selectArea/items'
    let requestUrl = `${this.path}/api/appSchemas/${indexAppCd}/frontends/${frontendCd}/items`
    return this.http.get<ResultInfoSingle<any>>(requestUrl)    
  }

}
