import { Component, HostListener, OnInit } from '@angular/core'

@Component({
  selector: 'app-check-server-update',
  template: ` <div id="update-notification" *ngIf="isChanged">
    <span class="notificationTitle">更新通知：</span>
    <span>{{ msg }}</span>
    <button class="ok-button" (click)="reload()">今すぐ{{ btnMsg }}</button>
  </div>`,
  styleUrls: ['./check-server-update.component.scss'],
})
export class CheckServerUpdateComponent implements OnInit {
  previousHash = null
  isChanged = false
  msg = 'アプリケーションの更新を確認しました。10秒後この画面をリロードします'
  maintenanceMsg =
    'アプリケーションのメンテナンスを開始します。10秒後この画面メンテナンスモードに切り替えます。'
  btnMsg = '更新'
  maintenanceBtnMsg = '切り替え'
  maintenanceMode = false

  timeLimit = 60000 // 60秒
  reloadTimeLimit = 10000 // 10秒
  activeMode = true
  timerId: any = null

  constructor() {}

  @HostListener('window:blur', ['$event']) onBlur(event: Event) {
    this.activeMode = false
    this.stopVertionCheck()
  }
  @HostListener('window:focus', ['$event']) onFocus(event: Event) {
    this.activeMode = true
    this.startVertionCheck()
  }

  ngOnInit(): void {
    this.fetchServer()
    this.startVertionCheck()
  }

  startVertionCheck(): void {
    this.stopVertionCheck()
    this.timerId = setInterval(() => {
      this.fetchServer()
    }, this.timeLimit)
  }
  stopVertionCheck(): void {
    clearInterval(this.timerId)
  }

  fetchServer = (): void => {
    try {
      fetch('./index.html', { cache: 'no-store' })
        .then((res) => {
          if (res.status === 302) {
            // 問題あり、キャッチできない
            this.maintenanceMode = true
            return res.text()
          } else if (res.status !== 200) {
            throw Error('Cannot access server.')
          }
          return res.text()
        })
        .then((html) => {
          if (this.maintenanceMode) {
            this.showMaintenanceMsg()
          } else {
            const hash = this.createHash(html)
            this.judgeHash(hash)
          }
        })
        .catch((err) => console.log(err))
    } catch (error) {
      // Log吐くのみ
      console.log(error)
    }
  }

  showMaintenanceMsg(): void {
    this.msg = this.maintenanceMsg
    this.btnMsg = this.maintenanceBtnMsg
    this.isChanged = true
    setTimeout(this.reload, this.reloadTimeLimit)
  }

  createHash(str: string): any {
    const len = str.length
    let hash = 0
    if (len === 0) {
      return hash
    }
    let i
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i)
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  judgeHash(hash: any): void {
    if (!this.previousHash) {
      this.previousHash = hash
      return
    }
    if (this.previousHash !== hash) {
      this.isChanged = true
      setTimeout(this.reload, this.reloadTimeLimit)
    }
  }

  reload(): void {
    location.reload()
  }
}
