import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { AuthService } from 'src/app/services/auth.service';
import { selectAreaData } from '../localdata';
import { environment } from 'src/environments/environment';
import { WbsService } from 'src/app/services/wbs.service';
import { FrontEndParams } from '@interserve/igx-angular';

@Component({
  selector: 'app-select-area',
  templateUrl: './select-area.component.html',
  styleUrls: ['./select-area.component.scss']
})
export class SelectAreaComponent implements OnInit {
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent

  public frontendCd="selectAreaV2"
  public title = 'ドキュメント 管理／エリア選択'
  
  public loading=false
  public customerId=''
  
  //WBSライブラリ用
  public frontEndParams!: FrontEndParams;

  constructor(
    private wbsService:WbsService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.customerId=this.authService.Account?.customerId??''
    
    this.initialize() 
  }
  async initialize(){
    this.loading=true    
    try {
        //フロントエンド設定をWBSに渡す
        let param={customerId:this.customerId}
        this.frontEndParams = this.wbsService.setFrontendParams(this.frontendCd, param)

        this.loading=false
    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }  
  clickSubMenu(arg: any){
    //カセット一覧を表示
    if(arg.selectedMenu===3){
      this.router.navigate([ `/wbs-viewer/${arg.selectedData.id}/1`]);    //0:閲覧のみ、1:追加可   
    }
    //カセットを作成
    if(arg.selectedMenu===4){
      this.router.navigate(["/create-cassette"],{ queryParams: { id: arg.selectedData.id } });
    }
    //アップロードしたアイテムの一覧を表示
    if(arg.selectedMenu===7){
      this.router.navigate([ `/select-wbs-item/${arg.selectedData.id}`]);    //0:閲覧のみ、1:追加可   
    }
  }
}
