<div
  #GridParent
  class="grid-parent hide-grid"
  (window:resize)="onResize($event)"
>
  <div class="invisible-cover">
    <div class="loading-block">読み込み中...</div>
  </div>
  <igx-tree-grid
    #treeGrid
    class="tree-grid"
    [displayDensity]="'compact'"
    [data]="bodyData"
    [pinning]="pinningConfig"
    igxPreventDocumentScroll
    [height]="'100%'"
    primaryKey="id"
    foreignKey="parentId"
  >
    <ng-container *ngFor="let first of gridHeaderData">
      <ng-container *ngIf="!first.columnGroup && first.parentId === null">
        <igx-column
          [field]="first.field"
          [header]="first.header"
          [width]="first.width"
          [pinned]="first.pinned"
          [editable]="first.editable"
          [dataType]="first.dataType"
          [resizable]="first.resizable"
          [hidden]="first.hidden"
          [headerClasses]="getHeaderClass(first)"
          [cellClasses]="cellClasses"
          [headerClasses]="'first-header'"
        ></igx-column>
      </ng-container>

      <ng-container *ngIf="first.columnGroup && first.parentId === null">
        <igx-column-group
          [header]="first.header"
          [headerGroupClasses]="getHeaderGroupClass(first)"
        >
          <ng-container *ngFor="let second of gridHeaderData">
            <ng-container
              *ngIf="!second.columnGroup && second.parentId === first.id"
            >
              <igx-column
                [field]="second.field"
                [header]="second.header"
                [width]="second.width"
                [pinned]="second.pinned"
                [editable]="second.editable"
                [dataType]="second.dataType"
                [resizable]="second.resizable"
                [hidden]="second.hidden"
                [headerClasses]="getHeaderClass(second)"
                [cellClasses]="cellClasses"
              ></igx-column>
            </ng-container>

            <ng-container
              *ngIf="second.columnGroup && second.parentId === first.id"
            >
              <igx-column-group
                [header]="second.header"
                [headerClasses]="getHeaderGroupClass(second)"
              >
                <ng-container *ngFor="let third of gridHeaderData">
                  <ng-container
                    *ngIf="!third.columnGroup && third.parentId === second.id"
                  >
                    <igx-column
                      [field]="third.field"
                      [header]="third.header"
                      [width]="third.width"
                      [pinned]="third.pinned"
                      [editable]="third.editable"
                      [dataType]="third.dataType"
                      [resizable]="third.resizable"
                      [hidden]="third.hidden"
                      [headerClasses]="getHeaderClass(third)"
                      [cellClasses]="cellClasses"
                      [headerClasses]="'sub-header'"
                    ></igx-column>
                  </ng-container>
                </ng-container>
              </igx-column-group>
            </ng-container>
          </ng-container>
        </igx-column-group>
      </ng-container>
    </ng-container>
  </igx-tree-grid>
</div>
