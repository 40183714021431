import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { catchError } from 'rxjs/operators';
import { ResultInfo, ResultInfoSingle} from 'src/app/interfaces'
import { WBSModel, WBSRequestModel } from '../interfaces/wbs.model';

@Injectable({
  providedIn: 'root'
})
export class WbsUiMacroApiService {
  private path = environment.wbsUiMacroApi
  private basePath = this.path + '/'
  constructor(private http: HttpClient) { }
  
  /**
   *File取得
   * filename
   * @returns 
   */
   getFileManagement(filename:string): Observable<any> {
    let requestUrl = `${this.basePath}api/file?path=${filename}`
    
    // return this.http.get<any>(requestUrl)
    // .pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     // エラーメッセージを作成します
    //     const errorMessage = `Error: ${error.status} - ${error.message} - ファイル未作成`;

    //     // `of`オペレーターを使用して、エラーメッセージをラップして返します
    //     return of(errorMessage);
    //   })
    // )
    return this.http.get<ResultInfoSingle<string>>(requestUrl)
  }
  /**
   *File登録
   * filename
   * @returns 
   */
   postFileManagement(filename:string, payload:any): Observable<ResultInfo<any>> {    
    // https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/file?path=43e6b090-d876-4bc7-aeab-40ec57fb53f6
    let requestUrl = `${this.basePath}api/file?path=${filename}`
    
    return this.http.post<ResultInfo<any>>(requestUrl, payload)
  }
  /**
   *File更新
   * filename
   * @returns 
   */
   putFileManagement(filename:string, payload:any): Observable<ResultInfo<any>> {    
    // https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/file?path=43e6b090-d876-4bc7-aeab-40ec57fb53f6
    let requestUrl = `${this.basePath}api/file?path=${filename}`
    
    return this.http.put<ResultInfo<any>>(requestUrl, payload)
  }
  /**
   *File削除
   * filename
   * @returns 
   */
   deleteFileManagement(filename:string): Observable<ResultInfo<any>> {
    let requestUrl = `${this.basePath}api/file?path=${filename}`
    return this.http.delete<ResultInfo<any>>(requestUrl)
  }

    /**
   *File取得
   * uuid
   * @returns 
   */
   getTempFile(uuid:string): Observable<any> {
    let requestUrl = `${this.basePath}api/file/temp?uuid=${uuid}`    
    return this.http.get<ResultInfoSingle<any>>(requestUrl)
  }
  /**
   *File登録
   * payload
   * @returns 
   */
   postTempFile(payload:any): Observable<ResultInfoSingle<any>> {    
    // https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/file/temp
    let requestUrl = `${this.basePath}api/file/temp`
    return this.http.post<ResultInfoSingle<any>>(requestUrl, payload)
  }
  /**
   *File更新
   * uuid
   * payload
   * @returns 
   */
   putTempFile(uuid:string,payload:any): Observable<ResultInfo<any>> {
    let requestUrl = `${this.basePath}api/file/temp?uuid=${uuid}`
    return this.http.put<ResultInfo<any>>(requestUrl, payload)
  }
  /**
   *File削除
   * uuid
   * @returns 
   */
   deleteTempFile(uuid:string): Observable<ResultInfo<any>> {
    let requestUrl = `${this.basePath}api/file/temp?uuid=${uuid}`
    return this.http.delete<ResultInfo<any>>(requestUrl)
  }
  /**
  *Wbs FQDN検索
  * @param fqdn
  */
  GetWbsByFqdn(fqdn: string): Observable<ResultInfo<WBSModel>> {
    // https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/wbs/searchFqdn?fqdn=organizations.interserve.customers
    let requestUrl = `${this.basePath}api/wbs/searchFqdn?fqdn=${fqdn}`
    return this.http.get<ResultInfo<WBSModel>>(requestUrl)
  } 

  /**
  *Wbs セル＋profile登録
  * @param payload
  */
  PostCellWithProfile(payload: WBSRequestModel): Observable<ResultInfoSingle<WBSRequestModel>> {
    // http://wbsuimacroapi.isbe.out.nakamenosakura.com/api/wbs/
    let requestUrl = `${this.basePath}api/wbs`    
    return this.http.post<ResultInfoSingle<WBSRequestModel>>(requestUrl, payload)
  }

  /**
  *Wbs セル＋profile更新
  * @param payload
  */
  PutCellWithProfile(payload: WBSRequestModel): Observable<ResultInfoSingle<WBSRequestModel>> {
    // http://wbsuimacroapi.isbe.out.nakamenosakura.com/api/wbs/
    let requestUrl = `${this.basePath}api/wbs`    
    return this.http.put<ResultInfoSingle<WBSRequestModel>>(requestUrl, payload)
  }

  /**
  *Wbs セル＋profile取得
  * @param cellId
  */
  GetCellWithProfile(cellId: string): Observable<ResultInfoSingle<WBSRequestModel>> {
    // http://wbsuimacroapi.isbe.out.nakamenosakura.com/api/wbs/{cellId}
    let requestUrl = `${this.basePath}api/wbs/${cellId}`    
    return this.http.get<ResultInfoSingle<WBSRequestModel>>(requestUrl)
  }
}
