import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError } from 'rxjs'
import { ResultInfo, ResultInfoSingle} from 'src/app/interfaces'
import { environment } from 'src/environments/environment'
import { wbsRelationModel, wbsModel, wbsRequestModel } from '../interfaces/wbs.model'
import { ApiErrorHandlingService } from './api-error-handling.service'

@Injectable({
  providedIn: 'root'
})
export class BackendUiMacroApiService {
  private path = environment.backendUiMacro
  constructor(
    private http: HttpClient,
    private apiErrorHandlingService: ApiErrorHandlingService
  ) { }

  /**
  *BC組織取得
  * @param organizationId
  */
  GetBackendOrganization(wbsId: string): Observable<ResultInfo<wbsModel>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/backendOrganization
    let requestUrl = `${this.path}/api/backendOrganization?wbsId=${wbsId}`
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  *BC組織登録
  * @param payload
  */
  PostBackendOrganization(payload:wbsRequestModel[],isDirect:boolean=false): Observable<ResultInfo<string>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/backendOrganization
    let requestUrl = `${this.path}/api/backendOrganization?isDirecte=${isDirect}`
    return this.http.post<ResultInfo<string>>(requestUrl, payload)
  }
  /**
  *BC組織変更
  * @param payload
  */
  PutBackendOrganization(payload:wbsRequestModel,isDirect:boolean=false): Observable<ResultInfo<string>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/backendOrganization
    let requestUrl = `${this.path}/api/backendOrganization?isDirect=${isDirect}`
    return this.http.put<ResultInfo<string>>(requestUrl, payload)
  }
  /**
  *BC組織削除
  * @param payload
  */
  DeleteBackendOrganization(payload:wbsRelationModel[]): Observable<{}> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/backendOrganization
    let requestUrl = `${this.path}/api/backendOrganization`
    return this.http.request('delete', requestUrl, { body: payload })
    .pipe(catchError(this.apiErrorHandlingService.handleError<any>('DeleteBackendOrganization')))
  }
  /**
  *組織プロファイル取得
  *全社共通マスタのためパラメータなし
  */
  GetOrganizationProfilesMaster(): Observable<ResultInfo<wbsModel>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/organizationProfiles/master
    let requestUrl = `${this.path}/api/organizationProfiles/master`
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  *組織図（BE&BC合体）
  * @param organizationId
  */
  GetMixedOrganizations(organizationId:string): Observable<ResultInfo<wbsModel>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/mixedOrganization?wbsId=8e24ebdd-f26a-4a28-b77c-b158cfdb00bb
     let requestUrl = `${this.path}/api/mixedOrganization?wbsId=${organizationId}`
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  *組織図（BE&BC&プロファイル合体）
  * @param organizationId
  */
  GetMixedOrganizationProfiles(organizationId:string): Observable<ResultInfo<wbsModel>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/organizationProfiles/mixedOrganizations?wbsId=9684d3b6-8061-4d50-b39c-c12b6fe34342
     let requestUrl = `${this.path}/api/organizationProfiles/mixedOrganizations?wbsId=${organizationId}`
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  *組織-プロファイル紐づけ登録
  * @param payload
  */
  PostOrgProfilesRelation(isDirect:boolean,payload:wbsRelationModel[]): Observable<ResultInfo<string>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/organizationProfiles/relation
    let requestUrl = `${this.path}/api/organizationProfiles/relation?isDirect=${isDirect}`
    return this.http.post<ResultInfo<string>>(requestUrl, payload)
  }
  /**
  *組織-プロファイル紐づけ削除
  * @param payload
  */
  DeleteOrgProfilesRelation(isDirect:boolean,payload:wbsRelationModel[]): Observable<{}> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/organizationProfiles/relation
    let requestUrl = `${this.path}/api/organizationProfiles/relation?isDirect=${isDirect}`
    return this.http.request('delete', requestUrl, { body: payload })
    .pipe(catchError(this.apiErrorHandlingService.handleError<any>('organizationProfilesRelation')))
  }
    /**
  *BE-BC組織紐づけ登録
  * @param payload
  */
  PostBeBcOrgRelation(isDirect:boolean,payload:wbsRelationModel[]): Observable<ResultInfo<string>> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/backendOrganization/relation
    let requestUrl = `${this.path}/api/backendOrganization/relation?isDirect=${isDirect}`
    return this.http.post<ResultInfo<string>>(requestUrl, payload)
  }
  /**
  *BE-BC組織紐づけ削除
  * @param payload
  */
  DeleteBeBcOrgRelation(isDirect:boolean,payload:wbsRelationModel[]): Observable<{}> {
    // https://backend-uimacroapi.backend.out.nakamenosakura.com/api/backendOrganization/relation
    let requestUrl = `${this.path}/api/backendOrganization/relation?isDirect=${isDirect}`
    return this.http.request('delete', requestUrl, { body: payload })
    .pipe(catchError(this.apiErrorHandlingService.handleError<any>('organizationProfilesRelation')))
  }
}
