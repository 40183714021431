import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {
  @Input() color = 'primary'
  @Input() disable = false
  @Input() loding = false
  @Input() hidden = false
  @Output() buttonClick = new EventEmitter()
  constructor() { }

  ngOnInit(): void {

  }

  get buttonColor(): string {
    let color = ''
    if (this.disable)
      return color = "c-btn c-btn--disable"

    switch (this.color) {
      case "primary":
        color = 'c-btn c-btn--pink'
        break
      case "cancel":
        // color = 'c-bottom-nav__link'
        color = 'c-btn c-btn--gray'
        break
      case "delete":
        color = 'c-bottom-nav__link c-bottom-nav__link--gray'
        break
      case "white":
        color = 'c-btn c-btn--white'
        break
      case "blue":
        color = 'c-btn c-btn--blue'
        break
      default:
        color = 'c-btn c-btn--pink'
        break
    }

    return color
  }
}
