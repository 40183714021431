/**
 * 現在使っているサンプルデータ
 * @description この中のデータは全て利用できる設定値です。
 */
export const wbsMappingDataResponse = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'getGarageCassette',
      header: {},
    },
    rightWbs: {
      loadAction: 'getOrganization',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'multiple',
      noSelectionWbsTypes: ['wbs'],
      fields: [
        {
          name: 'id',
          label: 'ID',
          dataType: 'string',
        },
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
        {
          name: 'wbsType',
          label: '種別',
          dataType: 'string',
        },
      ],
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'single',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
  },
  rules: {
    cassette: {
      rightArrow: {
        action: 'backward',
      },
      leftArrow: {
        action: 'copy',
      },
    },
  },
  actions: {
    getGarageCassette: {
      apiInfo: {
        url: 'http://garage-proposaloapi.garage.out.nakamenosakura.com/v1.0/cassette/{cassetteId}/list',
        urlObject: {
          cassetteId: '9643b985-0134-4c7a-bc46-e98ba7abad33',
          queryParams: {
            authDivision: 0,
            rootOnly: false,
          },
        },
      },
    },
    getOrganization: {
      event: '',
      apiInfo: {
        url: 'https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/file/temp',
        urlObject: {
          queryParams: {
            uuid: '6e2088ae-5a05-4a83-8914-640c6981f5e5',
          },
        },
      },
    },
    load: {
      event: 'onLoadCassette',
      apiInfo: {
        url: 'http://test/api/wbs/cassette/{cassetteId}',
      },
    },
    save: {
      event: 'onSave',
    },
    cancel: {
      event: 'footerCancel',
    },
    click: {},
    getData: {
      event: '',
      apiInfo: {
        url: 'https://anime-api.deno.dev/anime/v0/master/{year}/{month}',
        urlObject: {
          year: 2022,
          month: 1,
          queryParams: {
            key: 'test',
          },
        },
      },
    },
  },
  footer: {
    'right-buttons': [
      {
        name: 'saveButton',
        label: '登録する',
        action: 'save',
      },
      {
        name: 'cancelButton',
        label: 'キャンセル',
        action: 'cancel',
      },
    ],
    'left-buttons': [
      {
        name: 'getData',
        label: 'データ取得',
        action: 'getData',
      },
      {
        name: 'cancelButton',
        label: 'キャンセル',
        action: 'cancel',
      },
    ],
  },
}

export const wbsMappingDataResponse2 = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'getGarageCassette',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'multiple',
      noSelectionWbsTypes: ['wbs'],
      fields: [
        {
          name: 'id',
          label: 'ID',
          dataType: 'string',
        },
        {
          name: 'title_short1',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'single',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
  },
  actions: {
    getGarageCassette: {
      apiInfo: {
        url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexWbs/nodeObjects/all?fileSystemId=d24086a9-55f3-4329-a230-797ca71e05bb&rootOnly=false',
        urlObject: {},
      },
    },
    getOrganization: {
      event: '',
      apiInfo: {
        url: 'https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/file/temp',
        urlObject: {
          queryParams: {
            uuid: '6e2088ae-5a05-4a83-8914-640c6981f5e5',
          },
        },
      },
    },
    load: {
      event: 'onLoadCassette',
      apiInfo: {
        url: 'http://test/api/wbs/cassette/{cassetteId}',
      },
    },
    save: {
      event: 'onSave',
    },
    cancel: {
      event: 'footerCancel',
    },
    click: {},
    getData: {
      event: '',
      apiInfo: {
        url: 'https://anime-api.deno.dev/anime/v1/master/{year}/{month}',
        urlObject: {
          year: 2022,
          month: 1,
          queryParams: {
            key: 'test',
          },
        },
      },
    },
  },
}
export const AreaData = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'GetAreas',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['wbs'],
      height:'300px',
      width:'600px',
      showMenu:true,
      fields: [
        // {
        //   name: 'id',
        //   label: 'ID',
        //   dataType: 'string',
        // },
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'single',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
  },
  rules: {
    area: {
      menu: [
        {
            menuCd: 3,
            menuName: 'カセット表示',
        },
        {
            menuCd: 4,
            menuName: 'カセット作成・移動・削除',
        }
      ]
    },
  },
  actions: {
    GetAreas: {
      apiInfo: {
        url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/{customerId}',
        urlObject: {
          customerId:'b9de8178-baaf-4f3f-9ab4-d246d4f354e2'
        }
      },
    },
    getOrganization: {
      event: '',
      apiInfo: {
        url: 'https://wbsuimacroapi.isbe.out.nakamenosakura.com/api/file/temp',
        urlObject: {
          queryParams: {
            uuid: '6e2088ae-5a05-4a83-8914-640c6981f5e5',
          },
        },
      },
    },
    onSelectMenu:{
      event: 'selectRowMenu',  //ライブラリ内で設定されているメソッドを呼び出す
    },
    load: {
      event: 'onLoadCassette',
      apiInfo: {
        url: 'http://test/api/wbs/cassette/{cassetteId}',
      },
    },
    save: {
      event: 'onSave',
    },
    cancel: {
      event: 'footerCancel',
    },
    click: {},
    getData: {
      event: '',
      apiInfo: {
        url: 'https://anime-api.deno.dev/anime/v1/master/{year}/{month}',
        urlObject: {
          year: 2022,
          month: 1,
          queryParams: {
            key: 'test',
          },
        },
      },
    },
  },
}
export const AreaMappingData = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'GetAreas',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['wbs','plusButton'],
      canMoveTopNode:false,
      height:'300px',
      width:'100%',
      showMenu:false,
      fields: [        
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
    rightWbs: {
      loadAction: '',
      header: {},
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['wbs','plusButton'],
      height:'300px',
      width:'100%',
      showMenu:false,
      fields: [        
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
    footer:{
      buttons : [
         {key: 'cancel',
          id: '1',
          name: 'cancel-button',
          label: '元に戻す',
          color: 'cancel',
          disable: false,
          hidden: false,
          action:'revert'},
          {key: 'register',
          id: '2',
          name: 'save-button',
          label: '登録する',
          color: 'primary',
          disable: false,
          hidden: false,
          // action:'save'}
          action:'dialogBeforesave'}
      ]
    }
  },
  rules: {
    area: {
      rightArrow: {
        action: 'backward',
      },
      leftArrow: {
        action: 'forward',
      }      
    },
  },
  actions: {
    GetAreas: {
      apiInfo: {
        url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/{customerId}',
        urlObject: {
          customerId:'b9de8178-baaf-4f3f-9ab4-d246d4f354e2'
        }
      },
    },
    onSelectMenu:{
      event: 'selectRowMenu',  //ライブラリ内で設定されているメソッドを呼び出す
    },
    // load: {
    //   event: 'onLoadCassette',
    //   apiInfo: {
    //     url: 'http://test/api/wbs/cassette/{cassetteId}',
    //   },
    // },
    dialogBeforesave:{
      event: 'showSaveDialog',
      messageCd: 'confirmRun',
    },
    save: {
      event: 'onSave',
      apiInfo:[ 
        {apiNo:0,
          apiName:'PostAreaMany',
          url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/children',
          payload: {},
          apiMethod:'post'
          // エリア・エリア紐づけ登録(複数)
          // PostAreaMany(payload: any): Observable<ResultInfo<wbsModel>> {
          //   let requestUrl = `${this.basePath}api/area/children`    
          //   return this.http.post<ResultInfo<wbsModel>>(requestUrl, payload)
          // }
        },
        {apiNo:1,
          apiName:'DeleteAreaMany',
          url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/children',
          payload: {},
          apiMethod:'delete'
          // エリア・エリア紐づけ削除(複数)
          // DeleteAreaMany(payload: any): Observable<any> {
          //   let requestUrl = `${this.basePath}api/area/children`    
          //   return this.http.request('delete', requestUrl, { body: payload});
          // }
        },
      ],
    },
    cancel: {
      event: 'dialogCancel',
    },
    revert: {
      event: 'footerCancel',
    },
    ok:{
      event: 'dialogCancel',
    },
    click: {},
    getData: {
      event: '',
      apiInfo: {
        url: 'https://anime-api.deno.dev/anime/v1/master/{year}/{month}',
        urlObject: {
          year: 2022,
          month: 1,
          queryParams: {
            key: 'test',
          },
        },
      },
    }
  },
}
export const SelectwbsItemData = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'GetWbs',
      header: {},
    },
    leftWbsRules: {
      // childDataKey: 'nodeObjects',
      childDataKey: '',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['wbs'],
      height:'400px',
      width:'650px',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
      showMenu: true
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'single',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
  },
  rules: {
    'wbs-item': {
      menu: [
        { menuCd: 7, menuName: 'アップロードアイテム表示' }
      ]
    }
  },    
  actions: {
    GetWbs: {
      apiInfo: {
        url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/wbsItem/list',
        urlObject: {            
          queryParams: {
            fileSystemId: 'd24086a9-55f3-4329-a230-797ca71e05bb'
          },
        },
      },
    },      
    load: {
      // event: 'onLoadCassette',
      // apiInfo: {
      //   url: 'http://test/api/wbs/cassette/{cassetteId}',
      // },
    },
    onSelectMenu:{
      event: 'selectRowMenu',  //ライブラリ内で設定されているメソッドを呼び出す
    },
    save: {
      event: 'onSave',
    },
    cancel: {
      event: 'footerCancel',
    },
    click: {},
    // getData: {
    //   event: '',
    //   apiInfo: {
    //     url: 'https://anime-api.deno.dev/anime/v1/master/{year}/{month}',
    //     urlObject: {
    //       year: 2022,
    //       month: 1,
    //       queryParams: {
    //         key: 'test',
    //       },
    //     },
    //   },
    // },
  },
}
export const wbsItemViewerData = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'GetWbs',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['wbs'],
      height:'400px',
      width:'600px',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
      showMenu: true
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'single',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
  },
  rules: {
    'wbs-index': {
      menu: [
        { menuCd: 1, menuName: 'ファイルアップロード' }
      ]
    },
    'file': {
      menu: [
        { menuCd: 2, menuName: 'ファイルダウンロード' }
      ]
    }
  },    
  actions: {
    GetWbs: {
      apiInfo: {
        url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/wbsItem/wbs',
        urlObject: {            
          queryParams: {
            wbsItemId: '52e9a61f-77ba-4f4c-a368-1b8af4a61f6a'
          },
        },
      },
    },      
    load: {
      // event: 'onLoadCassette',
      // apiInfo: {
      //   url: 'http://test/api/wbs/cassette/{cassetteId}',
      // },
    },
    onSelectMenu:{
      event: 'selectRowMenu',  //ライブラリ内で設定されているメソッドを呼び出す
    },
    save: {
      event: 'onSave',
    },
    cancel: {
      event: 'footerCancel',
    },
    click: {},
    // getData: {
    //   event: '',
    //   apiInfo: {
    //     url: 'https://anime-api.deno.dev/anime/v1/master/{year}/{month}',
    //     urlObject: {
    //       year: 2022,
    //       month: 1,
    //       queryParams: {
    //         key: 'test',
    //       },
    //     },
    //   },
    // },
  },
}
export const AreaMappingHubData = {
  items : {
    type : 'list',
    dialog : {		
      width:'500px',
      maxHeight:'100%',
    }
  },
  layout: {    
    leftWbs: {
      loadAction: '',
      addButtonAction: 'plusButtonAction',
      header: {
        label: 'エリアを追加',
      },
      footer: {
        rightButton: {
          key: 'select',
          id: '1',
          name: 'select-button',
          label: '選択',
          color: 'primary',
          disable: false,
          hidden: false
        },
        leftButton: {
          key: 'cancel',
          id: '0',
          name: 'cancel-button',
          label: 'キャンセル',
          color: 'cancel',
          disable: false,
          hidden: false
        },     
      },
    },
    leftWbsRules: {
      childDataKey: '',
      hideHeader: false,
      height:'300px',
      width:'100%',
      rowSelection: 'multiple',
      noSelectionWbsTypes: ['plusButton'],
      canAddElement: true,
      addElementType:'area',
      canUseAddDialog:true,
      fields: [
        {
          name: 'name',
          label: '名称',
          width: '100%',
          dataType: 'string',
        },
      ],
    },
  },
  rules: {
    template: {},
  },
  actions: {
    plusButtonAction: {
      event: 'onClickPlus',
    },
    loadHub: {
      event: 'onLoadHub',
      defaultData:[]
      // apiInfo: {
      //   url: 'http://test/api/wbs/cassette/{cassetteId}',
      // },
    },
  },
}
export const selectAreaData = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    leftWbs: {
      loadAction: 'GetAreas',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['wbs'],
      height:'400px',
      width:'600px',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
      showMenu: true
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: false,
      rowSelection: 'single',
      fields: [
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
  },
  rules: {
    area: {
      menu: [
          {  menuCd: 3, menuName: 'カセット表示'},
          {  menuCd: 4, menuName: 'カセット作成・移動・削除'},
          {  menuCd: 7, menuName: 'アップロードアイテム表示' }
      ]
    },
  },
  actions: {
    GetAreas: {
      apiInfo: {
        // url: '//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/{customerId}',
        url: '/api/area/{customerId}',
        urlObject: {
          customerId:'b9de8178-baaf-4f3f-9ab4-d246d4f354e2'
        }
      },
    },      
    onSelectMenu:{
      event: 'selectRowMenu',  //ライブラリ内で設定されているメソッドを呼び出す
    },
    save: {
      event: 'onSave',
    },
    cancel: {
      event: 'footerCancel',
    },
    click: {}
  }
}

export const CassetteMappingData = {
  "layout": {
    "style": {
      "direction": "row"
    },
    "leftWbs": {
      "loadAction": "GetAreaCassettes",
      "header": {}
    },
    "leftWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs","plusButton"],
      "canMoveTopNode":false,
      "height":"300px",
      "width":"100%",
      "showMenu":false,
      "fields": [        
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    },
    "rightWbs": {
      "loadAction": "",
      "header": {}
    },
    "rightWbsRules": {
      "childDataKey": "nodeObjects",
      "hideHeader": true,
      "rowSelection": "single",
      "hideRowSelectors":true,
      "noSelectionWbsTypes": ["wbs","plusButton"],
      "height":"300px",
      "width":"100%",
      "showMenu":false,
      "fields": [        
        {
          "name": "name",
          "label": "名前",
          "dataType": "string"
        }
      ]
    },
    "footer":{
      "buttons": [
          {"key": "cancel",
          "id": "1",
          "name": "cancel-button",
          "label": "元に戻す",
          "color": "cancel",
          "disable": false,
          "hidden": false,
          "action":"revert"},
          {"key": "register",
          "id": "2",
          "name": "save-button",
          "label": "登録する",
          "color": "primary",
          "disable": false,
          "hidden": false,
          "action":"dialogBeforesave"}
      ]
    }
  },
  "rules": {
    "cassette": {
      "rightArrow": {
        "action": "backward"
      },
      "leftArrow": {
        "action": "forward"
      }      
    }
  },
  "actions": {
    "GetAreaCassettes": {
      "apiInfo": {
        "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/cassette/areamixed/list/{customerId}",
        "urlObject": {
          "customerId":"b9de8178-baaf-4f3f-9ab4-d246d4f354e2",
          "queryParams": {
            "areaId": ""
          }
        }
      }
    },
    "onSelectMenu":{
      "event": "selectRowMenu"
    },
    "dialogBeforesave":{
      "event": "showSaveDialog",
      "messageCd": "confirmRun"
    },
    "save": {
      "event": "onSave",
      "apiInfo":[ 
        {"apiNo":0,
          "apiName":"PostCassetteMany",
          "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/cassette/many",
          "payload": {},
          "apiMethod":"post"
        },
        {"apiNo":1,
          "apiName":"DeleteCassetteMany",
          "url": "https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/cassette/relation/many",
          "payload": {},
          "apiMethod":"delete"
        }
      ]
    },
    "cancel": {
      "event": "dialogCancel"
    },
    "revert": {
      "event": "footerCancel"
    },
    "ok":{
      "event": "dialogCancel"
    },
    "click": {}
  }
}
export const SimulationMappingData = {
  layout: {
    style: {
      direction: 'row', // row | column
    },
    // objectがあるかどうかしか見ていない。中身は見ていない。
    leftWbs: {
      loadAction: 'onLoadLeft',
      header: {},
    },
    leftWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['title','plusButton'],
      canMoveTopNode:false,
      height:'300px',
      width:'100%',
      showMenu:false,
      fields: [        
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
    rightWbs: {
      loadAction: 'onLoadRight',
      header: {},
    },
    rightWbsRules: {
      childDataKey: 'nodeObjects',
      hideHeader: true,
      rowSelection: 'single',
      hideRowSelectors:true,
      noSelectionWbsTypes: ['title','plusButton'],
      height:'300px',
      width:'100%',
      showMenu:false,
      fields: [        
        {
          name: 'name',
          label: '名前',
          dataType: 'string',
        },
      ],
    },
    footer:{
      buttons : [
         {key: 'cancel',
          id: '1',
          name: 'cancel-button',
          label: '元に戻す',
          color: 'cancel',
          disable: false,
          hidden: false,
          action:'revert'},
          {key: 'register',
          id: '2',
          name: 'save-button',
          label: '登録する',
          color: 'primary',
          disable: false,
          hidden: false,
          // action:'save'}
          action:'dialogBeforesave'}
      ]
    }
  },
  rules: {
    cassette: {
      rightArrow: {
        action: 'backward',
      },
      leftArrow: {
        action: 'forward',
      }      
    },
  },
  actions: {
    // GetAreas: {
    //   apiInfo: {
    //     url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/{customerId}',
    //     urlObject: {
    //       customerId:'b9de8178-baaf-4f3f-9ab4-d246d4f354e2'
    //     }
    //   },
    // },
    onLoadRight: {
      event: 'loadRightStaticData',
    },
    onLoadLeft: {
      event: 'loadLeftStaticData',
    },
    onSelectMenu:{
      event: 'selectRowMenu',  //ライブラリ内で設定されているメソッドを呼び出す
    },
    dialogBeforesave:{
      event: 'showSaveDialog',
      messageCd: 'confirmRun',
    },
    save: {
      event: 'onSave',
      apiInfo:[ 
        // {apiNo:0,
        //   apiName:'PostAreaMany',
        //   url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/children',
        //   payload: {},
        //   apiMethod:'post'
        // },
        // {apiNo:1,
        //   apiName:'DeleteAreaMany',
        //   url: 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/area/children',
        //   payload: {},
        //   apiMethod:'delete'
        // }
      ],
    },
    cancel: {
      event: 'dialogCancel',
    },
    revert: {
      event: 'footerCancel',
    },
    ok:{
      event: 'dialogCancel',
    },
    click: {}
  },
}
export const SimulationStaticLeftWbs = [
  {
      "userSource": "text",
      // "authDivision": 1,
      // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
      "wbsType": "title",
      "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
      // "creatorId": "b9de8178-baaf-4f3f-9ab4-d246d4f354e2",
      // "creatorName": "熊川",
      "id": "d24086a9-55f3-4329-a230-797ca71e05bb",
      "name": "作業日",
      "nodeObjects": [
          {
              "userSource": "text",
              // "authDivision": 1,
              // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              "wbsType": "area",
              "parentId": "d24086a9-55f3-4329-a230-797ca71e05bb",
              // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              // "creatorId": "b9de8178-baaf-4f3f-9ab4-d246d4f354e2",
              // "createdAt": "2024-01-17T03:14:05.0508843+00:00",
              // "lastUpdatedAt": "2024-01-17T03:14:05.0508843+00:00",
              "id": "86cf520e-982e-41fd-97e0-b68398ae5ef5",
              "name": "3/4(月)"
          },
          {
              "userSource": "text",
              // "authDivision": 1,
              // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              "wbsType": "area",
              "parentId": "d24086a9-55f3-4329-a230-797ca71e05bb",
              // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              // "createdAt": "2024-01-22T02:47:18.8884505+00:00",
              // "lastUpdatedAt": "2024-02-26T02:45:29.6055579+00:00",
              "id": "9602094a-2204-4b3a-a0c3-e1500f3c2168",
              "name": "3/5(火)"
          }
      ]
      
  }
]
export const SimulationStaticRightWbs = [
  {
      "userSource": "text",
      // "authDivision": 1,
      // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
      "wbsType": "title",
      // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
      // "creatorId": "b9de8178-baaf-4f3f-9ab4-d246d4f354e2",
      // "creatorName": "熊川",
      "id": "97cd4b8c-d292-4c99-9183-05f28fdf6407",
      "name": "納品日",
      "nodeObjects": [
          {
              "userSource": "organization",
              // "authDivision": 1,
              // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              "wbsType": "cassette",
              "parentId": "97cd4b8c-d292-4c99-9183-05f28fdf6407",
              // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              // "createdAt": "2024-02-29T00:18:10.008067+00:00",
              // "lastUpdatedAt": "2024-02-29T00:24:25.0876032+00:00",              
              "id": "6b4a7cb2-79d5-49a9-9f7e-5b403023b4d7",
              "name": "3/5(火)",
              "nodeObjects": [
                {
                    "userSource": "organization",
                    // "authDivision": 1,
                    // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                    "wbsType": "cassette",
                    "parentId": "6b4a7cb2-79d5-49a9-9f7e-5b403023b4d7",
                    // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                    // "createdAt": "2024-02-29T00:19:35.210758+00:00",
                    // "lastUpdatedAt": "2024-02-29T00:19:35.210758+00:00",
                    "id": "cdfb8e03-c538-4dd0-a0ec-09b6b40f7ba2",
                    "name": "新店"
                },
                {
                  "userSource": "organization",
                  // "authDivision": 1,
                  // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                  "wbsType": "cassette",
                  "parentId": "6b4a7cb2-79d5-49a9-9f7e-5b403023b4d7",
                  // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                  // "createdAt": "2024-02-29T00:19:35.210758+00:00",
                  // "lastUpdatedAt": "2024-02-29T00:19:35.210758+00:00",
                  "id": "b93936ad-cbd1-49f9-8ac9-66b040b03d2b",
                  "name": "客注"
              },
              {
                "userSource": "organization",
                // "authDivision": 1,
                // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                "wbsType": "cassette",
                "parentId": "6b4a7cb2-79d5-49a9-9f7e-5b403023b4d7",
                // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                // "createdAt": "2024-02-29T00:19:35.210758+00:00",
                // "lastUpdatedAt": "2024-02-29T00:19:35.210758+00:00",
                "id": "b658bf86-292d-4f71-bc4a-9a3d8f53914e",
                "name": "EC"
            }
            ]
          },
          {
            "userSource": "text",
            // "authDivision": 1,
            // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
            "wbsType": "cassette",
            "parentId": "97cd4b8c-d292-4c99-9183-05f28fdf6407",
            // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
            // "creatorId": "b9de8178-baaf-4f3f-9ab4-d246d4f354e2",
            // "createdAt": "2024-01-17T03:14:05.0508843+00:00",
            // "lastUpdatedAt": "2024-01-17T03:14:05.0508843+00:00",
            "id": "f2b66720-2ab2-491f-a8f1-a555e2be61bb",
            "name": "3/6(水)",
            "nodeObjects": [
              {
                  "userSource": "organization",
                  // "authDivision": 1,
                  // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                  "wbsType": "cassette",
                  "parentId": "f2b66720-2ab2-491f-a8f1-a555e2be61bb",
                  // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                  // "createdAt": "2024-02-29T00:19:35.210758+00:00",
                  // "lastUpdatedAt": "2024-02-29T00:19:35.210758+00:00",
                  "id": "e2f996ae-456a-4f63-a4f7-7d9af54acfd4",
                  "name": "新店"
              },
              {
                "userSource": "organization",
                // "authDivision": 1,
                // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                "wbsType": "cassette",
                "parentId": "f2b66720-2ab2-491f-a8f1-a555e2be61bb",
                // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
                // "createdAt": "2024-02-29T00:19:35.210758+00:00",
                // "lastUpdatedAt": "2024-02-29T00:19:35.210758+00:00",
                "id": "3f7d7b70-61bf-4eff-ae23-783071e51d84",
                "name": "客注"
            },
            {
              "userSource": "organization",
              // "authDivision": 1,
              // "ownId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              "wbsType": "cassette",
              "parentId": "f2b66720-2ab2-491f-a8f1-a555e2be61bb",
              // "ownerId": "486e1159-b3c6-48e0-ae9c-e523ee5a779c",
              // "createdAt": "2024-02-29T00:19:35.210758+00:00",
              // "lastUpdatedAt": "2024-02-29T00:19:35.210758+00:00",
              "id": "75893468-798c-4d1e-a32f-e40c813a52ba",
              "name": "通常"
          }
          ]
        }
      ]
      
  }
]
export const selectDataHubInfo = {
  items : {
    type : 'list',
    dialog : {		
      width:'100%',
      maxHeight:'100%',
    }
  },
  layout: {
    leftWbs: {
    loadAction: '',
    header: {
        label: 'どれをテストデータとして使いますか？',
        // useNgContent:true
    },
    footer: {
      rightButton: {        
        key: 'select',
        name: "right-button",
        label: '選択',
        color: "primary",
        disabled:false
      },
      leftButton: {
        key: 'cancel',
        name: "left-button",
        label: 'キャンセル',
        color: "cancel",
        disabled:false
      },
    },
    },
    leftWbsRules: {
    childDataKey: '',
    hideHeader: false,
    height:'300px',
    width:'400px',
    rowSelection: 'multiple',
    // noSelectionWbsTypes: ['catalog'],
    fields: [
        {
        name: 'name',
        label: '名称',
        width: 'calc(100% - 60px)',
        dataType: 'string',
        },
    ],
    },
},
rules: {
    template: {},
},
actions: {
    // getWbsItem: {
    // event: '',
    // apiInfo: {
    //     url: '//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexWbs/fqdn',
    //     urlObject: {            
    //       queryParams: {
    //         fqdn: 'catalog.services'            
    //       },            
    //     },
    //   },
    // },
},
}
export const selectDataHub = [
  {
      "id": "86cf520e-982e-41fd-97e0-b68398ae5ef5",
      "name": "3/5 データ"
  },
  {      
      "id": "58529c21-ce8e-47b1-9668-b88068dc089e",
      "name": "4/5 テストデータ"
  },
  {      
      "id": "c28cfbeb-911b-45d2-a9d5-f3ddfcb37621",
      "name": "is ホームセンターデモ"
  },
  {      
      "id": "928b37b7-ead2-4377-9c1b-b0e0a4517dc9",
      "name": "is---デモ"
  }
]