import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
7
@Component({
  selector: 'app-add-element-setting',
  standalone: true,
  imports: [CommonModule,FormsModule],
  templateUrl: './add-element-setting.component.html',
  styleUrls: ['./add-element-setting.component.scss']
})
export class AddElementSettingComponent implements OnInit {    

  @Input() element:any ={id:'',name:''};
  @Output() elementChange = new EventEmitter<any>();

  constructor(
  ) {}

  ngOnInit() {    
  }
  inputElementName(value: any) {
    this.elementChange.emit(this.element)
  }
}
