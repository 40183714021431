import { Component, OnInit, ViewChild } from '@angular/core';
import { wbsModel } from 'src/app/interfaces/wbs.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { AuthService } from 'src/app/services/auth.service';
import { WbsService } from 'src/app/services/wbs.service';
import { environment } from 'src/environments/environment';
import { FrontEndParams } from '@interserve/igx-angular';

@Component({
  selector: 'app-select-wbs-item',
  templateUrl: './select-wbs-item.component.html',
  styleUrls: ['./select-wbs-item.component.scss']
})
export class SelectWbsItemComponent implements OnInit {
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent

  public frontendCd="selectWbsItemV2"
  public title = 'ドキュメント 管理／アップロードしたアイテム表示（Wbsアイテム一覧）'
  public loading=false

  public customerId=''
  public organizaionId=''
  
  public childDataKey:string=''
  public selectedArea:any
  public selectedAreaId:string=''
  
  //WBSライブラリ用
  public frontEndParams!: FrontEndParams;
  public targetWbs:wbsModel | undefined

  constructor(
    private wbsService:WbsService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.customerId=this.authService.Account?.customerId??''

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.selectedAreaId=params.get('areaId') ?? ''
    })    
    this.initialize() 
  }

  async initialize(){
    this.loading=true    
    try {        
        //フロントエンド設定をWBSに渡す
        let param={fileSystemId:this.selectedAreaId}
        this.frontEndParams = this.wbsService.setFrontendParams(this.frontendCd, param)
        this.loading=false
    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }  
  clickSubMenu(arg: any){
    //アップロードしたアイテムの一覧を表示
    if(arg.selectedMenu===7){
      this.router.navigate([ `/wbs-item-viewer/${this.selectedAreaId}/${arg.selectedData.id}/1`]);    //0:閲覧のみ、1:追加可     
    }    
  }
  gotoArea(){
    this.router.navigate([ `/select-area`]);
  }
}
