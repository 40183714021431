import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
  } from '@angular/core';
import { ButtonSetting } from '../frontEndSetting';
import { CommonModule } from '@angular/common';
  
  @Component({
    selector: 'is-igx-button',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './is-igx-button.component.html',
    styleUrls: ['./is-igx-button.component.scss'],
  })
  export class IsIgxButtonComponent implements OnInit {
    @Input() buttonCaption = '';
    @Input() buttonData:ButtonSetting | undefined
    @Output() click = new EventEmitter();
    constructor() { }
  
    ngOnInit(): void { }

    _clickButton(event:any){
        this.click.emit()
        event.stopPropagation() 
    }
    get buttonColor(): string {
        let color = ''
        if (this.buttonData?.disable)
          return color = "btn btn--disable"
    
        switch (this.buttonData?.color) {
          case "primary":
            color = 'btn btn--primary'
            break
          case "cancel":
            color = 'btn btn--cancel'
            break
          default:
            color = 'btn btn--primary'
            break
        }    
        return color
      }
  }