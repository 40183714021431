<!-- <is-igx-wbs *ngIf="!loading" 
    [mappingData]="mappingData"
    (clickSubMenu)="clickSubMenu($event)">
</is-igx-wbs> -->

<!-- エリア紐づけ確認 -->
<!-- <is-igx-mapping *ngIf="!loading" 
    [mappingData]="mappingData"
    [mappingDataHub]="mappingDataHub"    
    (clickSubMenu)="clickSubMenu($event)"
    (clickPlusElement)="clickPlusElement($event)"
    >
</is-igx-mapping> -->
<!--テストデータ選択ダイアログ-->
<div style="margin: 20px;">
    <is-igx-hub
        [mappingData]="selectTestDataHubSettings"
        [wbsStaticData]="staticSelectTestData"
        [actions]="selectTestDataHubActions"
        #selectDataHub>    
    </is-igx-hub>
    <button igxButton="flat" (click)="openTestDataHub()">テストデータ選択 HUB Open</button>
</div>
<!-- 固定データ確認 -->
<is-igx-mapping *ngIf="!loading" 
    [mappingData]="mappingData"
    [leftWbsStaticData]="StaticLeftWbsData"
    [rightWbsStaticData]="StaticRightWbsData"
    [mappingDataHub]="mappingDataHub"    
    (clickSubMenu)="clickSubMenu($event)"
    (clickPlusElement)="clickPlusElement($event)"
    >
</is-igx-mapping>



<pre>
    <textarea [(ngModel)]="mappingDataString" style="width: 90%; height: 300px"></textarea>
    <button (click)="reflectMappingData()">反映</button> 
 </pre>