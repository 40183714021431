
<div class="l-main__contents">
    <div id="wrapper">
      <div class="c-text-1 h-margin-b24">{{title}}</div>
      <div class="radio-area">
          <igx-radio *ngFor="let org of orgList"
              name="org" 
              [value]="org.fqdn"
              [(ngModel)]="selectedOrgFqdn">
              {{org.name}}
          </igx-radio>
      </div>
      <div class="radio-area">
        <igx-radio *ngFor="let mode of modelist"
            name="mode" 
            [value]="mode.value"
            [(ngModel)]="selectedPetaboMode">
            {{mode.name}}
        </igx-radio>
    </div>
      <div class="button-area">
        <li class="button-margin" *ngFor="let button of buttonData">
            <app-button
                [disable]="button.disable"
                [hidden]="button.hidden || false"
                [color]="button.color"
                (click)="buttonClick(button.key)">
                {{ button.name }}
            </app-button>
        </li>
    </div>
    <app-petabo #petabo></app-petabo>
</div>
<app-petabo-dialog #petabodialog></app-petabo-dialog>
<!--カタログ選択ダイアログ-->
<app-hub-dialog
  #catalogHub
  [hubSettings]="hubSettings"
  (clickSelectItem)="selectItem($event)">
</app-hub-dialog> 
