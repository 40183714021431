import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { WbsService } from 'src/app/services/wbs.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-update-wbs-item',
    templateUrl: './update-wbs-item.component.html',
    styleUrls: ['./update-wbs-item.component.scss'],

})
export class UpdateWbsItemComponent implements OnInit {
    @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent

    public appCd = environment.appCd
    public frontendCd = "updateWbsItem"
    public hubFrontendCd = "updateWbsItemHub"
    public catalogHubFrontendCd = "updateWbsItemCatalogHub"
    public title = 'wbs-item更新'

    public apiLoading = false
    public loading = false

    public customerId = ''
    private selectedWbsItemId = ''
    private selectedAreaId = ''

    public mappingData: any = {}
    public mappingDataHub: any = {}
    public mappingDataCatalogHub:any={}

    constructor(
        private wbsService: WbsService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.customerId = this.authService.Account?.customerId ?? ''
        this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this.selectedAreaId=params.get('areaId') ?? ''
            this.selectedWbsItemId = params.get('wbsItemId') ?? ''
        })
        this.initialize()
    }
    async initialize() {
        this.loading = true
        try {
            // 紐づけWBSの設定
            let settings = await this.wbsService.getFrontendSettings(this.appCd, this.frontendCd)
            if (settings !== null) {
                settings.actions.GetWbs.apiInfo.urlObject.queryParams.wbsItemId = this.selectedWbsItemId;
                this.mappingData = settings
            }
            // Hub(wbs作成)の設定
            let hubsettings = await this.wbsService.getFrontendSettings(this.appCd, this.hubFrontendCd)
            if (hubsettings !== null) {
                this.mappingDataHub = hubsettings
            }
            // Hub(カタログ)の設定
            let catalogHubsettings = await this.wbsService.getFrontendSettings(this.appCd, this.catalogHubFrontendCd)
            catalogHubsettings.actions.getWbsItem.apiInfo.urlObject.queryParams.fileSystemId = this.selectedAreaId;
            if (catalogHubsettings !== null) {
                this.mappingDataCatalogHub = catalogHubsettings
            }
            this.loading = false
        } catch (error) {
            this.snackbar.error('画面の初期化が失敗しました')
            this.loading = false
        }
    }
    clickSubMenu(value: any) {
        console.log('clickSubMenu:' + value)
    }
    clickPlusElement(value: any) {
        //デフォルトの要素追加ダイアログを使わない場合はここで独自ダイアログを呼び出す（My-ideaの顧客登録などと連携）
        console.log('clickPlusElement:' + value)
    }
    gotoArea() {
        this.router.navigate([`/select-area`]);
    }
    selectItem(item: any) {
        console.log('selectItem:')
    }
}
