<igx-dialog 
    #uploadDialog 
    [closeOnOutsideSelect]="true">
        
    <div class="dialog-header">
      <div class="dialog-description">
          ファイルをアップロード
      </div>
      <div class="position-right">
        <a (click)="close()">
            <span class="material-icons icon-style">
                cancel
            </span>
        </a>  
      </div>
    </div>

    <div
        (drop)="onDrop($event)"
        (dragover)="dragOver($event)"
        class="p-data-upload__area">
        <div class="file-upload-text file-upload-text--l">ここにファイルをドロップ</div>
        <div class="file-upload-text file-upload-text--s">または</div>
        <div class="file-upload">
            <label
              for="upload1"
              class="file-upload__label btn btn--primary">            
              <span class="file-upload__btn">
                ファイルを選択
              </span>
            </label>

            <!-- <input
              (change)="change(file.files)"
              type="file"
              class="file-upload__input"
              name="upload1"
              id="upload1"
              #file
              accept="text/csv"
            /> -->
            <input
              (change)="change(file.files)"
              type="file"
              class="file-upload__input"
              name="upload1"
              id="upload1"
              #file
              accept={{fileType}}
            />
          </div>
    </div>
</igx-dialog>

<app-selected-file-dialog
  #selectedFileDialog
  [uploadfile]="uploadfile"
  (clickUpload)="upload($event)">    
</app-selected-file-dialog>

