<div class="l-main__contents">
  <div id="wrapper">
    <div class="c-text-1 h-margin-b24">{{title}}</div>
    <div class="grid-area">
      <div class ="loading" *ngIf="loading">
          <app-loding-circle></app-loding-circle>
          </div>
          <div *ngIf="updatable" class="cassette-list-header">
            <a class="goto-area" (click)="gotoArea()">
              エリア選択に戻る
            </a>
            <a class="goto-cassette-list" (click)="gotoCassetteList()">
              カセット作成・移動・削除へ
            </a>
          </div>
          <iframe
            *ngIf="targetUrl!==undefined&&targetUrl!==''"
            id="iframe"
            [src]="targetUrl"
            class="iframe-area"
            (load)="iframeloaded($event)"
            frameborder="0"
            style="border: none"
          ></iframe>
    </div>
  </div>
</div>
<app-footer id="pageFooter" style="margin-top: auto;">
  <li class="c-bottom-nav__item button-margin" *ngFor="let button of buttonData">
  </li>
</app-footer> 

<app-snackbar #snackbar [autoHide]="true"></app-snackbar>

<app-upload-dialog
  #uploadFileDialog
  [fileType]="'image/png,image/jpeg,image/gif,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,video/*'"
  (error)="uploadError($event)"
  (clickUpload)="addFile($event)"
></app-upload-dialog>

<app-yes-no-dialog
  #yesNoDialog
  (clickNoButton)="dialogCancel()"
  (clickYesButton)="buttonClick('del_file')"
  [headerMessage]="dialogParams_del_file.title"
  [message]="dialogParams_del_file.message"
  [buttonId]="dialogParams_del_file.buttonId"
  [isShowCloseButton]="false"
>
</app-yes-no-dialog>