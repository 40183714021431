<div *ngIf="petaboFqdn" class="l-main__contents"
  [ngClass]="{'l-main__contents_dialog' :isDialog===true }">
  <div>
    <div class="title-box">
      <div class="svg-flex-area">
        <div class="petabo-logo"></div>
      </div>
      <div class="c-modal__title cell__inner">{{ title }}</div>
      <div class="svg-flex-area cursor-pointer">
        <span class="reload-svg" (click)="reload()"> </span>
      </div>
      <div class="svg-flex-area  cursor-pointer">
        <span class="close-svg c-modal__close" (click)="close()"> </span>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loading-area">
    <!-- <app-loding-circle></app-loding-circle> -->
  </div>
  <iframe
    id="iframe"
    [src]="targetUrl"
    class="iframe-area"
    (load)="petaboloaded($event)"
    frameborder="0"
    style="border: none"
  ></iframe>
</div>
