import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { wbsLayout } from './is-igx-wbs.model'
import { IsApiService, IsUtilityService } from '@interserve/angular'
import { IsWbsService } from 'src/app/services//is-wbs.service'
import { IsIgxWbsTreeGridComponent } from '@interserve/igx-angular'
import { IsIgxHubComponent } from '@interserve/igx-angular'
import { FormsModule } from '@angular/forms'
import { IsIgxDialogComponent } from '@interserve/igx-angular'
import { AddElementDialogComponent } from './components/add-element-dialog/add-element-dialog.component'
import { ActionKey, ButtonSetting, DialogParams } from './components/frontEndSetting'
import { IsIgxDialogMessageComponent } from './components/is-igx-dialog-message/is-igx-dialog-message.component'
import { HttpHeaders } from '@angular/common/http'
import { IsIgxButtonComponent } from './components/is-igx-button/is-igx-button.component'

interface actions {
  [key: string]: (...args: any[]) => any
}
interface footer {
  [key: string]: footerButton[]
}
interface footerButton {
  name: string
  label: string
  action: string | ((...args: any[]) => any)
}

@Component({
  selector: 'is-igx-mapping-wbs-item',
  standalone: true,
  imports: [CommonModule, IsIgxWbsTreeGridComponent, IsIgxHubComponent, FormsModule, AddElementDialogComponent, IsIgxButtonComponent, IsIgxDialogMessageComponent],
  templateUrl: './is-igx-mapping-wbs-item.component.html',
  styleUrl: './is-igx-mapping-wbs-item.component.scss',
})
export class IsIgxMappingWbsItemComponent {
  @Input() leftWbsActions: actions = {}
  @Input() leftWbsLayout: any = {}
  @Input() leftWbsGridRules: any = {}
  @Input() leftWbsData: any = []
  @Input() leftWbsFields: any = []
  @Input() leftWbsStaticData: any = []
  //#endregion

  // #region rightWbs
  @Input() rightWbsActions: actions = {}
  @Input() rightWbsLayout: any = {}
  @Input() rightWbsGridRules: any = {}
  @Input() rightWbsData: any = []
  @Input() rightWbsFields: any = []
  @Input() rightWbsStaticData: any = []
  // #endregion

  @Input() wbsRules: any = {}

  @Output() clickSubMenu = new EventEmitter<any>()

  /**
   * データのマッピング情報、LayoutとかFooterとか
   */
  @Input() mappingData: any = undefined
  footer: footer = {}
  layout: wbsLayout | undefined = undefined
  @Input() mappingId: string | undefined = undefined

  @ViewChild('leftWbs', { static: false })
  public leftWbs: IsIgxWbsTreeGridComponent | undefined
  @ViewChild('rightWbs', { static: false })
  public rightWbs: IsIgxWbsTreeGridComponent | undefined

  @ViewChild('messageDialog', { static: true }) public messageDialog: IsIgxDialogMessageComponent | undefined

  public reloadFlg: boolean = false
  public reload() {
    this.reloadFlg = true
    setTimeout(() => {
      this.reloadFlg = false
    }, 0)
  }

  // #region From
  addWbsName = ''
  // resetForm() {
  //   this.addWbsName = ''
  // }
  // #endregion
  dialogMessageCd = ''

  ngOnInit() {
    this.initialize()
  }
  constructor(
    private isApiService: IsApiService,
    public isUtilityService: IsUtilityService,
    private isWbsService: IsWbsService
  ) { }
  private async initialize() {
    const items = this.getMappingData(this.mappingId)
    if (items) {
      const { layout, footer, rules, actions } = items
      this.footer = footer || {}

      this.initLayout(layout)
      this.initGridRules(layout)

      console.log('initialize getWbsData 呼出し')
      await this.getWbsData(layout, actions)
      this.wbsRules = rules
    }
    this.initHub()

    return true
  }
  initLayout(layout: wbsLayout): void {
    this.layout = layout
    const leftWbs = layout.leftWbs
    const rightWbs = layout.rightWbs
    this.leftWbsLayout = leftWbs || {}
    this.rightWbsLayout = rightWbs || {}
  }
  initGridRules(layout: wbsLayout): void {
    this.leftWbsGridRules = {
      ...layout.leftWbsRules,
    }
    this.rightWbsGridRules = {
      ...layout.rightWbsRules,
    }
    // 暫定
    this.leftWbsFields = layout.leftWbsRules?.fields || []
    this.rightWbsFields = layout.rightWbsRules?.fields || []
  }
  protected async loadLeftStaticData() {
    this.leftWbsData = this.leftWbsStaticData || []
  }
  firstLoad = true
  protected ngOnChanges(changes: SimpleChanges) {
    // マッピングのデータが更新されたら、再度初期化する
    if (changes['mappingData']) {
      this.mappingData = changes['mappingData'].currentValue
    }
    if (changes['leftWbsStaticData']) {
      const addedFrontEndIdData = this.isWbsService.addFrontEndIds(
        changes['leftWbsStaticData'].currentValue
      )
      // 配列じゃなければ、配列に変換する
      if (!Array.isArray(addedFrontEndIdData)) {
        this.leftWbsData = [addedFrontEndIdData]
      } else {
        this.leftWbsData = addedFrontEndIdData
      }
      this.reload()
    }
  }
  protected getMappingData(id: string | undefined) {
    if (id) {
      //id がある場合は、mappingのデータを取得して返す。
      // TODO: ここでAPIを叩く
      console.error('TODO: ここでAPIを叩く 未実装')
      return {}
    } else {
      //id がない場合は、layoutのデータを返す
      return this.mappingData
    }
  }

  protected async getWbsData(layout: wbsLayout, actions: actions = {}) {
    const leftWbs = layout.leftWbs
    const rightWbs = layout.rightWbs
    const leftWbsLoadAction = leftWbs?.loadAction
    const rightWbsLoadAction = rightWbs?.loadAction
    const leftUrlInfo = actions[leftWbsLoadAction || ''] as any
    const rightUrlInfo = actions[rightWbsLoadAction || ''] as any
    if (leftUrlInfo?.apiInfo) {
      const leftWbsRet = await this.isApiService.convertedWithAPISend(
        leftUrlInfo.apiInfo.url,
        '',
        leftUrlInfo.apiInfo.urlObject
      )
      console.log('getWbsData 左枠データ取得完了 leftWbsRet=' + leftWbsRet)
      const data = this.isApiService.checkApiReturnData(leftWbsRet)
      // this.leftWbsData = this.convertToArray(data)
      const arrayData = this.convertToArray(data)
      const childDataKey = this.leftWbsGridRules.childDataKey
      this.leftWbsData = this.addOrginalParentId(arrayData, childDataKey)
    } else if (leftUrlInfo?.event) {
      const event = leftUrlInfo.event as string
      this.isUtilityService.executeFunction(this, event)
    }

    if (rightUrlInfo?.apiInfo) {
      const rightWbsRet = await this.isApiService.convertedWithAPISend(
        rightUrlInfo.apiInfo.url,
        '',
        rightUrlInfo.apiInfo.urlObject
      )

      const data = this.isApiService.checkApiReturnData(rightWbsRet)

      this.rightWbsData = this.convertToArray(data)
    }
    else {
      this.rightWbsData = []
    }

    //設定ファイルのActionを分ける？とりあえず全部入れとく
    this.leftWbsActions = actions
  }

  // object なら 配列に変換する
  protected convertToArray(data: any) {
    if (Array.isArray(data)) {
      return data
    } else {
      return [data]
    }
  }
  protected addOrginalParentId(data: any, childDataKey: string) {
    data.forEach((element: { [x: string]: undefined; orginalParentId: any }) => {
      element.orginalParentId = element['parentId']
      if (element[childDataKey] !== undefined) {
        this.addOrginalParentId(element[childDataKey], childDataKey)
      }
    });
    return data
  }
  /**
   * Layoutの設定値からClassを返す
   * @returns
   */
  protected getAreaClass(): string {
    // 無かったらrow
    const direction = this.layout?.style?.direction || 'row'
    return `direction-${direction}`
  }
  /**
   * footerのactionを実行する
   * @param action
   */
  protected async footerAction(action: string | ((...args: any[]) => any)) {
    if (typeof action === 'string') {
      await this.sendAction(action)
    } else {
      action()
    }
  }

  //元に戻す
  protected footerCancel() {
    this.initialize()
  }

  //登録前確認（デフォルトダイアログ使用の場合は、actionsのdialogBeforesaveにセットしておく。）
  //★★アプリでダイアログを用意する場合はどうする？
  public showSaveDialog() {
    const actionFunc = this.leftWbsActions?.['dialogBeforesave'] as any
    this.dialogMessageCd = actionFunc.messageCd
    this.messageDialog?.open()
  }

  //キャンセル
  protected dialogCancel() {
    this.messageDialog?.close()
  }

  //保存
  protected async onSave() {
    this.messageDialog?.close()

    const leftWbs = this.leftWbs?.getGridData()
    const rightWbs = this.rightWbs?.getGridData()
    const data = {
      leftWbs,
      rightWbs,
    }
    console.log('onSaveを呼び出しました。', data)

    const actionFunc = this.leftWbsActions?.['save'] as any
    const apiInfo = structuredClone(actionFunc.apiInfo)

    const childDataKey = this.leftWbsGridRules.childDataKey
    //登録
    let newElement = this.getNewData(leftWbs, leftWbs, childDataKey)
    if (newElement.length > 0) {
      apiInfo[0].payload = newElement
      let retpost = await this.runApi(apiInfo[0])
      console.log('新規追加完了 retpost=' + retpost)
      if (!retpost) {
        console.log('システムエラーが発生しました')
        this.dialogMessageCd = 'apiFailed'
        this.messageDialog?.open()
        return
      }
    }
    //削除
    let delElement = this.getDelData(rightWbs, childDataKey)
    if (delElement.length > 0) {
      apiInfo[1].payload = delElement
      let retdel = await this.runApi(apiInfo[1])
      console.log('削除完了 retpost=' + retdel)
      if (!retdel) {
        this.dialogMessageCd = 'apiFailed'
        this.messageDialog?.open()
        return
      }
    }

    //親付け替え分
    let updateElement = this.getUpdateParentData(leftWbs, childDataKey)
    if (updateElement.length > 0) {
      //削除
      const delItem = updateElement.map(({ id: id, orginalParentId: parentId }) => ({ id, parentId }));
      apiInfo[1].payload = delItem
      let retdel = await this.runApi(apiInfo[1])
      console.log('親付け替え分削除完了 retdel=' + retdel)
      if (!retdel) {
        this.dialogMessageCd = 'apiFailed'
        this.messageDialog?.open()
        return
      }
      //登録
      const newItem = updateElement.map(({ id, parentId, name, ownerId, userSource }) => ({ id, parentId, name, ownerId, userSource }));
      apiInfo[0].payload = newItem
      let retpost = await this.runApi(apiInfo[0])
      console.log('親付け替え分追加完了 retpost=' + retpost)
      if (!retpost) {
        console.log('システムエラーが発生しました')
        this.dialogMessageCd = 'apiFailed'
        this.messageDialog?.open()
        return
      }
    }
    await this.initialize()
    this.dialogMessageCd = 'apiExceuted'
    this.messageDialog?.open()
  }

  protected async onSaveWbsItem() {
    this.messageDialog?.close()

    const leftWbs = this.leftWbs?.getGridData()
    console.log('onSaveWbsItemを呼び出しました。', leftWbs)

    const actionFunc = this.leftWbsActions?.['save'] as any
    const apiInfo = structuredClone(actionFunc.apiInfo)

    const childDataKey = this.leftWbsGridRules.childDataKey

    //wbs-item更新
    if (leftWbs.length > 0) {
      //wbs-itemの取得
      apiInfo[0].urlObject.queryParams.fileSystemId = this.mappingData.actions.GetWbs.apiInfo.urlObject.queryParams.wbsItemId
      let wbsItem = await this.GetApiAsync(apiInfo[0])
      let wbsIndex = wbsItem?.data?.nodeObjects?.find((x: any) => x.wbsType === 'wbs-index')
      if (!wbsItem) {
        console.log('システムエラーが発生しました')
        this.dialogMessageCd = 'apiFailed'
        this.messageDialog?.open()
        return
      }
      //wbs-itemの更新
      let wbsItemReqObjects = wbsItem?.data?.nodeObjects?.filter((x: any) => x.wbsType !== 'wbs-index')
      let wbsItemReq = wbsItem?.data
      //カタログ追加があれば追加
      if (this.selectedCatalogIds.length > 0) {
        for (let catalogId of this.selectedCatalogIds) {
          wbsItemReqObjects = wbsItemReqObjects.concat({
            wbsType: 'catalog',
            id: catalogId
          })
        }
      }
      wbsItemReq.nodeObjects = wbsItemReqObjects.concat(leftWbs)
      apiInfo[1].urlObject.queryParams.wbsItemId = wbsItemReq.id
      apiInfo[1].urlObject.queryParams.wbsIndexId = wbsIndex.id
      apiInfo[1].payload = wbsItemReq
      let retpost = await this.runApi(apiInfo[1])
      console.log('更新完了 retpost=' + retpost)
      if (!retpost) {
        console.log('システムエラーが発生しました')
        this.dialogMessageCd = 'apiFailed'
        this.messageDialog?.open()
        return
      }
    }

    await this.initialize()
    this.dialogMessageCd = 'apiExceuted'
    this.messageDialog?.open()
  }

  private getNewData(newTree: any[], topWbs: any[], childDataKey: string) {
    let newData: any[] = []
    newTree.forEach((element: any) => {
      if (element.frontEndId !== undefined) {
        //★★暫定 ownerIdはメインエリアのownId、userSourceはメインエリアのuserSourceをセット。
        //         ownerIdは本当は画面で選択できるようにしたい。
        let parentData = this.getTopParentInfo(topWbs, element.parentId, childDataKey)
        let item = {
          id: null,
          parentId: element.parentId,
          name: element.name,
          ownerId: parentData?.ownId,
          userSource: parentData?.userSource
        }
        newData.push(item)
      }
      else {
        if (element[childDataKey] !== undefined) {
          if (element[childDataKey].length > 0) {
            let child = this.getNewData(element[childDataKey], topWbs, childDataKey)
            if (child !== null) {
              newData = newData.concat(child)
            }
          }
        }
      }
    });
    return newData
  }
  private getTopParentInfo(data: any[], targetId: string, childDataKey: string): any | null {
    let topParent = null
    for (let i = 0; i < data.length; i++) {
      let tmpParent = data[i]
      if (data[i].id === targetId) {
        topParent = tmpParent
        break
      }
      if (data[i][childDataKey].length > 0) {
        let searchRet = this.searchId(data[i][childDataKey], targetId, childDataKey)
        if (searchRet) {
          topParent = tmpParent
          break
        }
      }
    }
    return topParent
  }
  private searchId(data: any[], targetId: string, childDataKey: string): boolean {
    let ret = false
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === targetId) {
        ret = true
        break
      }
      if (data[i][childDataKey] !== undefined) {
        if (data[i][childDataKey].length > 0) {
          let searchRet = this.searchId(data[i][childDataKey], targetId, childDataKey)
          if (searchRet) {
            ret = true
            break
          }
        }
      }
    }
    return ret
  }
  private getDelData(delTree: any[], childDataKey: string) {
    let delData: any[] = []
    delTree.forEach((element: any) => {
      if (element.frontEndId === undefined) {
        let item = {
          wbsType: element.wbsType,
          id: element.id,
          parentId: element.parentId
        }
        delData.push(item)
      }
      else {
        if (element[childDataKey] !== undefined) {
          if (element[childDataKey].length > 0) {
            let child = this.getDelData(element[childDataKey], childDataKey)
            if (child !== null) {
              delData = delData.concat(child)
            }
          }
        }
      }
    });
    return delData
  }
  private getUpdateParentData(updateTree: any[], childDataKey: string) {
    let targetData: any[] = []
    updateTree.forEach((element: any) => {
      if (element.parentId !== undefined && element.orginalParentId !== undefined) {
        if (element.parentId !== element.orginalParentId) {
          targetData.push(element)
        }
      }
      if (element[childDataKey] !== undefined) {
        if (element[childDataKey].length > 0) {
          let child = this.getUpdateParentData(element[childDataKey], childDataKey)
          if (child !== null) {
            targetData = targetData.concat(child)
          }
        }
      }
    });
    return targetData
  }
  private async GetApiAsync(api: any) {
    const apiMethod = api?.apiMethod || 'get'
    const url = api?.url
    const urlObject = api?.urlObject
    let ret = await this.isApiService.convertedWithAPISend(url, "", urlObject, apiMethod)
    if (!ret) {
      this.dialogMessageCd = 'apiFailed'
      this.messageDialog?.open()
      return null
    }
    return ret
  }

  private async runApi(api: any) {
    const apiMethod = api?.apiMethod || 'get'
    const path = api?.url
    const urlObject = api?.urlObject
    const payload = api?.payload
    const httpheader = api?.httpheader || new HttpHeaders({})
    const url = await this.isApiService.apiUrlGenerator(path, "", urlObject)
    let ret = await this.isApiService.sendApiRequest(url, payload, httpheader, apiMethod)
    if (!ret) {
      this.dialogMessageCd = 'apiFailed'
      this.messageDialog?.open()
      return false
    }
    return true
  }
  protected get items(): { [key: string]: any } {
    return this.mappingData || {}
  }

  /**
   * actionを実行する
   * @param action
   * @returns
   * @description actionの設定値に応じて、APIを実行する
   **/
  protected async sendAction(action: string, targetItem?: any) {
    let items = this.items
    //HUBの場合パラメータあり
    if (targetItem) {
      items = targetItem
    }
    const actions = items['actions'] || {}
    const actionFunc = actions[action]
    const event = actionFunc['event'] as string
    // eventが無い場合、APIを実行する
    if (!event && actionFunc) {
      const apiInfo = actionFunc['apiInfo']
      const apiMethod = actionFunc['apiMethod'] || 'get'
      const url = apiInfo['url']
      const urlObject = apiInfo['urlObject']
      return await this.isApiService.convertedWithAPISend(url, urlObject, apiMethod)
    }
    // eventがある場合、eventを実行する
    else {
      this.isUtilityService.executeFunction(this, event)
    }
  }

  protected arrowAction(action: string, target: any, source: any) {
    console.log('arrowActionを呼び出しました。', action)
    switch (action) {
      case 'map':
        // this.map()
        console.log('arrowAction mapを呼び出しました。')
        break
      case 'unmap':
        // this.unmaping()
        console.log('arrowAction unmapを呼び出しました。')
        break
      case 'forward':
        this.forward(target, source)
        // console.log('arrowAction forwardを呼び出しました。')
        break
      case 'backward':
        this.backward(target, source)
        // console.log('arrowAction backwardを呼び出しました。')
        break
      case 'copy':
        // this.copy()
        console.log('arrowAction copyを呼び出しました。')
        break
      default:
        // 未定義
        console.error('arrowAction 未定義のactionを呼び出しました。')
        break
    }
  }
  rightArrowClick() {
    const rightWbsSelectedRows = this.rightWbs?.selectedRows()
    const leftWbsSelectedRows = this.leftWbs?.selectedRows()
    if (leftWbsSelectedRows.length === 0) return
    const target = leftWbsSelectedRows?.[0]
    // if (!target) {
    //   return console.log('紐づけ元が選択されていません。')
    // }
    const wbsType = target.wbsType
    const arrowActionCd = this.getArrowActionCd('rightArrow', wbsType)
    this.arrowAction(arrowActionCd, target, leftWbsSelectedRows)
  }
  leftArrowClick() {
    const rightWbsSelectedRows = this.rightWbs?.selectedRows()
    if (rightWbsSelectedRows.length === 0) return
    const leftWbsSelectedRows = this.leftWbs?.selectedRows()
    const target = leftWbsSelectedRows?.[0]
    // if (!target) {
    //   return console.log('紐づけ先が選択されていません。')
    // }
    // const wbsType = target.wbsType
    const wbsType = rightWbsSelectedRows[0].wbsType
    const arrowActionCd = this.getArrowActionCd('leftArrow', wbsType)
    this.arrowAction(arrowActionCd, target, rightWbsSelectedRows)
  }
  getArrowActionCd(arrowCd: string = 'rightArrow', targetWbsType: string) {
    return this.wbsRules?.[targetWbsType]?.[arrowCd]?.action
  }

  leftButtonAction(event: any) {
    console.log('leftButtonAction', event)
  }
  rightButtonAction(event: any) {
    console.log('rightButtonAction', event)
  }
  leftWbsLeftFooterAction(event: any) {
    if (this.leftWbsActions['leftWbsLeftFooterAction']) {
      this.leftWbsActions['leftWbsLeftFooterAction'](event)
    } else {
      console.log('leftWbsLeftFooterAction', '未実装')
    }
  }
  leftWbsRightFooterAction(event: any) {
    if (this.leftWbsActions['leftWbsRightFooterAction']) {
      const leftWbsData = this.leftWbs?.selectedRows()
      const rightWbsData = this.rightWbs?.selectedRows()
      this.leftWbsActions['leftWbsRightFooterAction'](event, leftWbsData, rightWbsData)
    } else {
      console.log('leftWbsRightFooterAction', '未実装')
    }
  }
  _clickSubMenu(value: any) {
    this.clickSubMenu.emit(value)
  }

  //紐づけ(左) <= 未紐づけ(右)
  forward(target: any, source: any) {
    let rightData = structuredClone(this.rightWbsData)
    let leftData = structuredClone(this.leftWbsData)
    let newParentId = !target ? leftData[0].id : target.id  //紐づけ先指定なしの場合は、一番上の階層につける
    //右側から削除
    rightData = this.isWbsService.removeNodesById(
      this.rightWbsData,
      source.map((x: { id: string }) => x.id),
      this.leftWbsGridRules.childDataKey,
      'id')

    //左側に追加
    source.forEach((element: any) => {
      element.parentId = newParentId
      let tmpLeft = this.isWbsService.addChild(
        this.leftWbsData,
        newParentId,
        element,
        this.leftWbsGridRules.childDataKey
      )
      leftData = structuredClone(tmpLeft)
    });
    this.rightWbsData = rightData
    this.leftWbsData = leftData
  }
  //紐づけ(左) => 未紐づけ(右)
  backward(target: any, source: any) {
    let rightData = structuredClone(this.rightWbsData)
    let leftData = structuredClone(this.leftWbsData)

    //選択ノードが移動可能かチェック
    if (!this.checkCanMoveNode(leftData, source)) {
      //メッセージの出し方どうする？
      this.dialogMessageCd = 'unMovable'
      this.messageDialog?.open()
      return
    }
    //左側から削除
    leftData = this.isWbsService.removeNodesById(
      leftData,
      source.map((x: { id: string }) => x.id),
      this.leftWbsGridRules.childDataKey,
      'id')

    //右側に追加
    rightData = rightData.concat(source)
    this.rightWbsData = rightData
    this.leftWbsData = leftData
  }

  checkCanMoveNode(leftData: any, source: any) {
    let ret = true
    if (!this.leftWbsGridRules?.canMoveTopNode) {
      //トップ階層は移動不可の場合は、トップ階層が含まれているかどうかチェック
      const moveIds = source.map((item: { id: string }) => item.id);
      const topIds = leftData.map((item: { id: string }) => item.id);
      for (const value of topIds) {
        if (moveIds.includes(value)) {
          ret = false;
          break;
        }
      }
    }
    return ret
  }

  //region from Hubの設定
  callHub() {
    this.initHub()
    this.hub?.open()
  }
  callCatalogHub() {
    this.initCatalogHub()
    this.catalogHub?.open()
  }
  @ViewChild('hub', { static: true }) public hub: IsIgxHubComponent | undefined
  @ViewChild('catalogHub', { static: true }) public catalogHub: IsIgxHubComponent | undefined
  @ViewChild('selectedCatalogHub', { static: true }) public selectedCatalogHub: IsIgxHubComponent | undefined
  @Input() mappingDataHub: any | undefined
  @Input() mappingDataCatalogHub: any | undefined
  @ViewChild('addDialog', { static: true }) public addDialog: IsIgxDialogComponent | undefined
  @Output() clickPlusElement = new EventEmitter<any>()
  public selectedElementId: string = ''
  public selectedElements: string[] = []
  public addElement = { id: '', name: '' }
  public defaultHubData: any | undefined
  public hubWorkData: any | undefined
  public catalogHubWorkData: any | undefined
  private orginalHubData: any
  private orginalCatalogHubData: any
  public selectedCatalogIds: string[] = []
  public selectedCatalogHubData = {
    "items ": {
      "type ": "list",
      "dialog ": {
        "width": "100%",
        "maxHeight": "100%"
      }
    },
    "layout": {
      "leftWbs": {
        "loadAction": "getWbsItem",
        "header": {
          "label": "カタログを選択"
        },
        "footer": {
          "rightButton": {
            "key": "select",
            "name": "right-button",
            "label": "選択",
            "color": "primary",
            "disabled": false
          },
          "leftButton": {
            "key": "cancel",
            "name": "left-button",
            "label": "キャンセル",
            "color": "cancel",
            "disabled": false
          }
        }
      },
      "leftWbsRules": {
        "childDataKey": "nodeObjects",
        "hideHeader": false,
        "height": "300px",
        "width": "400px",
        "rowSelection": "multiple",
        "noSelectionWbsTypes": [
          "catalog"
        ],
        "fields": [
          {
            "name": "name",
            "label": "名称",
            "width": "calc(100% - 60px)",
            "dataType": "string"
          }
        ]
      }
    },
    "rules": {
      "template": {}
    },
    "actions": {
      "getWbsItem": {
        "event": "",
        "apiInfo": {
          "url": "//backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexwbs",
          "urlObject": {
            "queryParams": {
              "fileSystemId": ""
            }
          }
        }
      }
    }
  }
  public hubButtonSetting: ButtonSetting =
    {
      key: "hub",
      id: "0",
      name: "hub-button",
      label: "HUB（追加・選択１）あｄｆ",
      color: "primary",
      disable: false,
      hidden: false,
      action: ''
    }
  public catalogHubButtonSetting: ButtonSetting =
    {
      key: "hub",
      id: "1",
      name: "hub-button",
      label: "catalogHUB",
      color: "primary",
      disable: false,
      hidden: false,
      action: ''
    }
  hubActions = {
    cellClick: (event: any) => {
      //IDだけでいいのか？
      if (this.mappingDataHub.layout.leftWbsRules?.rowSelection === 'single') {
        if (event.added.length) {
          this.selectedElementId = event.added[0].id
        } else {
          this.selectedElementId = ''
        }
      }
      if (this.mappingDataHub.layout.leftWbsRules?.rowSelection === 'multiple') {
        if (event.newSelection.length) {
          this.selectedElements = event.newSelection
        } else {
          this.selectedElements = []
        }
      }
      // console.log("mapping cellClick")
    },
    onClickPlus: (event: any) => {
      //デフォルトの要素追加ダイアログ
      if (this.mappingDataHub.layout.leftWbsRules?.canUseAddDialog ||
        this.mappingDataHub.layout.leftWbsRules?.canUseAddDialog === undefined) {
        this.addElement.id = event.id
        this.addElement.name = ''
        this.addDialog?.open()
      }
      //デフォルトダイアログ以外(他サービスとの連携)
      else {
        this.clickPlusElement.emit()
      }
    },
    leftWbsLeftFooterAction: (event: any) => {
      this.hub?.close()
    },
    leftWbsRightFooterAction: (event: any, leftWbs?: any, rightwbs?: any) => {
      if (leftWbs.length === 0) {
        console.log('mappping-hub 要素を選択してください。')
        return
      } else {
        // HUBから紐づけへ受け渡し
        this.addElementToMapping(leftWbs)
      }
      this.hub?.close()
    },
  }
  catalogHubActions = {
    cellClick: (event: any) => {
      //IDだけでいいのか？
      if (this.mappingDataCatalogHub.layout.leftWbsRules?.rowSelection === 'single') {
        if (event.added.length) {
          this.selectedElementId = event.added[0].id
        } else {
          this.selectedElementId = ''
        }
      }
      if (this.mappingDataCatalogHub.layout.leftWbsRules?.rowSelection === 'multiple') {
        if (event.newSelection.length) {
          this.selectedElements = event.newSelection
        } else {
          this.selectedElements = []
        }
      }
      // console.log("mapping cellClick")
    },
    onClickPlus: (event: any) => {
      //デフォルトの要素追加ダイアログ
      if (this.mappingDataCatalogHub.layout.leftWbsRules?.canUseAddDialog ||
        this.mappingDataCatalogHub.layout.leftWbsRules?.canUseAddDialog === undefined) {
        this.addElement.id = event.id
        this.addElement.name = ''
        this.addDialog?.open()
      }
      //デフォルトダイアログ以外(他サービスとの連携)
      else {
        this.clickPlusElement.emit()
      }
    },
    leftWbsLeftFooterAction: (event: any) => {
      this.catalogHub?.close()
    },
    leftWbsRightFooterAction: (event: any, leftWbs?: any, rightwbs?: any) => {
      if (leftWbs.length === 0) {
        console.log('mappping-hub 要素を選択してください。')
        return
      } else {
        // HUBから紐づけへ受け渡し
        this.selectedCatalogHubData.actions.getWbsItem.apiInfo.urlObject.queryParams.fileSystemId = this.selectedElementId
        this.selectedCatalogHub?.open()
      }
      this.catalogHub?.close()
    },
  }
  selectedCatalogHubActions = {
    cellClick: (event: any) => {
      //IDだけでいいのか？
      if (this.selectedCatalogHubData.layout.leftWbsRules?.rowSelection === 'single') {
        if (event.added.length) {
          this.selectedElementId = event.added[0].id
        } else {
          this.selectedElementId = ''
        }
      }
      if (this.selectedCatalogHubData.layout.leftWbsRules?.rowSelection === 'multiple') {
        if (event.newSelection.length) {
          this.selectedElements = event.newSelection
        } else {
          this.selectedElements = []
        }
      }
      // console.log("mapping cellClick")
    },
    leftWbsLeftFooterAction: (event: any) => {
      this.selectedCatalogHub?.close()
    },
    leftWbsRightFooterAction: (event: any, leftWbs?: any, rightwbs?: any) => {
      if (leftWbs.length === 0) {
        console.log('mappping-hub 要素を選択してください。')
        return
      } else {
        // HUBから紐づけへ受け渡し
        this.addElementToMapping(leftWbs)
      }

      this.selectedCatalogIds.push(this.selectedCatalogHubData.actions.getWbsItem.apiInfo.urlObject.queryParams.fileSystemId)
      this.selectedCatalogHub?.close()
    },
  }

  initHub() {
    if (this.mappingDataHub.actions['loadHub']) {
      this.sendAction('loadHub', this.mappingDataHub)  //設定ファイルのactions.loadHub.eventを呼び出して実行する
    } else {
      console.log('loadHub', '未実装')
    }
  }
  initCatalogHub() {
    if (this.mappingDataCatalogHub.actions['loadHub']) {
      this.sendAction('loadCatalogHub', this.mappingDataCatalogHub)  //設定ファイルのactions.loadHub.eventを呼び出して実行する
    } else {
      console.log('loadCatalogHub', '未実装')
    }
  }
  getOrginalHubData(value: any) {
    //プラスなしのオリジナルデータ
    this.orginalHubData = value
  }
  _clickAdd(value: any) {
    //デフォルトの要素追加ダイアログからHUBへ受け渡し
    this.addElementToHub(value)
  }
  addElementToHub(value: any) {
    this.orginalHubData.push({
      id: value.id,
      name: value.name,
      wbsType: this.mappingDataHub.layout.leftWbsRules.addElementType,
      nodeObjects: [],
    })
    this.hubWorkData = structuredClone(this.orginalHubData)
  }
  addElementToMapping(value: any) {
    this.rightWbsData = this.rightWbsData.concat(value)
  }
  //region end Hubの設定
}
