import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { WbsService } from 'src/app/services/wbs.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { FrontEndParams } from '@interserve/igx-angular';

@Component({
  selector: 'app-create-cassette',
  templateUrl: './create-cassette.component.html',
  styleUrls: ['./create-cassette.component.scss']
})
export class CreateCassetteComponent implements OnInit {
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent

  public appCd=environment.appCd
  public frontendCd="cassetteMappingV2"
  public frontendCdHub="cassetteMappingHub"
  public title = 'ドキュメント管理／カセット作成・移動・削除'

  public apiLoading=false
  public loading=false
  
  public customerId=''
  public organizaionId=''
  public areaId=''

  public mappingParams!: FrontEndParams;
  public hubParams!: FrontEndParams;

  constructor(
    private wbsService:WbsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.customerId=this.authService.Account?.customerId??''

    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.areaId=params['id']
      }
    });
    this.initialize() 
  }
  async initialize(wbsId?:string){
    this.loading=true
    
      try {
          //フロントエンド設定を紐づけに渡す
          let param={customerId:this.customerId,areaId:this.areaId}
          this.mappingParams = this.wbsService.setFrontendParams(this.frontendCd, param)
          this.hubParams = this.wbsService.setFrontendParams(this.frontendCdHub, param)
          this.loading=false

      } catch (error) {
          this.snackbar.error('画面の初期化が失敗しました')
          this.loading=false
      }
  }  

  gotoArea(){
    this.router.navigate([ `/select-area`]);
  }
  gotoCassetteList(){
    this.router.navigate([ `/wbs-viewer/${this.areaId}/1`]);    //0:閲覧のみ、1:追加可  
  }
  clickSubMenu(value: any){
    console.log('clickSubMenu:' + value)
  }
  clickPlusElement(value: any){
    //デフォルトの要素追加ダイアログを使わない場合はここで独自ダイアログを呼び出す（My-ideaの顧客登録などと連携）
    console.log('clickPlusElement:'+value)
  }
}
