import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { IgxTreeGridComponent, IPinningConfig, RowPinningPosition, SortingDirection } from '@infragistics/igniteui-angular'
import {
  ColumnRule, HeaderOptions, SimulationByCassetteGridBody as GridBody,
  GridHeader, SimulationByCassetteHeaderData as HeaderData,
} from 'src/app/interfaces'
import { Subscription } from 'rxjs'
// import { SideNavService } from 'src/app/services/side-nav.service'

@Component({
  selector: 'app-tree-grid',
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.scss']
})
export class TreeGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('treeGrid', { read: IgxTreeGridComponent, static: true }) public treeGrid!: IgxTreeGridComponent
  @ViewChild('GridParent') private gridParent!: ElementRef
  @Input() headerData: any[] = []
  @Input() bodyData: any[] = []
  @Input() columnRule: ColumnRule[] = []
  @Input() amountUnit: number = 1000 // デフォルト千円
  /**
   * 2: シミュレーション＞品番別
   * 3: シミュレーション＞数量別
   * 4: 先付会議
   * 5: パターン会議
   */
  @Input() planningType: number = 5 // 計画種別 色などスタイルにて利用想定

  private totalRow = (row: any, columnKey: any): boolean => {
    return row.type === 'sum'
  }
  private warningValColor = (row: any, columnKey: any): boolean => {
    let ret = false
    const column = this.columnRule.find(o => o.type === columnKey)
    if (column && column.headerOptions.dataType === 'number') {
      const val = row[columnKey]
      if (!isNaN(Number(val)) && Number(val) < 0) {
        ret = true
      }
    }

    return ret
  }
  private firstCell = (row: any, columnKey: any): boolean => {
    return columnKey === 'name'
  }

  cellClasses = {
    totalRow: this.totalRow,
    warningValColor: this.warningValColor,
    firstCell: this.firstCell,
  }

  pinningConfig: IPinningConfig = { rows: RowPinningPosition.Bottom }
  gridHeaderData: any[] = []

  private totalColumnWidth!: number;
  private subscription: Subscription | undefined
  
  constructor(
    private cd: ChangeDetectorRef, 
    // private sideNavService: SideNavService
    ) { }

  ngOnInit(): void {
    this.gridHeaderData = this.mappingHeaderData(this.headerData, this.columnRule)

    this.convertUnit(this.bodyData, false, this.amountUnit, this.columnRule)

    this.removeDecimalPoint(this.bodyData)

    this.setFixedRow(this.bodyData)

    this.treeGrid.sortingExpressions = [{ fieldName: 'displayOrder', dir: SortingDirection.Asc }]

    // IgxGridの幅を取得する。
    this.totalColumnWidth = this.gridHeaderData.reduce((sum: any, element: any) => {
      let str = element.width
      let ret = str.replace("px", "")
      let num = Number(ret);
      return sum = sum + num;
    }, 0);
    
    // this.subscription = this.sideNavService.navClickSource$.subscribe((data) => {
    //   // サイドメニューが変更されたときIgxGridの幅を更新する。
    //   setTimeout(() => {
    //     const parentDiv = this.gridParent.nativeElement.getBoundingClientRect().width
    //       let numparentDiv = parentDiv
    //       if(data ? numparentDiv = numparentDiv + 260 : numparentDiv = numparentDiv - 260)
    //       this.viewCheck(numparentDiv)
    //   });
    // })
  }

  ngAfterViewInit(): void {
    this.gridParent.nativeElement.classList.add('hide-grid')
    const freeSpace = this.getfreeSpace()
    this.gridParent.nativeElement.style.height = freeSpace + 'px'
    this.collapseAllRow()
    setTimeout(() => {
      try {
        const dataCount = this.getCurrentViewDataCount()
        const grid = this.getGridHeightInfo()
        const requiredHeight = grid.headerHeight + grid.rowHeight * dataCount + + grid.pinnedHeight + grid.scrollHeight

        if (freeSpace > requiredHeight) {
          // 高さ十分なので、Grid高さをnullにして >> フリーにしておく
          this.resetGridHeightSetting(true)
        } else {
          // 高さ足りないので、Grid高さ固定に　>> スクロールだす
          this.resetGridHeightSetting(false)
        }

        this.oldDataCount = this.getCurrentViewDataCount()
      } catch (error) {
        // なにかあってもカバーを外す
        this.gridParent.nativeElement.classList.remove('hide-grid')
      }
      this.gridParent.nativeElement.classList.remove('hide-grid')
    }, 0)

    // 表示ボタンが押下されたときIgxGridの幅を更新する。
    setTimeout(() => {
      this.viewCheck(this.gridParent.nativeElement.getBoundingClientRect().width)
    });
  }

  ngAfterContentChecked(): void {
    try {
      const dataCount = this.getCurrentViewDataCount()
      if (this.oldDataCount !== dataCount) {
        const freeSpace = this.getfreeSpace()
        const grid = this.getGridHeightInfo()
        const requiredHeight = grid.headerHeight + grid.rowHeight * dataCount + + grid.pinnedHeight + grid.scrollHeight

        if (freeSpace > requiredHeight) {
          // 高さ十分なので、Grid高さをnullにして >> フリーにしておく
          this.resetGridHeightSetting(true)
        } else {
          // 高さ足りないので、Grid高さ固定に　>> スクロールだす
          this.resetGridHeightSetting(false)
        }

        this.oldDataCount = dataCount
      }
    } catch (error) {
      // 
    }
  }

  private oldDataCount = 0
  private resizeTimeout: any
  onResize(event: any): void {
    if (!this.resizeTimeout) {
      const that = this
      this.resizeTimeout = setTimeout(function () {
        that.resizeTimeout = null
        const freeSpace = that.getfreeSpace()
        that.gridParent.nativeElement.style.height = freeSpace + 'px'
        const dataCount = that.getCurrentViewDataCount()
        const grid = that.getGridHeightInfo()
        const requiredHeight = grid.headerHeight + grid.rowHeight * dataCount + + grid.pinnedHeight + grid.scrollHeight
        if (that.treeGrid.height === null) {
          if (requiredHeight > freeSpace) {
            // 高さ足りないので、Grid高さ固定に　>> スクロールだす
            that.resetGridHeightSetting(false)
          }
        } else {
          if (freeSpace > requiredHeight) {
            // 高さ十分なので、Grid高さをnullにして >> フリーにしておく
            that.resetGridHeightSetting(true)
          }
        }
      }, 200)
    }
    // IgxGridの幅を更新する。
    const newWidth = this.updateGridWidth(this.gridParent.nativeElement.getBoundingClientRect().width);
    if (this.treeGrid.width !== newWidth) {
      this.treeGrid.width = newWidth;
      this.treeGrid.cdr.detectChanges();
    }
  }

  /**
   * Headerスタイル設定
   * @param obj 
   * @returns 
   */
  getHeaderClass(obj: any): string {
    const planningType = this.planningType
    let ret = ''
    switch (planningType) {

      case 2: // * 2: シミュレーション＞品番別

        break
      case 3: // * 3: シミュレーション＞数量別

        break
      case 4: // * 4: 先付会議

        break
      case 5: // * 5: パターン会議

        break

      default:
        break
    }
    return ret
  }
  /**
   * Headerスタイル設定
   * @param obj 
   * @returns 
   */
  getHeaderGroupClass(obj: any): string {
    const planningType = this.planningType
    switch (planningType) {

      case 2: // * 2: シミュレーション＞品番別

        break
      case 3: // * 3: シミュレーション＞数量別

        break
      case 4: // * 4: 先付会議
      case 5: // * 5: パターン会議
        const items = ['diffPlan', 'total-diffPlan',]
        if (items.includes(obj.field)) {
          return 'diff-group-row'
        }
        break
      default:
        break
    }
    return ''
  }

  private mappingHeaderData(originalHeaderData: HeaderData[], columnRule: ColumnRule[]): any[] {
    let headerData: any[] = []
    let parentId = 1
    if (originalHeaderData && originalHeaderData.length) {
      originalHeaderData.forEach(obj => {
        const headerOptions = this.getColumnOptions(obj, columnRule)
        let item = this.createHeaderItem(parentId, obj, headerOptions)
        headerData.push(item)
        this.getChildItem(headerData, obj, parentId, columnRule)
        parentId += 1
      })

      if (headerData.length) {
        // 先頭列固定
        headerData[0].pinned = true
      }
    }
    return headerData
  }

  private getChildItem(headerData: any[], obj: any, parentId: number, columnRule: ColumnRule[]): any {
    if (obj.children && obj.children.length) {
      let id = parentId * 100
      obj.children.forEach((item: HeaderData) => {
        id += 1
        const headerOptions = this.getColumnOptions(item, columnRule)
        let secondHeaderItem = this.createHeaderItem(id, item, headerOptions, parentId)
        headerData.push(secondHeaderItem)
        this.getChildItem(headerData, item, id, columnRule)
      })
    }
  }

  private getColumnOptions(column: HeaderData, columnRule: ColumnRule[]): HeaderOptions | null {
    if (!column || !columnRule || !columnRule.length) {
      return null
    }
    const obj = columnRule.find(item => item.type === column.field)
    return obj ? obj.headerOptions : null
  }

  private createHeaderItem(id: number, obj: any, options: HeaderOptions | null, parentId: number | undefined = undefined): GridHeader {
    return {
      id: id,
      parentId: parentId !== undefined ? parentId : null,
      field: obj.field,
      header: obj.header,
      editable: options && options.editable ? options.editable : false,
      dataType: options && options.dataType ? options.dataType : 'string',
      width: options && options.width ? options.width : '',
      resizable: options && options.resizable ? options.resizable : true,
      hidden: options && options.hidden ? options.hidden : false,
      columnGroup: obj.children ? true : false,
      pinned: false
    }
  }

  /**
   * 単位変換
   *   表示/編集 >> item.vla　÷ this.amountUnit
   *   登録 >> item.val　× this.amountUnit
   *   切り捨て
   *   在日の小数点2桁までの処理もこちらで行う
   * @param bodyData
   * @param fullAmount true: 全桁 false: 指定単位に変換
   */
  private convertUnit(bodyData: GridBody[], fullAmount: boolean, amountUnit: number, columnRule: ColumnRule[]): void {
    const number = 'number'

    if (bodyData && bodyData.length) {
      bodyData.forEach(row => {
        Object.entries(row).forEach(field => {
          const fieldName = field[0]
          this.dailyStockDecimalPoint(fieldName, row)
          const ret = columnRule.find(obj => field[0] === obj.type && obj.headerOptions.dataType === number && obj.headerOptions.amountUnit)
          if (ret) {
            const originalVal = row[fieldName]
            let val = 0
            if (originalVal !== null && !isNaN(Number(originalVal)) && typeof (originalVal) === number) {
              if (fullAmount) {
                //　登録 >> item.val　× this.amountUnit
                // 小数点以下切り捨て
                val = Math.trunc(Number(originalVal)) * amountUnit
                row[fieldName] = val
              } else {
                //　表示/編集 >> item.val　÷ this.amountUnit
                // 小数点以下切り捨て
                val = Number(originalVal) / amountUnit
                row[fieldName] = Math.trunc(val)
              }
            }
          }
        })
      })
    }
  }

  /**
   * 在日列の値を小数点1桁まで四捨五入で切り落とす
   * @param fieldName 
   * @param row 
   */
  private dailyStockDecimalPoint(fieldName: any, row: GridBody): void {
    const number = 'number'
    const targetKey = 'dailyStock'
    if (fieldName.startsWith(targetKey)) {
      const originalVal = row[fieldName]
      let val = 0
      if (originalVal !== null && !isNaN(Number(originalVal)) && typeof (originalVal) === number) {
        val = Number(Number(originalVal).toFixed(1))
        row[fieldName] = val
      }
    }
  }

  /**
   * 合計行固定
   * @param bodyData 
   */
  private setFixedRow(bodyData: GridBody[]): void {
    const sumRowkey = 'sum'
    const sumRow = bodyData.find(o => o.type === sumRowkey)
    if (sumRow) {
      sumRow.displayOrder = 999999
      this.treeGrid.pinRow(sumRow.id)
    }
  }

  /**
   * すべての行を折りたたんでおく
   */
  private collapseAllRow() {
    const sumRowkey = 'sum'
    const target = this.treeGrid.data?.filter(o => o.type !== sumRowkey)
    if (target && target.length) {
      target.forEach(o => {
        this.treeGrid.collapseRow(o.id)
      })
    }
  }

  private getCurrentViewDataCount(): number {
    try {
      const target = ['productCd','mdCassette', 'salesFloor', 'priceLine']
      const objs = this.treeGrid.dataView.filter(o => o.data && target.includes(o.data.type))
      return objs.length
    } catch (error) {
      // 落ちないこと保証
    }
    return 0
  }

  /**
   * 
   * @param heightNull true: height = null, false: height = 100%
   */
  private resetGridHeightSetting(heightNull: boolean): void {
    if (heightNull) {
      // 高さ十分なので、Grid高さをnullにして >> フリーにしておく
      // 実験 No.1
      // this.treeGrid.height = null
      // this.treeGrid.nativeElement.classList.remove('has-scroll')

      // 実験 No.2
      setTimeout(() => {
        this.treeGrid.height = null
        this.treeGrid.nativeElement.classList.remove('has-scroll')
      }, 0)

      // 実験 No.3
      // setTimeout(() => {
      //   this.treeGrid.height = null
      // }, 0)
      // setTimeout(() => {
      //   this.treeGrid.nativeElement.classList.remove('has-scroll')
      // }, 0)
    } else {
      // 高さ足りないので、Grid高さ固定に　>> スクロールだす

      // 実験 No.1
      // this.treeGrid.height = `100%`
      // this.treeGrid.nativeElement.classList.add('has-scroll')

      // 実験 No.2
      setTimeout(() => {
        this.treeGrid.height = `100%`
        this.treeGrid.nativeElement.classList.add('has-scroll')
      }, 0)

      // 実験 No.3
      // setTimeout(() => {
      //   this.treeGrid.height = `100%`
      // }, 0)
      // setTimeout(() => {
      //   this.treeGrid.nativeElement.classList.add('has-scroll')
      // }, 0)
    }
  }

  private getfreeSpace(): number {
    const winHeight = window.innerHeight
    // MarginやHeaderの高さ調整したい場合、この数字調整
    const offset = 300
    const freeSpace = winHeight - offset
    return freeSpace < 0 ? 300 : freeSpace
  }

  /**
   * 
   * @returns 
   */
  private getGridHeightInfo(): any {
    try {
      const gridElem = this.treeGrid.nativeElement

      // this.treeGrid.renderedRowHeight
      // Header
      const gridHeaderElem = gridElem.querySelector('.igx-grid-thead') as HTMLElement
      let headerHeight = 55
      if (gridHeaderElem) {
        headerHeight = gridHeaderElem.offsetHeight
      }

      // Body
      const gridBodyElem = gridElem.querySelector('.igx-grid__tbody-content .igx-display-container') as HTMLElement
      let bodyHeight = this.treeGrid.rowHeight
      if (gridBodyElem) {
        bodyHeight = gridBodyElem.offsetHeight
      }


      // Row
      const rowObj = gridBodyElem.querySelector('igx-tree-grid-row') as HTMLElement
      let rowHeight = this.treeGrid.rowHeight
      if (rowObj && rowObj.offsetHeight > 0) {
        rowHeight = rowObj.offsetHeight
      }

      // 固定行
      const gridPinnedElem = gridElem.querySelector('.igx-grid__tr--pinned') as HTMLElement
      let pinnedHeight = this.treeGrid.rowHeight
      if (gridPinnedElem) {
        pinnedHeight = gridPinnedElem.offsetHeight
      }

      // スクロール
      const scrollElem = gridElem.querySelector('.igx-grid__scroll') as HTMLElement
      let scrollHeight = 17
      if (scrollElem) {
        scrollHeight = scrollElem.offsetHeight
      }

      return {
        headerHeight,
        bodyHeight,
        rowHeight,
        pinnedHeight,
        scrollHeight,
      }
    } catch (error) {
      // 
    }
    return null
  }

  /**
   * シミュレーション＞数量別　限定
   * 仕入数/在庫数に対し四捨五入（小数点なし）
   * @param bodyData 
   */
  private removeDecimalPoint(bodyData: GridBody[]): void {
    // * 3: シミュレーション＞数量別
    if (this.planningType === 3) {
      // 四捨五入（小数点なし）対象列
      const target = [
        'purchaseOne-', 
        'purchaseTotal-',
        'stocksOne-',
        'stocksTotal-'
      ]

      // let i = 1
      bodyData.forEach(row => {
        // console.log(`Row: ${i} ${row.id}/${row.name}`)
        // i += 1
        Object.entries(row).forEach(field => {
          const fieldName = field[0]
          const obj = target.find(v => fieldName.startsWith(v))
          if (obj) {
            const val = field[1]
            if (!isNaN(Number(val))) {
              row[fieldName] = Number(Number(val).toFixed())
              // console.log(`  処理対象列：${fieldName}`, Number(val).toFixed())
            }
          }
        })
      })
    }
  }

  viewCheck(parentDiv: number): void {
    // IgxGridの幅を更新する。
    const newWidth = this.updateGridWidth(parentDiv);
    this.treeGrid.width = newWidth;
    this.treeGrid.cdr.detectChanges();
    this.cd.detectChanges();
  }

  // IgxGridのすべての列を横スクロール無しに表示するのに必要な幅を計算するfunction。縦スクロールバーの幅の考慮あり。
  // ※UI要素を参照しているので、IgxGridが描画された前に呼び出すと期待通りの動作をしません。
  calculateGridWidth(): number {
    // 簡易的にcolumns定義から計算した値を使っていますが、UI操作で列幅の変更や表示・非表示を切り替えられる設定にしている場合は、
    // IgxGridのcolumnsの現在の列情報をもとに計算するなど、ご自由に書き換えてください。
    let updatedWidth = this.totalColumnWidth;

    // 縦スクロールバーが表示されている場合は、その幅分、幅を追加する。
    if (this.treeGrid.verticalScrollContainer.isScrollable()) {
      let scrollBarWidth = Number(this.treeGrid.verticalScrollContainer.getScroll().style.getPropertyValue("width").slice(0, -2));
      updatedWidth = updatedWidth + scrollBarWidth;
    }

    // 計算した幅を返す。
    return updatedWidth;
  }

  // IgxGridの幅を100%にするかpx指定にするかを判定するfunction。
  updateGridWidth(parentDiv: number): string {
    const gridWidth = this.calculateGridWidth();
    // 親DiVの幅 < グリッドの幅の場合
    if (parentDiv < gridWidth) {
      return "100%";
    }
    // 上記以外の場合
    else {
      return gridWidth + "px";
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

}
