<div class="l-main__contents">
    <div id="wrapper">
      <div class="c-text-1 h-margin-b24">{{title}}</div>
      <div class="grid-area">
        <div class ="loading" *ngIf="loading">
            <app-loding-circle></app-loding-circle>
            </div>
            <div class="cassette-list-header">
              <a class="goto-area" (click)="gotoArea()">
                エリア選択に戻る
              </a>
            </div>

            <div class="wbs-area">
              <is-igx-wbs *ngIf="!loading" 
                [frontEndParams]="frontEndParams"
                (clickSubMenu)="clickSubMenu($event)">
              </is-igx-wbs>     
            </div>
      </div>
    </div>
  </div>

<app-snackbar #snackbar [autoHide]="true"></app-snackbar>