import { Injectable } from '@angular/core';
import { WBSManagementApiService } from '../api/wbs-management-api.service';
import { lastValueFrom } from 'rxjs';
import { wbsModel, wbsRelationModel } from '../interfaces/wbs.model';
import { IndexCoreMyideaApiService } from '../api/indexcore-myidea-api.service';
import { WbsUiMacroApiService } from '../api/wbs-ui-macro-api';
import { BackendUiMacroApiService } from '../api/backend-ui-macro-api.service';
import { foreignExchange } from '@igniteui/material-icons-extended';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WbsService {

  constructor(
    private wBSManagementApiService: WBSManagementApiService,
    private indexCoreMyideaApiService:IndexCoreMyideaApiService,
    private wbsUiMacroApiService:WbsUiMacroApiService,
    private backendUiMacroApiService:BackendUiMacroApiService
  ) { }

  private _selectedWbsId: string=''

  set selectedWbsId(selectedWbsId: string) {
    this._selectedWbsId = selectedWbsId
  }
  get selectedWbsId(): string {
    return this._selectedWbsId
  }

  // iframe用ファイル書き込み
  async createFile(payload:any):Promise<any>{    
    let fileId=''   

    const postSettings$ = this.wbsUiMacroApiService.postTempFile(payload)
    const postSettings = await lastValueFrom(postSettings$).catch(() => {
      return null
    })
    if(postSettings){
        fileId=postSettings.data
    }    
    return fileId
  }
  // iframe用ファイル読込み
  async getFileData(fileId:string){
    let retval: any=null
    const getFileData$ = this.wbsUiMacroApiService.getTempFile(fileId)
    let ret = await lastValueFrom(getFileData$).catch(() => {
      return retval
    })
    if (ret) {
      retval= ret
    } else {
      //データがなかったとき
      console.log(retval)
    }
    return retval
  }
  // //iframe用ファイル読込み => BC組織登録・更新
  // async registerBcOrganization(fileId:string,ownerId:string){
  //   //ファイル読込み
  //   const ret = await this.getFileData(fileId)

  //   if(ret){
  //     let retData=JSON.parse(ret.data)
  //     let newVal = retData.targetWbs

  //     newVal.forEach((element: { ownerId: string; }) => {
  //       element.ownerId=ownerId
  //     });

  //     let addVal=newVal.filter((x: { id: string; })=>x.id==='')
  //     let updVal=newVal.filter((x: { id: string; })=>x.id!=='')

  //     //新規登録
  //     if(addVal.length>0){
  //       const postRet$ = this.backendUiMacroApiService.PostBackendOrganization(addVal)
  //       const postRet = await lastValueFrom(postRet$).catch(() => {
  //         return false
  //       })
  //     }
  //     //更新
  //     if(updVal.length>0){
  //       const putRet$ = this.backendUiMacroApiService.PutBackendOrganization(updVal)
  //       const putRet = await lastValueFrom(putRet$).catch(() => {
  //         return false
  //       })
  //     }      
  //   }
  //   return true
  // }

  // //iframe用ファイル読込み => BC組織削除
  // async deleteBcOrganization(fileId:string,ownerId:string){
  //   //ファイル読込み
  //   const ret = await this.getFileData(fileId)

  //   if(ret){
  //     let retData=JSON.parse(ret.data)
  //     let targetVal = retData.targetWbs

  //     let delVal:wbsRelationModel[]=[]
  //     targetVal.forEach((element: { id: any; parentId: string; }) => {
  //       let tmp:wbsRelationModel={"parentId":ownerId,"id":element.id}
  //       delVal.push(tmp)
  //     });

  //     //削除      
  //     const delRet$ = this.backendUiMacroApiService.DeleteBackendOrganization(delVal)
  //     const delRet = await lastValueFrom(delRet$).catch(() => {
  //       return
  //     })
  //     return delRet
  //   }      
    
  // }
  // // BC組織取得
  // async getBcOrgsWbs(orgId:string):Promise<wbsModel[]>{
    
  //   let retval: any=null

  //   const getFileData$ = this.backendUiMacroApiService.GetBackendOrganization(orgId)
  //   let ret = await lastValueFrom(getFileData$).catch(() => {
  //     return retval
  //   })
  //   if (ret) {
  //     retval= ret.data
  //   }
  //   return retval

  // }
  // // 組織図（BC・BE合体）取得
  // async getMixedOrgsWbs(orgId:string,childDataKey:string):Promise<wbsModel[]>{
    
  //   let retval: any=null
    
  //   const getFileData$ = this.backendUiMacroApiService.GetMixedOrganizations(orgId)
  //   let ret = await lastValueFrom(getFileData$).catch(() => {
  //     return retval
  //   })
  //   if (ret) {
  //     retval= [ret.data]
      
  //     retval = this.setIconOrganization(retval,childDataKey)
  //   }
  //   return retval

  // }
  // // 組織図（BC・BE・プロファイル合体）取得
  // async getMixedOrgsProfileWbs(orgId:string):Promise<wbsModel[]>{
  
  //   let retval: any=null
    
  //   const getFileData$ = this.backendUiMacroApiService.GetMixedOrganizationProfiles(orgId)
  //   let ret = await lastValueFrom(getFileData$).catch(() => {
  //     return retval
  //   })
  //   if (ret) {
  //     retval= [ret.data]
  //   }
  //   return retval

  // }
  // //iframe用ファイル読込み => 稼働組織紐づけ登録・削除
  // async registerBCOrgRelation(fileId:string,isDirectL:boolean,isDirectR:boolean){
  //   //ファイル読込み
  //   const ret = await this.getFileData(fileId)

  //   if(ret){
  //     let retData=JSON.parse(ret.data)
  //     let newRelation = retData.newRelation
  //     let delRelation = retData.delRelation

  //     //紐づけ追加
  //     if(newRelation.length>0){
  //       const postRet$ = this.backendUiMacroApiService.PostBeBcOrgRelation(isDirectL,newRelation)
  //       const postRet = await lastValueFrom(postRet$).catch(() => {
  //         return false
  //       })
  //     }
  //     //紐づけ削除
  //     if(delRelation.length>0){
  //       const postRet$ = this.backendUiMacroApiService.DeleteBeBcOrgRelation(isDirectR,delRelation)
  //       const postRet = await lastValueFrom(postRet$).catch(() => {
  //         return false
  //       })
  //     }
  //     return true
  //   }
  //   else{
  //     return false
  //   }
  // }
  // //iframe用ファイル読込み => 組織プロファイル紐づけ登録・削除
  // async registerOrgProfileRelation(fileId:string,orgId:string,isDirectL:boolean,isDirectR:boolean){
  //   //ファイル読込み
  //   const ret = await this.getFileData(fileId)

  //   if(ret){
  //     let retData=JSON.parse(ret.data)
  //     let newRelation = retData.newRelation
  //     let delRelation = retData.delRelation
  //     // delRelation.forEach((element: { parentId: string; }) => {
  //     //   element.parentId=orgId
  //     // });
  //     //紐づけ追加
  //     if(newRelation.length>0){
  //       const postRet$ = this.backendUiMacroApiService.PostOrgProfilesRelation(isDirectL,newRelation)
  //       const postRet = await lastValueFrom(postRet$).catch(() => {
  //         return false
  //       })
  //     }
  //     //紐づけ削除
  //     if(delRelation.length>0){
  //       const postRet$ = this.backendUiMacroApiService.DeleteOrgProfilesRelation(isDirectR,delRelation)
  //       const postRet = await lastValueFrom(postRet$).catch(() => {
  //         return false
  //       })
  //     }
  //     return true
  //   }
  //   else{
  //     return false
  //   }
  // }
  setIcon(target:wbsModel[],childDataKey:string){
    let tmp=structuredClone(target)    
    tmp.forEach((element:wbsModel) => {
      if(element.profiles===null){
        element.profiles={}
      }
      if(element.wbsType!==undefined){
        if(element.wbsType==="file"){
          element.profiles={}
          element.profiles.icon='file'
        }
        if(element.wbsType==="area"){
          if(element.profiles===undefined){
            element.profiles={}
          }
          element.profiles.icon='area'
        }
        if(element.wbsType==="wbs"||element.wbsType==="cassette"){
          if(element.profiles===undefined){
            element.profiles={}
          }
          element.profiles.icon='cassette'
        }
        if(element.wbsType==="template"){
          if(element.profiles===undefined){
            element.profiles={}
          }
          element.profiles.icon='template'
        }
      }

      if(childDataKey==='nodeObjects'){
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            element.nodeObjects=this.setIcon(element.nodeObjects,childDataKey)
          }
        }
      }
      if(childDataKey==='children'){
        if(element.children!==undefined){
          if(element.children.length>0){
            element.children=this.setIcon(element.children,childDataKey)
          }
        }
      }     
    });
    return tmp
  }

  setUnMovablWbsType(target:wbsModel[],unMovableTypes:string[],childDataKey:string){
    let tmp=structuredClone(target)
    unMovableTypes.forEach(unMovableType => {      
      tmp.forEach(element => {      
        if(element.profiles===null){
          element.profiles={}
        }
        if(element.wbsType===undefined){
          element.profiles.movable=true
        }
        else{
          if(element.wbsType===unMovableType){
            element.profiles.movable=false
          }
          else{
            element.profiles.movable=true
          }          
        }
        if(childDataKey==='nodeObjects'){
          if(element.nodeObjects!==undefined){
            if(element.nodeObjects.length>0){
              element.nodeObjects=this.setUnMovablWbsType(element.nodeObjects,unMovableTypes,childDataKey)
            }
          }
        }
        if(childDataKey==='children'){
          if(element.children!==undefined){
            if(element.children.length>0){
              element.children=this.setUnMovablWbsType(element.children,unMovableTypes,childDataKey)
            }
          }
        }  
      });
    });
      
    return tmp
  }  
  //★★暫定 組織対応 組織もwbsTypeをセットしてもらったらsetIconと合体できる
  setIconOrganization(target:wbsModel[],childDataKey:string){
    let tmp=structuredClone(target)    
    tmp.forEach((element:wbsModel) => {
      if(element.profiles===null){
        element.profiles={}
      }      
      if(element.profiles.organizationTypeCd!==undefined){
        switch (element.profiles.organizationTypeCd){
          case "organization_type-company":
            element.profiles.icon='business'
            break
          case "organization_type-work_group":
          case "organization_type-section":
            element.profiles.icon='grid_view'
            break
          default:
            element.profiles.icon='join_right'
            break
        }
      }
      else{
        element.profiles.icon='join_right'
      }

      if(childDataKey==='nodeObjects'){
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            element.nodeObjects=this.setIconOrganization(element.nodeObjects,childDataKey)
          }
        }
      }
      if(childDataKey==='children'){
        if(element.children!==undefined){
          if(element.children.length>0){
            element.children=this.setIconOrganization(element.children,childDataKey)
          }
        }
      }     
    });
    return tmp
  }
  getDelData(delTree:wbsModel[]){
    let delData:any[]=[]
    delTree.forEach((element: wbsModel) => {
      if(element.id.substring(0,3)!=='new'){
        // let item={id:element.id,
        //           parentId:element.parentId}        
        delData.push(element)
      }
      else{
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            let child = this.getDelData(element.nodeObjects)
            if(child!==null){
              delData=delData.concat(child)
            }
          }
        }
      }
    });
    return delData
  }

  //フロントエンド設定取得
  async getFrontendSettings(appCd:string,frontendCd:string){
    
    const getFileData$ = this.indexCoreMyideaApiService.GetFrontendItems(appCd, frontendCd)          
    const ret = await lastValueFrom(getFileData$).catch(() => {
      return null
    })
    if(ret){
      return ret.data
    }
    return null
  }
  //WBS呼出し用パラメータセット
  setFrontendParams(frontendCd:string,paramItems:any){
    const appCd=environment.appCd
    const frontendBasePathCd=environment.frontendBasePathCd
    const frontendAddressCd=environment.frontendAddressCd
    
    const params={
      appCd:appCd,
      basePathFrontendCd:frontendBasePathCd,
      addressFrontendCd:frontendAddressCd,
      layoutFrontendCd:frontendCd,
      items:paramItems
    }

    return params
  }
}