export const environment = {
  production: false,
  login: {
    isbeLoginScript:
      '//backend-login-ui-v3.backend.out.nakamenosakura.com/wwwroot/js/be-login-core.js',
    isbeLogin: '//backend-login-ui-v3.backend.out.nakamenosakura.com/',
  },
  appCd:'documentWbs',
  frontendBasePathCd:'basePaths',
  frontendAddressCd:'address',

  myidea: '//myidea.backend.out.nakamenosakura.com',

  relationSetting: '//interserve-publicviews-ui.backend.out.nakamenosakura.com',
  // relationSetting: '//localhost:4300',
  wbsManagementApi:
    '//me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api',
  wbsUiMacroApi: '//wbsuimacroapi.isbe.out.nakamenosakura.com',
  backendUiMacro: '//backend-uimacroapi.backend.out.nakamenosakura.com',
  indexCoreMyidea: '//indexcore-myidea.isbe.out.nakamenosakura.com',
  // WBSプロト（旧健康診断）
  backendWbsProtoApi: '//backend-wbsprotoapi.backend.out.nakamenosakura.com',

  //ペタボ
  petaboUi: '//petabo-ui.backend.out.nakamenosakura.com',
  // petaboUi: '//localhost:4500',

  //出荷範囲テストモードURL
  ShipmentRangeApi: 'https://zofxbkdbm4kmvkrm5hr4augtvm0guqdo.lambda-url.ap-northeast-1.on.aws/',

};
