import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-petabo',
  templateUrl: './petabo.component.html',
  styleUrls: ['./petabo.component.scss'],
})
export class PetaboComponent implements OnInit {
  @Output() closed = new EventEmitter()
  @Input() isDialog = false;

  petaboFqdn = ''
  petaboSettingsId =''
  title = ''
  targetUrl: SafeUrl | undefined
  targetBasePath = environment.petaboUi
  myPath = window.location.origin
  loading = true
  apiloading = false

  constructor(
    private sanitizer: DomSanitizer,
    // private authService: AuthService,
    // private salesSupportApiService: SalesSupportApiService
  ) {}

  ngOnInit(): void {}
  open(petaboFqdn: string, petaboSettingsId: string, title: string): void {
    this.loading = true
    this.petaboFqdn = petaboFqdn
    this.petaboSettingsId=petaboSettingsId
    this.title = title
    const url = `${this.targetBasePath}/login/${this.petaboFqdn}/${petaboSettingsId}/petabo`
    this.targetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  close(){
    this.petaboFqdn = ''
    if(this.isDialog){
      this.closed.emit()
    }
  }
  reload() {
    const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement
    const iframe = element.contentWindow
    if (iframe !== null) {
      iframe.postMessage('reload-petabo', '*')
    }
  }
  @HostListener('window:message', ['$event']) onMessage(msgEvent: MessageEvent) {
    if (msgEvent.origin === window.location.protocol + this.targetBasePath) {
      // ログインセッション切れの場合
      if (msgEvent.data === 'session-timeout') {
        this.petaboFqdn = ''
        location.reload()
      }
    }
  }
  petaboloaded(value: any) {
    //iframeのloadが2度走る場合があるため、srcを見る
    if (value.target.src !== '') {
      this.loading = false
    }
  } 
}
