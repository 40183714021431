<!-- <is-igx-wbs *ngIf="!loading"
    [mappingData]="mappingData"
    (clickSubMenu)="clickSubMenu($event)">
</is-igx-wbs> -->

<!-- エリア紐づけ確認 -->
<!-- <is-igx-mapping *ngIf="!loading"
    [mappingData]="mappingData"
    [mappingDataHub]="mappingDataHub"
    (clickSubMenu)="clickSubMenu($event)"
    (clickPlusElement)="clickPlusElement($event)"
    >
</is-igx-mapping> -->
<!--テストデータ選択ダイアログ-->
<!-- 固定データ確認 -->
<is-igx-mapping *ngIf="!loading"
    [mappingData]="mappingData"
    [leftWbsStaticData]="StaticLeftWbsData"
    [rightWbsStaticData]="StaticRightWbsData"
    [mappingDataHub]="mappingDataHub"
    (clickSubMenu)="clickSubMenu($event)"
    (clickPlusElement)="clickPlusElement($event)"
    >
</is-igx-mapping>

<div *ngIf="selectedData">
  <h3>Selected Data:</h3>
  <pre>{{ selectedData | json }}</pre>
</div>
