import { Component, OnInit, ViewChild } from '@angular/core';
import { SideNavService } from './services/side-nav.service';
import { animate, state, style, transition, trigger } from '@angular/animations'
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const window: { BeLoginCore: any, sessionStorage: Storage, top: any, parent: Window, location: Location }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('navOpen', [
      state('close', style({
        paddingLeft: '0'
      })),
      transition('close => *', [
        animate('0.2s')
      ]),
      transition('* => close', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  // @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  title = 'wbs.ui';
  // scriptLoaded: boolean = false
  dispContent = true
  canDispContent = true
  showNav=true
  token = ''
  constructor(
    private router: Router, 
    private authService: AuthService,
    private sideNavService: SideNavService,
  ) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.startsWith('/login/')) {
          this.authService.readStrage()
          // if (this.authService.IsEmptyIds) {
          //   alert('My-ideaから再度アクセスし直して下さい')
          //   this.canDispContent = false
          //   return
          // }
        }
      }
    })
  }
  ngOnInit(): void {
    // アカウント情報の変更検知
    this.authService.accountSource$.subscribe(ret => {
      if (!this.canDispContent) {
        return
      }
      this.dispContent = true
    })
    //サイドメニュー非表示切り替えの変更検知(Viewerはサイドメニュー非表示)
    this.sideNavService.navSource$.subscribe(ret => {
      this.showNav=this.sideNavService.IsShowNave
    })
  }
  get navDisp(): boolean {
    return this.sideNavService.NavOpen
  }
  // logout() {
  //   window.BeLoginCore.logout()
  //   location.reload()
  //   // window.sessionStorage.clear()
  //   // this.authService.clear()
  //   // location.href = environment.myidea
  // }
  logout() {
    window.BeLoginCore.logout()
    window.location.href =environment.myidea
  }
}
