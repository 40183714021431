<header class="c-header">
  <div class="c-header__inner">
    <div class="c-header__box">
      <button
        *ngIf="this.canClickMenu"
        [class.is-open]="menuOpen"
        (click)="clickMenuBtn()"
        class="c-header__btn js-menu-trigger" style="cursor: pointer;">
        <i class="c-icon-navigation"></i><i class="c-icon-close"></i>
      </button>
      <div
        *ngIf="!this.canClickMenu"
        class="c-header__btn_hidden">
      </div>
      <!-- <h1 class="c-header__logo">
        <a [routerLink]="['/']" style="cursor: pointer;">          
        </a>
      </h1> -->
      <div style="font-size: 1.6rem; font-weight: bold;">
        Document Wbs
      </div>
    </div>
    <div class="c-header__box">
      <div [hidden]="true"
        class="c-header__help js-panel-trigger h-pc"
        data-target=".c-header__panel-help"
        (mouseenter)="showHelpPanel = true"
        (mouseleave)="mouseleaveHelpPanel($event)">
        <i class="c-icon-help2"></i>
      </div>
      <div class="c-header__idea h-pc"  [hidden]="true">
        <a target="_blank" style="cursor: pointer;">idea+</a>
      </div>
      <div
        (mouseenter)="showAdminPanel = true"
        (mouseleave)="mouseleaveAccountArea($event)"
        class="c-header__admin js-panel-trigger"
        data-target=".c-header__panel-admin">
        <p class="c-header__name h-pc">
          {{ account.familyName }} {{ account.firstName }}
        </p>
        <div
          class="c-avator"
          style="background-image: url(assets/images/avator_default.png);"
        ></div>
      </div>
    </div>
  </div>
  <div
    (mouseleave)="showAdminPanel = false"
    [@panelOpen]="showAdminPanel ? 'open' : ''"
    class="c-header__panel-admin c-panel"
    data-target=".c-header__panel-admin"
  >
    <div id="headerAdminPanel"
      class="c-header__panel-admin__cell">
      <div class="c-header__panel-admin__user">
        <div
          class="c-header__panel-admin__avator c-avator"
          style="
            background-image: url(assets/images/avator_default.png);
          "
        ></div>
        <div class="c-header__panel-admin__name">
          <p>{{ groupName }}</p>
          <p>{{ account.familyName }} {{ account.firstName }}</p>
        </div>
      </div>
    </div>
    <div
      class="c-header__panel-admin__cell"
    >
      <div class="c-header__panel-admin__link" [hidden]="true">
        <a style="cursor: pointer;"><span>My-idea（アカウント情報）</span></a>
      </div>
      <div class="c-header__panel-admin__link">
        <a (click)="clickLogoutBtn()" style="cursor: pointer;"><span>ログアウト</span></a>
      </div>
    </div>
  </div>
  <!-- <div
    id="helpPanel"
    (mouseleave)="showHelpPanel = false"
    [@panelOpen]="showHelpPanel ? 'open' : ''"
    class="c-header__panel-help c-panel"
    data-target=".c-header__panel-help"
  >
    <p><a target="_blank" style="cursor: pointer;">使い方</a></p>
    <p><a target="_blank" style="cursor: pointer;">よくあるご質問</a></p>
    <p><a target="_blank" style="cursor: pointer;">お問い合わせ</a></p>
  </div> -->
</header>
