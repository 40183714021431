<div class="c-bottom-nav" [@navOpen]="navDisp ? '' : 'close'">
  <div class="c-bottom-nav__contents">
    <div class="c-bottom-nav__item">
      <div
        *ngIf="buttonId !== ''"
        class="c-bottom-nav__link c-bottom-nav__link--gray"
      >
        <a (click)="_clickButton()"
          ><i class="c-icon-trash"></i>{{buttonText}}</a
        >
      </div>
    </div>
    <ul class="c-bottom-nav__list">
      <li class="c-bottom-nav__item">
        <div class="c-bottom-nav__text">
          {{ text }}
          <i
            *ngIf="helpText !== ''"
            class="c-icon-help"
            #target="tooltipTarget"
            [igxTooltipTarget]="tooltipRef"
          >
            <div #tooltipRef="tooltip" igxTooltip style="width: 400px">
              {{ helpText }}
            </div>
          </i>
        </div>
      </li>
      <ng-content></ng-content>
    </ul>
  </div>
</div>
