import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class TestdataService {
  constructor() {}
  public selectData: any = undefined
  getSelectedData() {
    return this.selectData
  }
  setSelectedData(data: any) {
    this.selectData = data
  }
}
