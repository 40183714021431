import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class FileDLService {
  constructor(
  ) { }
  /**
   * ファイルダウンロード
   * @param data -FileのデータAPIからのReturn
   * @param type -Fileのタイtext/plain等
   * @param filename - 出力されるファイルの名前
   */
  public downLoadFile(data: Blob | HttpResponse<any> | any, type?: string, filename?: string): void {

    if (data?.body && data?.headers) {
      //headerやbodyがあればそこからtypeやfilenameを取得する。
      this.hasBodyDataDL(data, filename);
    } else {
      if (type && filename) {
        this.fileDl(data, type, filename)
      } else {

      }
    }

  }

  /**
   * bodyがない場合のDL方法
   * @param data
   * @param type
   * @param filename
   */
  private fileDl(data: any, type: string, filename?: string) {
    let blob = new Blob([data], { type: type });
    let downLoadElem = document.createElement('a');
    downLoadElem.href = window.URL.createObjectURL(blob);
    //filenameの指定があれば優先する。
    if (filename) downLoadElem.setAttribute('download', filename);
    document.body.appendChild(downLoadElem);
    downLoadElem.click();
    document.body.removeChild(downLoadElem);
  }
  private hasBodyDataDL(data: HttpResponse<any>, fileName?: string) {
    const headers = data.headers;
    //ファイル名なければbodyから取得
    if (!fileName) fileName = this.getFileNameFromContentDisposition(headers)??'';
    //content-typeを取得
    const type = this.getContentType(headers)??''
    //ファイルダウンロード
    this.fileDl(data.body, type, fileName)
  }
  /**
   * content-typeを取得
   * @param headers
   * @returns
   */
  public getContentType(headers: HttpHeaders) {
    return headers.get('content-type')
  }
  /**
   * ファイル名を抜き出す
   * @param disposition
   * @returns
   */
  public getFileNameFromContentDisposition(headers: HttpHeaders) {
    const disposition = headers.get('content-disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
    const matches = filenameRegex.exec(disposition??'');
    if (matches != null && matches[1]) {
      const fileName = matches[1].replace(/['"]/g, '');
      return decodeURI(fileName) // 日本語対応
    } else {
      return null
    }
  }
}
