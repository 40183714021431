import { useAnimation } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { HorizontalAlignment, IgxSnackbarComponent, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular'
import { slideInTop, slideOutBottom } from '@infragistics/igniteui-angular/animations';
import { SnackbarService } from './snackbar.service'

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(private snackbarService: SnackbarService) { }
  @Input() autoHide: boolean = true
  @ViewChild('snackbar', { static: true }) public snackbar!: IgxSnackbarComponent
  type: 'error' | 'sucsess' | 'warning' = 'sucsess'
  public newPositionSettings: PositionSettings = {
    horizontalDirection: HorizontalAlignment.Center,
    verticalDirection: VerticalAlignment.Top,
    verticalStartPoint: VerticalAlignment.Middle
  };

  ngOnInit(): void {
  }
  /**
   * 開く
   */
  open(masage: string = ""): void {

    this.snackbar.open(masage)
  }
  close(): void {
    this.snackbar.close()
  }

  success(masage: string = "") {
    this.type = "sucsess"
    this.snackbar.open(masage)
  }
  warning(masage: string = "") {
    this.type = "warning"
    this.snackbar.open(masage)
  }
  error(masage: string = "") {
    this.type = "error"
    this.snackbar.open(masage)
  }
}
