import { Component, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { wbsMappingDataResponse,wbsMappingDataResponse2,AreaData,SelectwbsItemData, wbsItemViewerData, AreaMappingData, AreaMappingHubData, selectAreaData, CassetteMappingData, SimulationMappingData, SimulationStaticLeftWbs, SimulationStaticRightWbs, selectDataHubInfo, selectDataHub } from './data'
// import { SelectULItemHubSettings } from '../is-igx-hub-sample/sample/mappingData'
import { FormsModule } from '@angular/forms'
import { IsIgxDialogComponent, IsIgxHubComponent, IsIgxMappingComponent, IsIgxWbsComponent } from '@interserve/igx-angular'
import { IgxButtonModule } from '@infragistics/igniteui-angular'
import { TestdataService } from '../../services/testdata.service'
import { ShipemntRangeApiServiceApiService } from '../../api/shipment-range-api.service'
import { lastValueFrom } from 'rxjs'

// import { IsIgxMappingComponent } from '../../../../projects/interserve/igx-angular/src/lib/components/is-igx-mapping/is-igx-mapping.component'
// import { IsIgxWbsComponent } from '../../../../projects/interserve/igx-angular/src/lib/components/is-igx-wbs/is-igx-wbs.component'
// import { IsIgxMappingComponent, IsIgxWbsComponent } from '@interserve/igx-angular'
@Component({
    selector: 'is-igx-wbs-sample',
    standalone: true,
    templateUrl: './SampleTestDataWorkDate.html',
    styleUrl: './SampleTestDataWorkDate.scss',
    imports: [CommonModule, IsIgxWbsComponent, FormsModule, IsIgxMappingComponent,IsIgxHubComponent, IsIgxDialogComponent,IgxButtonModule]
})
export class SampleTestDataWorkDate {
  @ViewChild('selectDataHub', { static: true }) public selectDataHub: IsIgxHubComponent | undefined
  constructor(private testdataService :TestdataService,private shipemntRangeApiServiceApiService:ShipemntRangeApiServiceApiService) {}
  loading = true
  selectedData: any = null;
  StaticLeftWbsData: any = undefined
  StaticRightWbsData: any = undefined
  basePathInfo: any = undefined
  mappingData: any = undefined
  mappingDataString = ''
  actions = {
    function1: () => {
      console.log('function1')
    },
    // 行をクリックした時のイベント
    cellClick: (arg1: any, arg2: any) => {
      console.log(`呼び出し元のプロジェクト:`, arg1, arg2)
      console.log(this)
    },
  }
  //HUB設定（紐づけで使用）
  mappingDataHub: any = AreaMappingHubData
  selectTestDataHubSettings=structuredClone(selectDataHubInfo)
  staticSelectTestData=structuredClone(selectDataHub)
  selectedTestDataIds: any[] = []
  selectTestDataHubActions = {
    cellClick: (event: any) => {
     this.selectedTestDataIds=event.newSelection
    },
    leftWbsLeftFooterAction: (event: any) => {
      this.selectDataHub?.close()
    },
    leftWbsRightFooterAction: (event: any) => {
     if (this.selectedTestDataIds.length===0) {
        alert('WBSを選択してください。')
      } else {
        //画面遷移する。


        console.log('WBSID', this.selectedTestDataIds)
      }
    },
  }

 async ngOnInit(): Promise<void> {
    // this.mappingData = wbsItemViewerData
    // this.mappingData = AreaMappingData
    // this.mappingData = CassetteMappingData
    // this.mappingData =SelectULItemHubSettings
    // this.mappingData =selectAreaData
    this.selectedData = this.testdataService.getSelectedData();
     // console.log(this.testdataService.getSelectedData())
    //物流改善
    this.mappingData =SimulationMappingData
   const rightWbsData = await this.GetShipmentRangeCreateData()
    this.StaticLeftWbsData=SimulationStaticLeftWbs
    this.StaticRightWbsData=rightWbsData

    this.mappingDataString = JSON.stringify(this.mappingData, null, 2)
    this.loading = false
  }
async  GetShipmentRangeCreateData():Promise<any>{
    const fileNameList = this.selectedData.map((x:{name:string})=>x.name)
   // const observable = lastValueFrom(this.shipemntRangeApiServiceApiService.ShipmentRangeCreate('cb910882d3944ac098de3df9a2f6e4f6', fileNameList))
  const observable = lastValueFrom(this.shipemntRangeApiServiceApiService.ShipmentRangeTestModeCreate('cb910882d3944ac098de3df9a2f6e4f6', fileNameList))
  const result = await observable
    return result.data
}
  get calcHeight() {
    const element = document.querySelector('body')
    const height = element?.offsetHeight || 0
    const calculatedHeight = height - 28
    // console.log('calculatedHeight', calculatedHeight)
    return calculatedHeight + 'px'
  }

  reflectMappingData() {
    try {
      const json = JSON.parse(this.mappingDataString)
      this.mappingData = json
    } catch (error) {
      console.error(error)
    }
  }
  clickSubMenu(value: any){
    console.log('clickSubMenu:' + value)
  }
  clickPlusElement(value: any){
    //デフォルトの要素追加ダイアログを使わない場合はここで独自ダイアログを呼び出す（My-ideaの顧客登録などと連携）
    console.log('clickPlusElement:'+value)
  }

  openTestDataHub(){
    this.staticSelectTestData=structuredClone(selectDataHub)
    this.selectDataHub?.open()
 }
}
