<aside [@navOpen]="disp" class="c-nav js-navigation">
  <div class="c-nav__wrap">
    <nav class="c-nav__main">
      <ul class="c-nav__list">
        <ng-container *ngFor="let item of navItems">
          <li
            *ngIf="item.data"
            class="c-nav__item"
          >
            <p class="c-nav__name">
              <a
                [title]="item.data['title']"
                [routerLink]= "['/' + item.path]"
                queryParamsHandling="preserve"
                [class.is-current]="isActiveMenu(item)"
                (click)="clickLink(item.path) " >
                <i [class]="'c-icon-' + item.data['icon']"></i>
                {{ item.data["title"] }}
              </a>
              <span
                *ngIf="item.children"
                (click)="item.data['isOpen'] = !item.data['isOpen']"
                class="c-nav__arrow"
                [class.is-open]="item.data['isOpen']"
              >
                <i class="c-icon-arrow-s-bottom"></i>
              </span>
            </p>
            <ul
              *ngIf="item.children"
              [class.is-open]="item.data['isOpen']"
              class="c-nav__children"
            >
              <ng-container *ngFor="let child of item.children">
                <li class="c-nav__sub"
                >
                  <a
                    [title]="child.data!['title']"
                    [routerLink]= "['/' + child.path]"
                    queryParamsHandling="preserve"
                    [class.is-current]="checkCurrentView(item, child)"
                    (click)="clickLink(child.path)"
                    >{{ child.data!["title"] }}
                  </a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>

      <!-- <small class="c-nav__copyright">©︎ Shimamura.Co.Ltd. All rights reserved.</small> -->
    </nav>
  </div>
</aside>
