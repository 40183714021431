import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CheckServerUpdateComponent } from './components/check-server-update/check-server-update.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { IgxButtonModule, IgxDialogModule, IgxGridModule, IgxRadioModule, IgxSimpleComboModule, IgxSnackbarModule, IgxTooltipModule, IgxTreeGridModule } from '@infragistics/igniteui-angular';
import { TreeGridComponent } from './components/tree-grid/tree-grid.component';
import { HttpClientModule } from '@angular/common/http';
import { YesNoDialogComponent } from './components/dialogs/yes-no-dialog/yes-no-dialog.component';
import { lodingCircleComponent } from './components/loding-circle/loding-circle.component';
import { footerComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { RouterModule, Routes } from '@angular/router';
import { SideNavMenuComponent } from './components/side-nav-menu/side-nav-menu.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { SelectedFileDialogComponent } from './components/dialogs/selected-file-dialog/selected-file-dialog.component';
import { UploadDialogComponent } from './components/dialogs/upload-dialog/upload-dialog.component';
import { CreateAreaV1Component } from './views/create-area-v1/create-area-v1.component';
import { CreateCassetteComponent } from './views/create-cassette/create-cassette.component';
import { SelectAreaComponent } from './views/select-area/select-area.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { PetaboComponent } from './components/petabo/petabo.component';
import { PetaboDialogComponent } from './components/dialogs/petabo-dialog/petabo-dialog.component';
import { WBSViewerComponent } from './views/wbs-viewer/wbs-viewer.component';
import { IsbeauthComponent } from './isbeauth/isbeauth.component';
import { IsIgxHubComponent, IsIgxMappingComponent, IsIgxWbsComponent,IsIgxWbsTreeGridComponent } from '@interserve/igx-angular';
import { CreateAreaComponent } from './views/create-area/create-area.component';
import { SelectAreaV1Component } from './views/select-area-v1/select-area-v1.component';
import { WBSItemViewerComponent } from './views/wbs-item-viewer/wbs-item-viewer.component';
import { SelectWbsItemComponent } from './views/select-wbs-item/select-wbs-item.component';
import { WBSViewerV1Component } from './views/wbs-viewer-v1/wbs-viewer-v1.component';
import { HubDialogComponent } from './components/dialogs/hub-dialog/hub-dialog.component';
import { CreateCassetteV1Component } from './views/create-cassette-v1/create-cassette-v1.component';
import { UpdateWbsItemComponent } from './views/update-wbs-item/update-wbs-item.component';
import { IsIgxMappingWbsItemComponent } from './views/update-wbs-item/mapping/is-igx-mapping-wbs-item/is-igx-mapping-wbs-item.component';
import { SelectAreaV2Component } from './views/select-area-v2/select-area-v2.component';

const routes: Routes = [];
@NgModule({
  declarations: [
    AppComponent,
    CheckServerUpdateComponent,
    IsbeauthComponent,
    SnackbarComponent,
    TreeGridComponent,
    YesNoDialogComponent,
    lodingCircleComponent,
    ButtonComponent,
    PageHeaderComponent,
    footerComponent,
    SideNavMenuComponent,
    SelectedFileDialogComponent,
    UploadDialogComponent,
    CreateAreaV1Component,
    SelectAreaV1Component,
    SelectAreaV2Component,
    WBSViewerV1Component,
    SelectAreaComponent,
    SelectWbsItemComponent,
    CreateAreaComponent,
    CreateCassetteComponent,
    CreateCassetteV1Component,
    DashboardComponent,
    PetaboComponent,
    PetaboDialogComponent,
    WBSViewerComponent,
    WBSItemViewerComponent,
    HubDialogComponent,
    UpdateWbsItemComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IgxSnackbarModule,
    IgxDialogModule,
    IgxGridModule,
    IgxTreeGridModule,
    IgxButtonModule,
    IgxSimpleComboModule,
    IgxTooltipModule,
    IgxRadioModule,
    IsIgxWbsComponent,
    IsIgxHubComponent,
    IsIgxMappingComponent,
    IsIgxWbsTreeGridComponent,
    IsIgxMappingWbsItemComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
