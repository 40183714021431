import { Component, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { wbsMappingDataResponse,wbsMappingDataResponse2,AreaData,SelectwbsItemData, wbsItemViewerData, AreaMappingData, AreaMappingHubData, selectAreaData, CassetteMappingData, SimulationMappingData, SimulationStaticLeftWbs, SimulationStaticRightWbs, selectDataHubInfo, selectDataHub } from './data'
// import { SelectULItemHubSettings } from '../is-igx-hub-sample/sample/mappingData'
import { FormsModule } from '@angular/forms'
import { IsIgxDialogComponent, IsIgxHubComponent, IsIgxMappingComponent, IsIgxWbsComponent } from '@interserve/igx-angular'
import { IgxButtonModule } from '@infragistics/igniteui-angular'
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router'
import { TestdataService } from '../../services/testdata.service'
import { ShipemntRangeApiServiceApiService } from '../../api/shipment-range-api.service'
// import { IsIgxMappingComponent } from '../../../../projects/interserve/igx-angular/src/lib/components/is-igx-mapping/is-igx-mapping.component'
// import { IsIgxWbsComponent } from '../../../../projects/interserve/igx-angular/src/lib/components/is-igx-wbs/is-igx-wbs.component'
// import { IsIgxMappingComponent, IsIgxWbsComponent } from '@interserve/igx-angular'
@Component({
    selector: 'is-igx-wbs-sample2',
    standalone: true,
    templateUrl: './sample-testdata-create.html',
    styleUrl: './SampleTestDataCreateComponent.scss',
    imports: [CommonModule, IsIgxWbsComponent, FormsModule, IsIgxMappingComponent,IsIgxHubComponent, IsIgxDialogComponent,IgxButtonModule]
})
export class SampleTestDataCreateComponent {
  @ViewChild('selectDataHub', { static: false }) public selectDataHub: IsIgxHubComponent | undefined
  constructor(private router:Router,private testdataService:TestdataService,private shipemntRangeApiServiceApiService:ShipemntRangeApiServiceApiService) {
    //this.startDate = new Date();

  }
  fileName:string = '';
  startDate: string = '';
  endDate: string = '';
  dueDate: string = '';
  showMessage = false;
  message = '';

  loading = true
  StaticLeftWbsData: any = undefined
  StaticRightWbsData: any = undefined
  basePathInfo: any = undefined
  mappingData: any = undefined
  mappingDataString = ''
  reload = false
  actions = {
    function1: () => {
      console.log('function1')
    },
    // 行をクリックした時のイベント
    cellClick: (arg1: any, arg2: any) => {
      console.log(`呼び出し元のプロジェクト:`, arg1, arg2)
      console.log(this)
    },
  }
  //HUB設定（紐づけで使用）
  mappingDataHub: any = AreaMappingHubData
  selectTestDataHubSettings=structuredClone(selectDataHubInfo)
  staticSelectTestData=structuredClone(selectDataHub)
  selectedTestDataIds: any[] = []
  selectTestDataHubActions = {
    cellClick: (event: any) => {
     this.selectedTestDataIds=event.newSelection
    },
    leftWbsLeftFooterAction: (event: any) => {
      this.selectDataHub?.close()
    },
    leftWbsRightFooterAction: (event: any) => {
     if (this.selectedTestDataIds.length===0) {
        alert('WBSを選択してください。')
      } else {
       //選択したものをループして確認
       this.testdataService.setSelectedData(this.selectedTestDataIds)
       this.router.navigate(['納品日指定'])
        console.log('WBSID', this.selectedTestDataIds)
      }
    },
  }

  createContent() {

    var msg = 'ファイル名:' + this.fileName + "\n"
    + '期間:'+ this.startDate + '~' +this.endDate + "\n" ;
    const addData = {
      id:crypto.randomUUID() ,
      name: this.fileName,
    }

    if(this.fileName === ''){
      alert('ファイル名を入力してください')
      return
    }
    const createFileObj= {
      "fileName": this.fileName,
      "distributionPlaceCd": "cb910882d3944ac098de3df9a2f6e4f6",
      "deliveryDateFrom": this.startDate,
      "deliveryDateTo": this.endDate,
      "replaceBaseDate": this.dueDate
    }

    // ファイル作成API呼び出し
    this.shipemntRangeApiServiceApiService.FileCreate(createFileObj)
      .subscribe(ret=>{
      this.message = msg;
      this.showMessage = true;
      }
    )

  }

  ngOnInit(): void {
    // this.mappingData = wbsItemViewerData
    // this.mappingData = AreaMappingData
    // this.mappingData = CassetteMappingData
    // this.mappingData =SelectULItemHubSettings
    // this.mappingData =selectAreaData
    this.startDate = '2024-03-01'
    this.endDate = '2024-03-03'

    this.dueDate = '2024-03-18';

    this.mappingDataString = JSON.stringify(this.mappingData, null, 2)
    this.loading = false
  }

  get calcHeight() {
    const element = document.querySelector('body')
    const height = element?.offsetHeight || 0
    const calculatedHeight = height - 28
    // console.log('calculatedHeight', calculatedHeight)
    return calculatedHeight + 'px'
  }

  reflectMappingData() {
    try {
      const json = JSON.parse(this.mappingDataString)
      this.mappingData = json
    } catch (error) {
      console.error(error)
    }
  }
  clickSubMenu(value: any){
    console.log('clickSubMenu:' + value)
  }
  clickPlusElement(value: any){
    //デフォルトの要素追加ダイアログを使わない場合はここで独自ダイアログを呼び出す（My-ideaの顧客登録などと連携）
    console.log('clickPlusElement:'+value)
  }

  openTestDataHub(){
    this.shipemntRangeApiServiceApiService.FileList('cb910882d3944ac098de3df9a2f6e4f6').subscribe((ret)=>{
        console.log(ret)
      const data = ret.data.map((item: any) => {
        return {id:item.eTag,name:item.key}
      })

      this.staticSelectTestData=data
      this.selectDataHub?.open()

      }

    )


 }
}
