import { Injectable } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { Subject } from 'rxjs'
import { AccountInfo } from '../interfaces/auth'
import { DecodeParam } from '../utils/RoutingEncode'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private account: AccountInfo | undefined
  // private garageId = ''
  // private teamId = ''
  private applicationId = ''
  // private fileboxId = ''
  // private businessCategoryName = ''
  // private businessCategoryCd = ''
  // private groupName = ''
  // private itemLeafId = ''
  // private supplierCd: string = ''
  private actuateId: string = ''

  constructor(private activatedRoute: ActivatedRoute) { }

  private accountSource = new Subject<AccountInfo>()
  accountSource$ = this.accountSource.asObservable()

  get Account(): AccountInfo | undefined {
    return this.account
  }
  setAccount(account: AccountInfo): void {
    this.account = account
    this.accountSource.next(account)
  }

  get Token(): string {
    return this.account?.token ?? ''
  }

  // get GarageId(): string {
  //   return this.garageId
  // }
  // setGarageId(garageId: string): void {
  //   this.garageId = garageId
  //   this.setStrage()
  // }

  // get TeamId(): string {
  //   return this.teamId
  // }
  // setTeamId(teamId: string): void {
  //   this.teamId = teamId
  //   this.setStrage()
  // }

  get ApplicationId(): string {
    return this.applicationId
  }
  setApplicationId(applicationId: string): void {
    this.applicationId = applicationId
    this.setStrage()
  }

  // get FileboxId(): string {
  //   return this.fileboxId
  // }
  // setFileboxId(fileboxId: string): void {
  //   this.fileboxId = fileboxId
  //   this.setStrage()
  // }

  // get BusinessCategoryName(): string {
  //   return this.businessCategoryName
  // }
  // setBusinessCategoryName(businessCategoryName: string): void {
  //   this.businessCategoryName = businessCategoryName
  //   this.setStrage()
  // }

  // get BusinessCategoryCd(): string {
  //   return this.businessCategoryCd
  // }
  // setBusinessCategoryCd(businessCategoryCd: string): void {
  //   this.businessCategoryCd = businessCategoryCd
  //   this.setStrage()
  // }

  // get GroupName(): string {
  //   return this.groupName
  // }
  // setGroupName(groupName: string): void {
  //   this.groupName = groupName
  //   this.setStrage()
  // }

  // get ItemLeafId(): string {
  //   return this.itemLeafId
  // }
  // setItemLeafId(itemLeafId: string): void {
  //   this.itemLeafId = itemLeafId
  //   this.setStrage()
  // }
  // setSupplierCd(supplierCd: string) {
  //   this.supplierCd = supplierCd
  //   this.setStrage()
  // }
  // get SupplierCd() {
  //   return this.supplierCd
  // }
  setActuateId(actuateId: string) {
    this.actuateId = actuateId
    this.setStrage()
  }
  get ActuateId() {
    return this.actuateId
  }
  // get IsEmptyIds(): boolean {
  //   return !this.garageId || !this.teamId || !this.applicationId || !this.fileboxId
  // }
  // private getStrageKey(key: string, applicationId: string) {
  //   return `${key}-${applicationId}`
  // }
  setStrage(): void {
    const setStrage = {
      // garageId: this.garageId,
      // teamId: this.teamId,
      applicationId: this.applicationId,
      // fileboxId: this.fileboxId,
      // businessCategoryName: this.businessCategoryName,
      // businessCategoryCd: this.businessCategoryCd,
      // groupName: this.groupName,
      // itemLeafId: this.itemLeafId,
      // supplierCd: this.supplierCd,
      actuateId: this.actuateId
    }
    const jsonString = JSON.stringify(setStrage)
    if (this.applicationId) {
      localStorage.setItem(this.applicationId + '-storage', jsonString)
    }
  }

  readStrage(): void {
    const url = location.href
    var result = url.split('/')
    const id = result.slice(-1)[0]

    var resultSplit = id.split('-')
    var decodeParam = DecodeParam(resultSplit[0])

    for (const [index, value] of resultSplit.entries()) {
      if (index !== 0) {
        decodeParam = decodeParam + '-' + value
      }
    }

    var getStrageId = ''
    if (decodeParam) {
      getStrageId = decodeParam
    } else {
      // this.garageId = ''
      // this.teamId = ''
      this.applicationId = ''
      // this.fileboxId = ''
    }
    const getStrage = localStorage.getItem(getStrageId + '-storage')
    if (getStrage) {
      const getItem = JSON.parse(getStrage)
      // this.garageId = getItem.garageId ?? ''
      // this.teamId = getItem.teamId ?? ''
      this.applicationId = getItem.applicationId ?? ''
      // this.fileboxId = getItem.fileboxId ?? ''
      // this.businessCategoryName = getItem.businessCategoryName ?? ''
      // this.businessCategoryCd = getItem.businessCategoryCd ?? ''
      // this.groupName = getItem.groupName ?? ''
      // this.itemLeafId = getItem.itemLeafId ?? '',
      //   this.supplierCd = getItem.supplierCd ?? ''
      this.actuateId = getItem.actuateId ?? ''
    }
  }
  clear(): void {
    this.account = undefined
    // this.authorizationToken = null
    // this.garageId = null
    // this.reloadInfo="home"
  }
}