<div class="l-main__contents" style="padding:20px 0; min-width: 400px;">
    <form class="h-margin-">
        <div class="l-table">
          <!-- <div *ngIf="parentWbs!==undefined" class="l-table__item">
            <div class="l-table__head l-table__head--sm">
              <p> 親の要素名 </p>
            </div>
            <div class="l-table__body">
              <p>{{ parentWbs.name }}</p>
            </div>
          </div> -->
          <div class="l-table__item">
            <div class="l-table__head l-table__head--sm">
              <p> 要素名 </p>
            </div>
            <div class="l-table__body">
              <input
                  class="c-form__input"
                  type="text"
                  placeholder="WBS要素名を入力してください"
                  name="WbsName"
                  id="WbsName"
                  [(ngModel)]="element.name"
                  maxlength="50"
                  required
                  (input)="inputElementName($event)"
              />
            </div>
          </div>
        </div>
    </form>
</div>