import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { WBSManagementApiService } from 'src/app/api/wbs-management-api.service';
import { wbsModel } from 'src/app/interfaces/wbs.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { YesNoDialogComponent } from 'src/app/components/dialogs/yes-no-dialog/yes-no-dialog.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { IgxTreeGridComponent } from '@infragistics/igniteui-angular';
import { WbsService } from 'src/app/services/wbs.service';
import { WbsUiMacroApiService } from 'src/app/api/wbs-ui-macro-api';
import { UploadDialogComponent } from 'src/app/components/dialogs/upload-dialog/upload-dialog.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BackendWbsProtoApiService } from 'src/app/api/backend-wbs-proto-api';
import { AuthService } from 'src/app/services/auth.service';
import { FileDLService } from 'src/app/api/file-dl.service';
import { SideNavService } from 'src/app/services/side-nav.service';

@Component({
  selector: 'app-wbs-viewer-v1',
  templateUrl: './wbs-viewer-v1.component.html',
  styleUrls: ['./wbs-viewer-v1.component.scss']
})
export class WBSViewerV1Component implements OnInit {
  @ViewChild('createWbsDialog') createWbsDialog?: YesNoDialogComponent
  @ViewChild('uploadFileDialog') uploadFileDialog?: UploadDialogComponent;
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  @ViewChild('wbsGrid', { read: IgxTreeGridComponent, static: true }) public wbsGrid!: IgxTreeGridComponent
  @ViewChild('cassetteViewDialog') cassetteViewDialog?: YesNoDialogComponent
  @ViewChild('yesNoDialog') yesNoDialog?: UploadDialogComponent;  

  public title='WBS Viewer' 
  public apiLoading=false
  public loading=false
  public isDialogOpen=false
  public isShowArea=true  //true=>エリア表示、false=>カセット表示
  public customerId=''
  public organizaionId=''
  
  public wbsIds:string[]=[]
  public wbsList:wbsModel[]=[]
  public canUpdateElement:boolean=false
  public canAddTop:boolean=false
  public filterOn:boolean=false  
  public showSubMenu:boolean=true
  public draggableOn:boolean=false
  public childDataKey:string='nodeObjects'
  // public subMenu: SubMenuModel[] = [{ menuCd: 1, menuName: 'ファイルアップロード' }]
  public selectedArea:any

  public targetUrl: SafeUrl | undefined
  private relationSettingPath = environment.relationSetting
  private settingFileId:string=''
  
  public dialogParams =
  {
    messageCd: 'cassetteView',
    title: '',
    message: '',
    buttonId: '',
    yesButtonCaption:'アップロード',
    noButtonCaption:'閉じる'
  }
  public dialogParams_del_file =
  {
    messageCd: 'del_file',
    title: 'ファイル削除',
    message: '実行しますか？',
    buttonId: 'del_file',
    yesButtonCaption:'はい',
    noButtonCaption:'キャンセル'
  } 
  public buttonData: any = [
    {
      "key": "createWbs",
      "name": "WBS新規作成",
      "color": "blue"
    },
    {
      "key": "compile",
      "name": "コンパイル",
      "color": "primary"
    },
    {
      "key": "run",
      "name": "実行",
      "color": "primary"
    },
  ]
  
  public targetWbs:wbsModel | undefined

  constructor(
    private wbsUiMacroApiService:WbsUiMacroApiService,
    private wbsService:WbsService,
    private sanitizer: DomSanitizer,
    private backendWbsProtoApiService:BackendWbsProtoApiService,
    private fd: FileDLService,
    private activatedRoute: ActivatedRoute,
    private sideNavService: SideNavService,
    private router: Router,
  ) {}
  public selectedAreaId=''
  public updatable=false

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.selectedAreaId=params.get('areaId') ?? ''
      this.updatable=params.get('updatable')==='0'?false:true //0:閲覧のみ、1:編集可
      if(this.updatable===false){
        this.sideNavService.setNavOpen(false)
        this.sideNavService.setIsShowNave(false)
      }
    })    
    this.initialize() 
  }
  async initialize(){
    this.loading=true
    try {
        const ret = await this.getWbs()
        this.wbsList=structuredClone(ret)      

        await this.createIframe()
        this.loading=false

        if(this.updatable){
          this.title='ドキュメント管理／カセット一覧（ファイルアップロード・ダウンロード・削除）' //カセット表示

          // // カセットのprofileにサブメニューをセットする
          // retval = this.setCassetteSubMenu(retval)
          // return retval
        }
        else{
          this.title='WBS Viewer'
        }
    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }
  async getWbs():Promise<wbsModel[]>{
    
    let retval: any=null
    let rootOnly=false
    let map='files'

    const getFileData$ = this.backendWbsProtoApiService.GetWbsForViewer(this.selectedAreaId,rootOnly,map)
    let ret = await lastValueFrom(getFileData$).catch(() => {
      return retval
    })
    if (ret) {
      // retval= ret.data
      retval= [ret.data]
    }
    
    // retval = this.wbsService.setIcon(retval,this.childDataKey)

    // // カセットのprofileにサブメニューをセットする
    let canUpload =this.updatable
    let canDownload =true
    // retval = this.setSubMenu(retval,canUpload,canDownload)

    // カセットのprofileにサブメニューをセットする
    retval = this.setCassetteSubMenu(retval,canUpload,canDownload)
    return retval

  }
  setCassetteSubMenu(target:wbsModel[],canUpload:boolean,canDownload:boolean){
    let tmp=structuredClone(target)

    tmp.forEach((element:wbsModel) => {
      if(element.profiles===undefined){
        element.profiles={}
      }
      if(element.profiles===null){
        element.profiles={}
      }
      let wbsType="wbs"
      if(element.wbsType!==undefined){
        if(element.wbsType==="file"){
          wbsType='file'
          element.profiles.canDownload=true
          element.profiles.subMenu=[{ menuCd: 2, menuName: 'ファイルダウンロード' },{ menuCd: 6, menuName: 'ファイルを削除' }]
          element.profiles.icon=element.wbsType
        }
      }
      if(wbsType==="wbs"){
        element.profiles.icon=element.wbsType
        if(element.wbsType==="area"){
          element.profiles.showSubMenu=false
        }
        if(element.wbsType==="cassette"){
          element.profiles.showSubMenu=true
          element.profiles.subMenu=[{ menuCd: 1, menuName: 'ファイルアップロード' }]        
        }        
      }

      if(this.childDataKey==='nodeObjects'){
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            element.nodeObjects=this.setCassetteSubMenu(element.nodeObjects,canUpload,canDownload)
          }
        }
      }
      if(this.childDataKey==='children'){
        if(element.children!==undefined){
          if(element.children.length>0){
            element.children=this.setCassetteSubMenu(element.children,canUpload,canDownload)
          }
        }
      }     
    });
    return tmp
  }

  // setAreaSubMenu(target:wbsModel[]){
  //   let tmp=structuredClone(target)

  //   tmp.forEach((element:wbsModel) => {
  //     if(element.profiles===undefined){
  //       element.profiles={}
  //     }
  //     if(element.profiles===null){
  //       element.profiles={}
  //     }
      
  //     element.profiles.showSubMenu=true
  //     element.profiles.subMenu=[{ menuCd: 3, menuName: 'カセットを表示' }]
  //     element.profiles.icon='area'

  //     if(this.childDataKey==='nodeObjects'){
  //       if(element.nodeObjects!==undefined){
  //         if(element.nodeObjects.length>0){
  //           element.nodeObjects=this.setAreaSubMenu(element.nodeObjects)
  //         }
  //       }
  //     }
  //     if(this.childDataKey==='children'){
  //       if(element.children!==undefined){
  //         if(element.children.length>0){
  //           element.children=this.setAreaSubMenu(element.children)
  //         }
  //       }
  //     }     
  //   });
  //   return tmp
  // }
  async getCassettes(areaId:string):Promise<wbsModel[]>{
    
    let retval: any=null

    const getFileData$ = this.backendWbsProtoApiService.GetCassettes(areaId,this.customerId,this.selectedArea.authDivision)
    let ret = await lastValueFrom(getFileData$).catch(() => {
      return retval
    })
    if (ret) {
      if(ret.data===null){
        return retval
      }
      retval= ret.data
    }
    this.isShowArea=false //カセット表示
    // this.title='ドキュメント WBS／カセット一覧' //カセット表示
    // let tmp=structuredClone(HEALTH_CHCECK_CASSETTES_ONLY)

    // カセットのprofileにサブメニューをセットする
    let canUpload =false
    let canDownload =true
    retval = this.setSubMenu(retval,canUpload,canDownload)
    return retval
  }
  
  setSubMenu(target:wbsModel[],canUpload:boolean,canDownload:boolean){
    let tmp=structuredClone(target)

    tmp.forEach((element:wbsModel) => {
      if(element.profiles===undefined){
        element.profiles={}
      }
      if(element.profiles===null){
        element.profiles={}
      }

      if(element.wbsType!==undefined){
        element.profiles.showSubMenu=false
        element.profiles.subMenu=[]
        switch (element.wbsType) {
          case 'file':
            // element.profiles={}
            // element.profiles.icon='file'
            if(canDownload){
              element.profiles.showSubMenu=true
              element.profiles.canDownload=true
              element.profiles.subMenu=[{ menuCd: 2, menuName: 'ファイルダウンロード' }]
            }  
            break
          case 'cassette':
            // element.profiles={}
            // element.profiles.icon='cassette'
            if(canUpload){
              element.profiles.showSubMenu=true
              element.profiles.canUpload=true
              element.profiles.subMenu=[{ menuCd: 1, menuName: 'ファイルアップロード' }]            
            }
            break
        }
      }

      if(this.childDataKey==='nodeObjects'){
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            element.nodeObjects=this.setSubMenu(element.nodeObjects,canUpload,canDownload)
          }
        }
      }
      if(this.childDataKey==='children'){
        if(element.children!==undefined){
          if(element.children.length>0){
            element.children=this.setSubMenu(element.children,canUpload,canDownload)
          }
        }
      }     
    });
    return tmp
  }

  async createIframe():Promise<any>{
    this.loading=true

    this.settingFileId=''

    let payload={
        "fileId": this.wbsIds,
        "wbsData":this.wbsList,
        "filter": this.filterOn,
        "canUpdateElement":this.canUpdateElement,
        "canAddTop":this.canAddTop,
        "draggableOn":this.draggableOn,
        "childDataKey":this.childDataKey,
        "showSubMenu": this.showSubMenu,
    }

    // ファイル作成(条件、紐づけデータ)
    this.settingFileId = await this.wbsService.createFile(payload)

    const url = `${this.relationSettingPath}/relation/#/wbs-view/${encodeURIComponent(this.settingFileId)}`
    this.targetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)    
  }

  ngOnDestroy(){
    this.wbsService.selectedWbsId=''
    if(this.settingFileId!==''){
      this.deleteFile()
    }    
  }
  async deleteFile(){  
    const deleteFile$ = this.wbsUiMacroApiService.deleteTempFile(this.settingFileId)
    const deleteFile = await lastValueFrom(deleteFile$).catch(() => {
      return null
    })

    return deleteFile
  }
  iframeloaded(value: any) {
    //iframeのloadが2度走る場合があるため、srcを見る
    if (value.target.src !== '') {
      this.loading = false
    }
  }  
  dialogClose(){    
    this.createWbsDialog?.close()
    this.isDialogOpen=false
  }
  openUploadDialog(){
    this.uploadFileDialog?.open()
  }
  uploadError(value:any){
    this.snackbar.error(value)
  }
  async addFile(uploadObject: any){
    if(uploadObject!==null){
      this.apiLoading = true
      let uploadTitle=uploadObject.uploadTitle
      let uploadFile=uploadObject.File
      // APIでファイルアップロード
      const formData = new FormData();
      formData.append('file', uploadFile);  //キー名、データファイル、ファイル名
      console.log(formData.get('file'));

      if(this.targetWbs===undefined) return
      let cassetteId=this.targetWbs.id

      const postret$ = this.backendWbsProtoApiService.PostFileUpload(cassetteId,formData,uploadTitle)
      let postret  = await lastValueFrom(postret$).catch(() => {
        this.apiLoading = false
      })

      // let fileId=0
      // if(postret){
      //   fileId=postret.data[0].fileId!==undefined?postret.data[0].fileId:0
      // }

      if(postret===undefined){
        this.snackbar.error("アップロードが失敗しました")
        this.apiLoading = false
        return
      }

      this.snackbar.success("ファイルがアップロードされました")
      this.apiLoading = false
      // const cassetteTree =await this.getCassettes(this.selectedArea.id)
      // this.wbsList=structuredClone(cassetteTree)
      const ret = await this.getWbs()
      this.wbsList=structuredClone(ret)   
      
      await this.createIframe()
    }
  }
  async downloadFile(selectedFile: any){
    if(this.apiLoading) return
    this.apiLoading = true

    const getFileData$ = this.backendWbsProtoApiService.GetFileDownload(selectedFile.id)
    let getret  = await lastValueFrom(getFileData$).catch(() => {
        this.apiLoading = false
    })
    if (getret) {     
      let downloadFileName=selectedFile.profiles.fileName       
      this.fd.downLoadFile(getret,getret.body.type,downloadFileName);       
    }

    this.apiLoading =false
  }
  @HostListener('window:message', ['$event']) async onMessage(msgEvent: MessageEvent) {
    // 呼び出し元確認入れる
    if (msgEvent.origin === window.location.protocol + this.relationSettingPath) {
    // if (msgEvent.origin === this.relationSettingPath) {
      //アップロードダイアログを開く
      if(msgEvent.data.selectedMenu===1){
        if(msgEvent.data.selectedData!==undefined){
          this.targetWbs=msgEvent.data.selectedData
          this.isDialogOpen=true
          // this.cassetteViewDialog?.open()
          this.uploadFileDialog?.open()
        }        
      }
      //ダウンロード
      if(msgEvent.data.selectedMenu===2){
        this.downloadFile(msgEvent.data.selectedData)
      }
      //カセットを表示
      if(msgEvent.data.selectedMenu===3){
        // this.selectedArea=msgEvent.data.selectedData
        // const ret =await this.getCassettes(msgEvent.data.selectedData.id)
        const ret = await this.getWbs()
        this.wbsList=structuredClone(ret)  
        if(ret===null){          
          this.snackbar.error('カセットがありません')
          return
        }
        this.wbsList=structuredClone(ret)        
        await this.createIframe()
      }
      //ファイルを削除
      if(msgEvent.data.selectedMenu===6){
        this.targetWbs= msgEvent.data.selectedData
        this.yesNoDialog?.open()
      }
    }   
  }
  gotoArea(){
    this.router.navigate([ `/select-area`]);
    // this.router.navigate([ `/select-area-v1`]);
  }
  gotoCassetteList(){
    this.router.navigate(["/create-cassette"],{ queryParams: { id: this.selectedAreaId} });
  }
  async buttonClick(id: string){
    if(id==='del_file'){
      if(this.targetWbs!==undefined){  
        if(this.apiLoading) return
        this.apiLoading=true
        //カセット削除処理
        let delTree=[structuredClone(this.targetWbs)]          
        let delData=this.wbsService.getDelData(delTree)

        if(delTree.length>0){
          const delCassettes$ = this.backendWbsProtoApiService.DeleteCassetteMany(delData)
          const delCassettes = await lastValueFrom(delCassettes$).catch(() => {
            this.apiLoading=false
            return null
          })
          if(delCassettes){
            this.yesNoDialog?.close()
            this.snackbar.success('ファイルを削除しました')
            this.apiLoading=false
            this.initialize() 
          }    
        }
      }
    }
    this.apiLoading=false
  }
  dialogCancel(){
    this.yesNoDialog?.close()
  }
}
