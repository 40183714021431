/**
 * エンコード
 * @param paramVal
 * @returns
 */
export const EncodeParam = (paramVal: string) => {
  try {
    const base = 36
    const firstKeyVal = createRandomVal()
    const secondKeyVal = createRandomVal()
    const firstKey = firstKeyVal.toString(base)
    const secondKey = secondKeyVal.toString(base)
    if (!isNullOrEmpty(paramVal)) {
      let i = 0
      let mainVal = ''
      let calibrationVal = 0
      for (const char of paramVal) {
        let codePoint = char.codePointAt(0)
        if (codePoint !== undefined) {
          calibrationVal += codePoint
          if (i % 2 === 0) {
            codePoint += 100000 + i
          } else {
            codePoint += 100 + i
          }
          i += 1
          mainVal += (firstKeyVal + codePoint).toString(base)
        }
      }
      let calibrationKey = (calibrationVal + firstKeyVal + secondKeyVal).toString(base)
      return [firstKey, mainVal, calibrationKey, secondKey].join('_')
    }
  } catch (error) {
    // console.error(`EncodeParam() :>> `, error)
  }

  return ''
}
/**
 * デコード
 * @param encodeVal
 * @returns
 */
export const DecodeParam = (encodeVal: string) => {
  try {
    const base = 36
    const requiredElemCnt = 4
    if (!isNullOrEmpty(encodeVal)) {
      const elements = encodeVal.split('_')
      if (elements.length === requiredElemCnt) {
        let [firstKey, mainVal, calibrationKey, secondKey] = elements
        const firstKeyVal = parseInt(firstKey, base)
        const secondKeyVal = parseInt(secondKey, base)
        const calibrationNumber = parseInt(calibrationKey, base)
        const calibrationVal = calibrationNumber - firstKeyVal - secondKeyVal
        const valElements = mainVal.match(/.{4}/g)
        if (valElements) {
          let checkPoint = 0
          let paramVal = ''
          for (let i = 0; i < valElements.length; i++) {
            const elem = valElements[i]
            let codePoint = parseInt(elem, base) - firstKeyVal
            if (i % 2 === 0) {
              codePoint = codePoint - 100000 - i
            } else {
              codePoint = codePoint - 100 - i
            }
            checkPoint += codePoint

            const char = String.fromCodePoint(codePoint)
            paramVal += char
          }
          if (calibrationVal === checkPoint) {
            return paramVal
          }
        }
      }
    }
  } catch (error) {
    // console.error(`DecodeParam() :>> `, error)
  }
  return ''
}

export const createRandomVal = (max: number = 1000000) => {
  const randomVal = Math.random()
  const ret = randomVal < 0.1 ? randomVal + 0.1 : randomVal
  return Math.floor(ret * max)
}

export const isNullOrEmpty = (obj: any): boolean => {
  return obj === undefined || obj === null || obj === ''
}
