import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
// import { ApprovalGridBody, ColumnRule, DepartmentPlanGridBodyRow, ResultInfo, ResultInfoSingle, SimulationByCassetteGridBody, SimulationByCassetteGridHeader, SimulationByCassetteHeaderData, Status, TagPurpose } from 'src/app/interface'
import { ResultInfo, ResultInfoSingle} from 'src/app/interfaces'
import { MatrixGridData, MatrixHeader } from 'src/app/interfaces/matrix-tree-grid'
// import { ProgressGridBodyRow,ProgressHeaderData } from 'src/app/interfaces/progress'
import { environment } from 'src/environments/environment'
// import { ApiErrorHandlingService } from '../api-error-handling.service'
// import { CalendarModel, StorePatternDetail, StorePatternModel } from './mdplanning.interface'
import { catchError } from 'rxjs/operators';
import { CellRequestModel, WBSCellModel, WBSModel, relationModel } from '../interfaces/wbs.model'

@Injectable({
  providedIn: 'root'
})
export class WBSManagementApiService {
  private path = environment.wbsManagementApi
  constructor(
    private http: HttpClient
    // private apiErrorHandlingService: ApiErrorHandlingService
  ) { }

  /**
  *全Wbs取得
  * @param wbsId
  * @param jsonType
  */
  GetAllWbs(): Observable<ResultInfo<WBSCellModel>> {
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs
    let requestUrl = `${this.path}/wbs`    
    return this.http.get<ResultInfo<WBSModel>>(requestUrl)    
  }

  /**
  *Wbs取得
  * @param wbsId
  * @param jsonType
  */
  GetWbs(wbsId: string, jsonType: number): Observable<ResultInfo<WBSModel>> {
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/62a20303-be8e-432c-84e7-36fdfa6bebd6?jsonType=0
    let requestUrl = `${this.path}/wbs/${wbsId}?jsonType=${jsonType}`    
    return this.http.get<ResultInfo<WBSModel>>(requestUrl)    
  }

  /**
  *Wbs セル登録   ★これは使わなくなる UIマクロの方のセルとプロファイルを一緒に登録できるAPIを使う
  * @param payload
  */
  PostWbsCell(payload: CellRequestModel): Observable<ResultInfoSingle<string>> {
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/cells
    let requestUrl = `${this.path}/wbs/cells`    
    return this.http.post<ResultInfoSingle<string>>(requestUrl, payload)
  }
  /**
  *Wbs セル更新
  * @param cellId
  * @param payload
  */
  PutWbsCell(cellId: string,payload: CellRequestModel): Observable<ResultInfoSingle<string>> {
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/cells/
    let requestUrl = `${this.path}/wbs/cells/${cellId}`    
    return this.http.put<ResultInfoSingle<string>>(requestUrl, payload)
  }
  /**
  *Wbs セルプロファイル登録
  * @param cellId
  * @param payload
  */
  PostWbsCellProfiles(cellId:string, payload: string): Observable<ResultInfo<string>> {
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/cellProfiles/{cellId}
    let requestUrl = `${this.path}/cellProfiles/${cellId}`
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<ResultInfo<string>>(requestUrl, payload, httpOptions)
  }
  /**
  *Wbs セルプロファイル取得
  * @param cellId
  */
  GetWbsCellProfiles(cellId: string): Observable<ResultInfoSingle<string>> {
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/cellProfiles/{cellId}
    let requestUrl = `${this.path}/cellProfiles/${cellId}`
    return this.http.get<ResultInfoSingle<string>>(requestUrl)
  }
  /**
  *Wbs セル紐づけ登録(複数)
  * @param payload
  */
  PostWbsRelations(payload: relationModel[]): Observable<ResultInfo<WBSModel>> {    
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/many
    let requestUrl = `${this.path}/wbs/many`    
    return this.http.post<ResultInfo<WBSModel>>(requestUrl,payload)
  }

  /**
  *Wbs セル紐づけ更新  
  * @param cellRelationId
  * @param payload
  */
  PutWbsRelations(cellRelationId:string,payload: relationModel): Observable<ResultInfo<WBSModel>> {    
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/cellRelations/43b760e5-5dd8-43a8-a82b-504696d99847
    let requestUrl = `${this.path}/wbs/cellRelations/${cellRelationId}`    
    return this.http.put<ResultInfo<WBSModel>>(requestUrl, payload)
  }

  
  /**
  *Wbs セル紐づけ登録(複数)
  * @param payload
  */
  PostWbsRelationsUpsert(payload: relationModel[]): Observable<ResultInfo<WBSModel>> {    
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/cellRelations/upsert/many
    let requestUrl = `${this.path}/wbs/cellRelations/upsert/many`    
    return this.http.post<ResultInfo<WBSModel>>(requestUrl,payload)
  }

  /**
  *Wbs セル登録後、WBSのスナップショット更新
  * @param wbsId
  * @param jsonType
  */
  PostWbsSnapshot(wbsId: string, jsonType: number): Observable<ResultInfo<WBSModel>> {
    // ★★ここはGetからPOSTに変わる予定
    // http://me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com/api/wbs/create/wbsJson/d09fce10-07a7-416e-bf0d-fc20c017d151?jsonType=1
    let requestUrl = `${this.path}/wbs/create/wbsJson/${wbsId}?jsonType=${jsonType}`    
    return this.http.get<ResultInfo<WBSModel>>(requestUrl)
  }
}
