import { DialogParams } from "../../frontEndSetting";

export const messageList:DialogParams[] = [
  {
    messageCd: 'confirmRun',
    title: '',
    message: '実行しますか？',
    buttonData: [
        {
            key: 'cancel',
            id: '0',
            name: 'cancel-button',
            label:'キャンセル',
            color: 'cancel',
            disable: false,
            hidden: false,
            action:'cancel'
        },
        {
            key: 'register',
            id: '1',
            name: 'save-button',
            label:'はい',
            color: 'primary',
            disable: false,
            hidden: false,
            action:'save'
        },
    ],
    width:'100%',
    maxHeight:'100%'
  },
  {
    messageCd: 'apiExceuted',
    title: '',
    message: '処理を実行しました',
    buttonData: [
        {
            key: 'ok',
            id: '0',
            name: 'ok-button',
            label:'閉じる',
            color: 'cancel',
            disable: false,
            hidden: false,
            action:'ok'
        }
    ],
    width:'100%',
    maxHeight:'100%'
  },
  {
    messageCd: 'apiFailed',
    title: '',
    message: 'システムエラーが発生しました',
    buttonData: [
        {
            key: 'ok',
            id: '0',
            name: 'ok-button',
            label:'閉じる',
            color: 'cancel',
            disable: false,
            hidden: false,
            action:'ok'
        }
    ],
    width:'100%',
    maxHeight:'100%'
  },
  {
    messageCd: 'unMovable',
    title: '',
    message: '移動できません',
    buttonData: [
        {
            key: 'ok',
            id: '0',
            name: 'ok-button',
            label:'閉じる',
            color: 'cancel',
            disable: false,
            hidden: false,
            action:'ok'
        }
    ],
    width:'100%',
    maxHeight:'100%'
  }     
]

