<igx-dialog #modaldialog [closeOnOutsideSelect]="false" (closed)="_closed()">
  <!-- <div class="c-modal c-modal--480"> -->
  <div class="c-modal c-modal--800">
    <div class="c-modal__content">
      <div *ngIf="isShowCloseButton" class="c-modal__close">
        <a class="js-modal-close" (click)="modaldialog.close()" data-id=""
          ><i class="c-icon-close"></i
        ></a>
      </div>
      <div class="c-modal__head h-margin-b24">
        <p class="c-text-3 c-text-center c-text-bold">{{ headerMessage }}</p>
      </div>
      <div class="c-modal__body">
        <p class="c-text-3 c-text-center">{{ message }}</p>
        <ng-content></ng-content>
      </div>
      <div class="l-flex l-flex--center h-margin-t24">
        <div class="c-btn c-btn--white c-btn--145">
          <a class="js-modal-close" (click)="_clickNoButton()" data-id=""
            >{{noButtonCaption}}</a
          >
        </div>
        <div class="c-btn c-btn--pink c-btn--145 h-margin-l16">
          <a class="js-modal-close" (click)="_clickYesButton()" data-id=""
            >{{yesButtonCaption}}</a
          >
        </div>
      </div>
    </div>
  </div>
</igx-dialog>
