import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class IsWbsService {
  constructor() {}
  /**
   * データからWBSのデータを取得する。
   * @param data - data
   * @param getKey - objectの場合はどのkeyから取得するかを設定。
   * @returns
   */
  convertData(data: any[] | { [key: string]: any } | string, getKey: string = 'wbsData') {
    // typeがarrayだったらそのまま返す
    if (Array.isArray(data)) {
      return data
    }

    // typeがstringだったら、objectに変換する
    if (typeof data === 'string') {
      const parseData = JSON.parse(data) as { [key: string]: any }
      // parseDataからwbsDataを取得する
      return parseData[getKey]
    }

    // typeがobjectたったら、wbsDataを取得する
    if (typeof data === 'object') {
      return data[getKey]
    }
  }

  /**
   *  WBSのデータを取得する。
   * @param data
   * @param id
   * @param idKey
   * @param childDataKey
   * @returns
   */
  findById(
    data: any[],
    id: string,
    idKey: string = 'id',
    childDataKey: string = 'nodeObjects'
  ): any | undefined {
    let result = null
    for (let i = 0; i < data.length; i++) {
      const item = data[i]

      if (item[idKey] === id) {
        return item
      }
      if (item[childDataKey] && item[childDataKey].length > 0) {
        result = this.findById(item[childDataKey], id, idKey, childDataKey)
        if (result) {
          break
        }
      }
    }
    return result
  }

  findByIds(
    data: any[],
    ids: string[],
    idKey: string = 'id',
    childDataKey: string = 'nodeObjects'
  ): any[] {
    return ids.map((id) => this.findById(data, id, idKey, childDataKey))
  }

  /**
   *  dummy nodeを追加する
   * @param data
   * @param childDataKey - 子要素のkey
   * @param levelKey - levelのkey
   * @returns
   */
  addDummyNodes(
    data: any[],
    childDataKey: string = 'nodeObjects',
    levelKey: string = 'levelCd',
    primaryKey: string = 'frontEndId'
  ) {
    if (!Array.isArray(data)) {
      data = [data]
    }
    data.forEach((rootNode) =>
      this.insertDummy(
        rootNode,
        rootNode.profiles[levelKey] + 1,
        childDataKey,
        levelKey,
        primaryKey
      )
    )
    return data
  }

  /**
   * dummy nodeを削除する
   * @param data
   * @param childDataKey
   * @returns
   **/
  removeDummyNodes(
    data: any[],
    childDataKey: string = 'nodeObjects',
    primaryKey: string = 'frontEndId'
  ) {
    if (!Array.isArray(data)) {
      data = [data]
    }
    return data.map((rootNode: any) => {
      rootNode[childDataKey] = this.removeDummies(rootNode, childDataKey, primaryKey)
      return rootNode
    })
  }

  private insertDummy(
    node: any,
    expectedLevel: number,
    childDataKey: string,
    levelKey: string,
    primaryKey: string
  ) {
    if (!node[childDataKey]) {
      node[childDataKey] = []
    }
    for (let i = 0; i < node[childDataKey].length; i++) {
      let child = node[childDataKey][i]
      let childLevel = child.profiles[levelKey]

      if (childLevel === expectedLevel) {
        this.insertDummy(child, expectedLevel + 1, childDataKey, levelKey, primaryKey)
      } else if (childLevel > expectedLevel) {
        let dummyNode = {
          [primaryKey]: 'dummy-' + crypto.randomUUID(),
        } as any
        //create profile obj
        const profileObj = {} as { [key: string]: string | number }
        profileObj[levelKey] = expectedLevel
        dummyNode['profiles'] = profileObj
        dummyNode[childDataKey] = [child]
        node[childDataKey][i] = dummyNode
        this.insertDummy(dummyNode, expectedLevel + 1, childDataKey, levelKey, primaryKey)
      }
    }
  }
  /**
   *  Dummy nodeを削除する
   * @param node
   * @param childDataKey
   * @param primaryKey
   * @returns
   */
  private removeDummies(node: any, childDataKey: string, primaryKey: string) {
    if (!node[childDataKey]) {
      return []
    }

    let newChildren = [] as any[]
    node[childDataKey].forEach((child: any) => {
      if (child[primaryKey]?.startsWith('dummy-')) {
        // Flatten the structure by adding grand children directly to the parent
        newChildren.push(...this.removeDummies(child, childDataKey, primaryKey))
      } else {
        // Recursive call to process child nodes
        child[childDataKey] = this.removeDummies(child, childDataKey, primaryKey)
        newChildren.push(child)
      }
    })
    return newChildren
  }

  addLevelCd(data: any[], structures: any[], levelKey = 'levelNo') {
    const clonedData = structuredClone(data)

    if (Array.isArray(clonedData)) {
      clonedData.forEach((rootNode) => this.processNode(rootNode, structures, levelKey))
    } else {
      this.processNode(clonedData, structures, levelKey)
    }

    return clonedData
  }

  findStructureIndex(structures: any[], structureId: string) {
    return structures.findIndex((structure) => structure.id === structureId)
  }

  private processNode(node: any, structures: any[], levelKey: string) {
    if (node.profiles && node.profiles.structureId) {
      const index = this.findStructureIndex(structures, node.profiles.structureId)
      if (index !== -1) {
        node.profiles[levelKey] = index
      }
    }

    // Process child nodes
    if (node.nodeObjects && node.nodeObjects.length > 0) {
      node.nodeObjects.forEach((childNode: any) =>
        this.processNode(childNode, structures, levelKey)
      )
    }
  }

  addOrderNumbers(data: any[], childDataKey = 'nodeObjects') {
    if (!Array.isArray(data)) {
      data = [data]
    }

    data.forEach((rootNode, index) => {
      rootNode.profiles.orderNo = index
      this.setOrderNumbers(rootNode, 0, childDataKey)
    })

    return data.length === 1 ? data[0] : data
  }
  private setOrderNumbers(node: any, startOrder = 0, childDataKey: string) {
    if (!node[childDataKey]) {
      return
    }

    node[childDataKey].forEach((child: any, index: number) => {
      child.profiles.orderNo = startOrder + index
      this.setOrderNumbers(child, 0, childDataKey) // 子ノードのオーダーは各階層ごとにリセット
    })
  }

  addFrontEndIds(data: any[], childDataKey: string = 'nodeObjects') {
    if(data===undefined){
      data =[]
    }
    if (!Array.isArray(data)) {
      data = [data]
    }

    data.forEach((rootNode) => this.addIdToNode(rootNode, childDataKey))

    return data.length === 1 ? data[0] : data
  }

  private addIdToNode(node: any, childDataKey: string) {
    node.frontEndId = crypto.randomUUID()
    if (node[childDataKey] && node[childDataKey].length > 0) {
      node[childDataKey].forEach((child: any) => this.addIdToNode(child, childDataKey))
    }
  }

  public flattenHierarchy(
    data: any[] | any,
    removeChildren = true,
    childDataKey: string = 'nodeObjects'
  ) {
    const flatArray = [] as any[]

    if (!Array.isArray(data)) {
      data = [data]
    }

    data.forEach((rootNode: any) =>
      this.flattenNode(rootNode, flatArray, childDataKey, removeChildren)
    )
    return flatArray
  }

  private flattenNode(node: any, flatArray: any, childDataKey: string, removeChildren: boolean) {
    // 子ノードを退避
    let children = node[childDataKey]

    if (removeChildren) {
      // 子ノードを空の配列に設定
      node[childDataKey] = []
    }

    // ノードを配列に追加
    flatArray.push(node)

    // 退避した子ノードを処理
    if (children && children.length > 0) {
      children.forEach((child: any) =>
        this.flattenNode(child, flatArray, childDataKey, removeChildren)
      )
    }
  }

  removeNodesById(data: any, idsToRemove: string[], childDataKey: string = 'nodeObjects', primaryKey: string = 'frontEndId') {
    if (!Array.isArray(data)) {
      data = [data];
    }
  
    const removeNodes = (node: any) => {
      if (node[childDataKey] && node[childDataKey].length > 0) {
        node[childDataKey] = node[childDataKey].filter((child: any) => {
          // 子ノードが削除対象の場合、その子ノードを削除
          if (idsToRemove.includes(child[primaryKey])) {
            return false;
          }
          // さらに、子ノードの子ノードに対しても同じ処理を再帰的に行う
          removeNodes(child);
          return true;
        });
      }
    };
  
    // ルートレベルのノードに対しても削除処理を行う
    data.forEach(removeNodes);
  
    // 削除対象のIDを持つルートレベルのノードをフィルタリング
    return data.filter((node: any) => !idsToRemove.includes(node[primaryKey]));
  }
  

  removeAllDummiesIfParentIsDummy(
    data: any[],
    targetId: string,
    primaryKey: string = 'frontEndId'
  ) {
    function isParentDummy(node: any, id: string) {
      if (node[primaryKey] === id) {
        return true
      }
      if (node.nodeObjects) {
        return node.nodeObjects.some((child: any) => isParentDummy(child, id))
      }
      return false
    }

    // data から dummy ノードを再帰的に削除する
    function removeDummies(nodes: any[]) {
      return nodes.filter((node) => {
        if (node?.[primaryKey]?.startsWith('dummy') && isParentDummy(node, targetId)) {
          return false // 対象の親が dummy であれば削除
        }

        if (node.nodeObjects) {
          node.nodeObjects = removeDummies(node.nodeObjects)
        }

        return true
      })
    }

    return removeDummies(data)
  }

  removeInnerMostDummyChain(
    data: any[],
    targetId: string,
    childDataKey: string = 'nodeObjects',
    primaryKey: string = 'frontEndId'
  ) {
    function removeDummyChain(node: any, parentId: string) {
      if (!node[childDataKey] || node[childDataKey].length === 0) {
        return false
      }

      node[childDataKey] = node[childDataKey].filter((child: any) => {
        if (child[primaryKey] === targetId) {
          // return !parentId.startsWith('dummy') // 親が 'dummy' でなければ残す
          return !parentId.startsWith('dummy') // 親が 'dummy' でなければ残す
        }
        return removeDummyChain(child, node[primaryKey]) // 子ノードで再帰的に検索
      })

      return node[childDataKey].length > 0 || !node?.[primaryKey]?.startsWith('dummy')
    }

    return data.filter((node) => removeDummyChain(node, ''))
  }

  orgChartDataInit(
    organizationChartData: any[],
    structures: any[],
    childDataKey: string = 'nodeObjects',
    levelKey: string = 'levelCd',
    primaryKey: string = 'frontEndId',
    addLevel: boolean = true,
    addFrontEndIds = true,
    addDummyNodes = true
  ): any[] {
    if (addLevel) {
      organizationChartData = this.addLevelCd(organizationChartData, structures, levelKey)
    }
    if (addFrontEndIds) {
      organizationChartData = this.addFrontEndIds(organizationChartData, childDataKey)
    }
    if (addDummyNodes) {
      organizationChartData = this.addDummyNodes(
        organizationChartData,
        childDataKey,
        levelKey,
        primaryKey
      )
    }
    return organizationChartData
  }
  addChild(objects: any[], id: string | null, child: any, childDataKey: string= 'nodeObjects'): any {
    for (let obj of objects) {
        if (obj.id === id) {
            if(obj[childDataKey]===undefined){
                obj[childDataKey]=[child]
            }
            else{
                obj[childDataKey].push(child)
            }                
            return objects
        } else if (obj[childDataKey]!==undefined)
        {
            if(obj[childDataKey].length > 0){
                let result = this.addChild(obj[childDataKey], id, child, childDataKey)
                if (result !== null) {
                    obj.children = result
                    return objects
                }
            }
        }
    }
    return null
  }
}
