import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router, Routes, Route } from '@angular/router'
import { filter } from 'rxjs/internal/operators/filter'
// import { AuthService } from 'src/app/auth/auth.service'
// import { ActionKey, ViewKey } from 'src/app/interface/auth'
import { SideNavService } from 'src/app/services/side-nav.service'

@Component({
  selector: 'app-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.scss', './nav.scss'],
  animations: [
    trigger('navOpen', [
      state('close', style({
        transform: 'translate(-100%, 0px)'
      })),
      state('open', style({
        transform: 'translate(0%, 0px)'
      })),
      state('first', style({
        transform: 'translate(-100%, 0px)'
      })),
      transition('close => open', [
        animate('0.2s')
      ]),
      transition('open => close', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class SideNavMenuComponent implements OnInit {
  navItems: Routes
  currentMenuParent = ''
  currentMenuChild = ''
  helpOpen = false
  firstLoad = true
  breakPointWide = 1193

  constructor(
    private router: Router,
    private sideNavService: SideNavService,
    // private authService: AuthService
  ) {
    this.navItems = JSON.parse(JSON.stringify(router.config))

  }

  ngOnInit(): void {
    this.setCurrent(this.router.url)
    this.firstLoad = false
  }

  get disp(): string {
    if (this.firstLoad) {
      // モバイル幅での初期表示時に開かないように
      return 'first'
    }
    return this.sideNavService.NavOpen ? 'open' : 'close'
  }

  // 一旦対応するが、要見直し
  // 特殊処理＞MDカセット詳細画面の場合、「MD実績」を光らせる
  // 違うやり方もうあるけど、一旦最小限に修正しておく
  checkCurrentView(item: Route, child: Route): boolean {
    const mdresult = 'mdresult'
    const mdCassetteDetail = 'md-cassette-detail'
    const parentPath = item.path?.split('/')[0]
    const chhildPath = child.path?.split('/')[1]
    if (this.currentMenuParent === parentPath && (!this.currentMenuChild || this.currentMenuChild === chhildPath)) {
      return true
    } else if (mdresult === item.path && child.path === '' && this.currentMenuChild === mdCassetteDetail) {
      return true
    }
    return false
  }

  isActiveMenu(item: Route): boolean {
    const currentView = this.currentMenuParent
    if (this.currentMenuParent === item.path) {
      return true
    }

    return false
  }

  setCurrent(url: string): void {
    const list = url.split('/')
    this.currentMenuParent = this.exceptParam(list[1])
    this.currentMenuChild = list[2] ? this.exceptParam(list[2]) : ''
    this.navItems.forEach(navItem => {
      if (navItem.data) {
        const parentPath = navItem.path?.split('/')[0]
        if (parentPath === this.currentMenuParent && navItem.children) {
          navItem.data['isOpen'] = true
        }
        else {
          navItem.data['isOpen'] = false
        }
      }
    })
  }

  clickLink(value:any): void {
    // リンククリック時にメニューを閉じる
    if (window.innerWidth < this.breakPointWide) {
      this.sideNavService.setNavOpen(false)
    }
    this.setCurrent('/' + value)
  }

  exceptParam(path: string): string {
    return path.split(';')[0]
  }

}
