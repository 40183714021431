<div class="l-main__contents">
    <div id="wrapper">
      <div class="c-text-1 h-margin-b24">{{title}}</div>
      <div class="grid-area">
        <div class ="loading" *ngIf="loading">
            <app-loding-circle></app-loding-circle>
            </div>
            <iframe
              *ngIf="targetUrl!==undefined&&targetUrl!==''"
              id="iframe"
              [src]="targetUrl"
              class="iframe-area"
              (load)="iframeloaded($event)"
              frameborder="0"
              style="border: none"
            ></iframe>
      </div>
    </div>
  </div>
<app-footer id="pageFooter" style="margin-top: auto;">
    <li class="c-bottom-nav__item button-margin" *ngFor="let button of buttonData">
      <app-button
        [disable]="button.disable"
        [hidden]="button.hidden || false"
        [color]="button.color"
        (click)="buttonClick(button.key)"
      >
        {{ button.name }}
      </app-button>
    </li>
</app-footer> 
<app-snackbar #snackbar [autoHide]="true"></app-snackbar>
<app-yes-no-dialog
  #dialog
  (clickNoButton)="dialogCancel()"
  (clickYesButton)="clickRun()"
  [headerMessage]="dialogParams.title"
  [message]="dialogParams.message"
  [buttonId]="dialogParams.buttonId"
  [isShowCloseButton]="false"
>
  <div class ="loading" *ngIf="apiLoading">
    <app-loding-circle></app-loding-circle>
  </div>
</app-yes-no-dialog>