import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { ActionKey, ButtonSetting, DialogParams } from '../frontEndSetting';
import { IsIgxButtonComponent } from '../is-igx-button/is-igx-button.component';
import { IsIgxDialogComponent } from '../is-igx-dialog/is-igx-dialog.component';
import { CommonModule } from '@angular/common';
import { messageList } from './static/message-data';

@Component({
  selector: 'is-igx-dialog-message',  
  standalone: true,
  imports: [CommonModule, IsIgxDialogComponent, IsIgxButtonComponent],
  templateUrl: './is-igx-dialog-message.component.html',
  styleUrls: ['./is-igx-dialog-message.component.scss'],
})
export class IsIgxDialogMessageComponent implements OnInit {
  @ViewChild('modalDialog', { static: true }) public modalDialog: IsIgxDialogComponent | undefined
  @Input() messageCd:string='';
  @Input() dialogParam:DialogParams| undefined;
  @Output() click = new EventEmitter();
  @Output() closed = new EventEmitter();

  dialogWidth='100%'
  dialogMaxHeight='100%'

  constructor() { }

  ngOnInit(): void { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['messageCd']) {
      if(this.messageCd.length>0){
        let messageData = messageList.filter(x=>x.messageCd===this.messageCd)[0]
        if(messageData?.width){
          this.dialogWidth=messageData?.width
        }
        if(messageData?.maxHeight){
          this.dialogMaxHeight=messageData?.maxHeight
        }
        this.dialogParam=messageData
      }
    }
  }
  open() {
    if (this.modalDialog) this.modalDialog.open();
  }
  close() {
    if (this.modalDialog) this.modalDialog.close();
  }
  // _clickButton(key:ActionKey) {
  _clickButton(action:any) {
    this.click.emit(action);
  }
  /**
   * 閉じたあとのイベント
   */
  _closed() {
    this.closed.emit()
  }

}
