import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, Inject, Injectable, Input, OnInit, Output } from '@angular/core'
import { SideNavService } from 'src/app/services/side-nav.service'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('navOpen', [
      state('close', style({
        width: 'calc(100% - 0px)',
        left: '0'
      })),
      transition('close => *', [
        animate('0.2s')
      ]),
      transition('* => close', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class footerComponent implements OnInit {

  @Output() clickButton = new EventEmitter();
  @Input() text = '';
  @Input() helpText = ''
  @Input() buttonId = ''
  @Input() buttonText = ''
  constructor(
    private sideNavService: SideNavService
  ) { }

  get navDisp(): boolean {
    return this.sideNavService.NavOpen
  }

  ngOnInit(): void {
  }

  _clickButton() {
    this.clickButton.emit(this.buttonId);
  }


}
