import { Component, Input, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IgxDialogComponent, IgxDialogModule } from '@infragistics/igniteui-angular'
@Component({
  selector: 'is-igx-dialog',
  standalone: true,
  imports: [CommonModule, IgxDialogModule],
  templateUrl: './is-igx-dialog.component.html',
  styleUrl: './is-igx-dialog.component.css',
})
export class IsIgxDialogComponent {
  @ViewChild('dialog', { static: false })
  public dialog: IgxDialogComponent | undefined
  @Input() width: any = 500
  @Input() maxHeight: any = 500
  constructor() {}

  ngOnInit(): void {}

  open() {
    if(Number(this.width)){
      this.width=this.width + 'px'
    }
    this.dialog?.open()
  }
  close() {
    this.dialog?.close()
  }
}
