import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ResultInfo, ResultInfoSingle} from 'src/app/interfaces'
import { environment } from 'src/environments/environment'
import { wbsModel } from '../interfaces/wbs.model'
import { switchMap } from 'rxjs'

interface FileCreateRequestBody {
  "fileName": string,
  "distributionPlaceCd": string,
  "deliveryDateFrom": string,
  "deliveryDateTo": string,
  "replaceBaseDate": string
}

interface WbsItem {
  "userSource": string,
  "wbsType": string,
  "parentId": string,
  "id": string,
  "name": string,
  "nodeObjects": WbsItem[]
}

@Injectable({
  providedIn: 'root'
})
export class ShipemntRangeApiServiceApiService {
  private path = environment.ShipmentRangeApi
  constructor(
    private http: HttpClient
  ) { }





  /**
  *ファイル取得
  * @param distributionPlaceCd
  */
  FileList(distributionPlaceCd: string): Observable<ResultInfo<{key:string,eTag:string}[]>> {
    let requestUrl = `${this.path}api/CreateTestData/fileList/?distributionPlaceCd=${distributionPlaceCd}`
    return this.http.get<ResultInfo<{key:string,eTag:string}[]>>(requestUrl)
  }


  FileCreate(fileCreateObj:FileCreateRequestBody): Observable<ResultInfoSingle<string>> {
    let requestUrl = `${this.path}api/CreateTestData/fileCreate`
    return this.http.post<ResultInfoSingle<string>>(requestUrl,fileCreateObj)
  }

  /**
   * 出荷範囲作成(旧)
   * @param distributionPlaceCd
   * @param fileName
   * @constructor
   */
  ShipmentRangeCreate(distributionPlaceCd:string,　fileName:string[]): Observable<ResultInfo<WbsItem>> {
    let requestUrl = `${this.path}api/CreateTestData/CreateDummyShipmentRange?distributionPlaceCd=${distributionPlaceCd}`
    return this.http.post<ResultInfo<WbsItem>>(requestUrl,fileName)
  }

  /**
   * ファイル選択→出荷範囲作成
   * @param distributionPlaceCd
   * @param fileName
   * @constructor
   */
  ShipmentRangeTestModeCreate(distributionPlaceCd:string,　fileName:string[]): Observable<ResultInfo<WbsItem>> {
    // ファイル選択
    const fileCreateRequestUrl = `${this.path}api/shipmentRange/fileTestMode?distributionPlaceCd=${distributionPlaceCd}`;

    // 最初のAPIリクエストを送信し、結果を待ってから次のリクエストを送信する
    return this.http.post<ResultInfo<string>>(fileCreateRequestUrl, fileName).pipe(
      switchMap(() => {
        // 出荷範囲作成
        const requestUrl = `${this.path}api/CreateTestData/GetShipmentRangeFileTestMode?distributionPlaceCd=${distributionPlaceCd}`;
        return this.http.get<ResultInfo<WbsItem>>(requestUrl);
      })
    );
  }

}
