import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { AuthService } from 'src/app/services/auth.service';
import { selectAreaData } from '../localdata';
import { environment } from 'src/environments/environment';
import { WbsService } from 'src/app/services/wbs.service';

@Component({
  selector: 'app-select-area-v2',
  templateUrl: './select-area-v2.component.html',
  styleUrls: ['./select-area-v2.component.scss']
})
// V2はライブラリに移行版 設定ファイルver.1(1設定ファイルで動作版) 動作確認終了したら消す
export class SelectAreaV2Component implements OnInit {
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  public appCd=environment.appCd
  public frontendCd="selectArea"
  public title = 'ドキュメント 管理／エリア選択'
  
  public loading=false
  public customerId=''
  
  //WBSライブラリ用
  public mappingData: any = {}
  public mappingDataString=''

  constructor(
    private wbsService:WbsService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.customerId=this.authService.Account?.customerId??''
    
    this.initialize() 
  }
  async initialize(){
    this.loading=true    
    try {
        // 設定ファイル
        // this.mappingData = structuredClone(selectAreaData)  
        let settings = await this.wbsService.getFrontendSettings(this.appCd, this.frontendCd)
        if(settings!==null){
          settings.actions.GetAreas.apiInfo.urlObject.customerId = this.customerId     
          this.mappingData=settings
        } 
        this.loading=false
    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }  
  clickSubMenu(arg: any){
    //カセット一覧を表示
    if(arg.selectedMenu===3){
      this.router.navigate([ `/wbs-viewer/${arg.selectedData.id}/1`]);    //0:閲覧のみ、1:追加可   
    }
    //カセットを作成
    if(arg.selectedMenu===4){
      this.router.navigate(["/create-cassette"],{ queryParams: { id: arg.selectedData.id } });
    }
    //アップロードしたアイテムの一覧を表示
    if(arg.selectedMenu===7){
      this.router.navigate([ `/select-wbs-item/${arg.selectedData.id}`]);    //0:閲覧のみ、1:追加可   
    }
  }
}
