import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { wbsModel } from 'src/app/interfaces/wbs.model';
import { Router } from '@angular/router';
import { YesNoDialogComponent } from 'src/app/components/dialogs/yes-no-dialog/yes-no-dialog.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { IgxTreeGridComponent } from '@infragistics/igniteui-angular';
import { WbsService } from 'src/app/services/wbs.service';
import { WbsUiMacroApiService } from 'src/app/api/wbs-ui-macro-api';
import { UploadDialogComponent } from 'src/app/components/dialogs/upload-dialog/upload-dialog.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BackendWbsProtoApiService } from 'src/app/api/backend-wbs-proto-api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-select-area-v1',
  templateUrl: './select-area-v1.component.html',
  styleUrls: ['./select-area-v1.component.scss']
})
// V1はiframe版 動作確認終了したら消す
export class SelectAreaV1Component implements OnInit {
  @ViewChild('createWbsDialog') createWbsDialog?: YesNoDialogComponent
  @ViewChild('yesNoDialog') yesNoDialog?: UploadDialogComponent;  
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  @ViewChild('wbsGrid', { read: IgxTreeGridComponent, static: true }) public wbsGrid!: IgxTreeGridComponent
  @ViewChild('cassetteViewDialog') cassetteViewDialog?: YesNoDialogComponent

  public title = 'ドキュメント 管理／エリア選択'
  public apiLoading=false
  public loading=false
  public isDialogOpen=false
  public isShowArea=true  //true=>エリア表示、false=>カセット表示
  public customerId=''
  public organizaionId=''
  
  public wbsIds:string[]=[]
  public wbsList:wbsModel[]=[]
  public canUpdateElement:boolean=false
  public canAddTop:boolean=false
  public filterOn:boolean=false  
  public showSubMenu:boolean=true
  public draggableOn:boolean=false
  public childDataKey:string='nodeObjects'
  public selectedArea:any
  public selectedAreaId:string=''

  public targetUrl: SafeUrl | undefined
  private relationSettingPath = environment.relationSetting
  private settingFileId:string=''
  
  public dialogParams =
  {
    messageCd: 'cassetteView',
    title: '',
    message: '',
    buttonId: '',
    yesButtonCaption:'アップロード',
    noButtonCaption:'閉じる'
  }
  public dialogParams_del_file =
  {
    messageCd: 'del_file',
    title: 'ファイル削除',
    message: '実行しますか？',
    buttonId: 'del_file',
    yesButtonCaption:'はい',
    noButtonCaption:'キャンセル'
  }  
  public buttonData: any = [
    {
      "key": "createWbs",
      "name": "WBS新規作成",
      "color": "blue"
    },
    {
      "key": "compile",
      "name": "コンパイル",
      "color": "primary"
    },
    {
      "key": "run",
      "name": "実行",
      "color": "primary"
    },
  ]
  
  public targetWbs:wbsModel | undefined

  constructor(
    private wbsUiMacroApiService:WbsUiMacroApiService,
    private wbsService:WbsService,
    private sanitizer: DomSanitizer,
    private backendWbsProtoApiService:BackendWbsProtoApiService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.customerId=this.authService.Account?.customerId??''
    
    // //カセット作成画面からカセット一覧へ飛ぶ時
    // this.activatedRoute.queryParams.subscribe(async (params) => {
    //   if (params['areaId']) {
    //     this.selectedAreaId=params['areaId']
    //   }      
    // })    
    this.initialize() 
  }
  async initialize(wbsId?:string){
    this.loading=true
    
      try {
          const ret = await this.getAreas()
          this.wbsList=structuredClone(ret)

          // //カセット作成画面からカセット一覧へ飛ぶ時
          // if(this.selectedAreaId!==''){
          //   let ret = this.getAreaInfo(this.selectedAreaId,this.wbsList)
          //   if(ret!==null){
          //     this.selectedArea=ret
          //     const cassetteTree =await this.getCassettes(this.selectedAreaId)        
          //     this.wbsList=structuredClone(cassetteTree)        
          //     await this.createIframe()
          //     return
          //   }
          // }

          this.isShowArea=true //エリア表示
          this.selectedArea=null

          await this.createIframe()
          this.loading=false

      } catch (error) {
          this.snackbar.error('画面の初期化が失敗しました')
          this.loading=false
      }
  }
  async getAreas():Promise<wbsModel[]>{
    
    let retval: any=null

    const getFileData$ = this.backendWbsProtoApiService.GetAreas(this.customerId,this.organizaionId)
    let ret = await lastValueFrom(getFileData$).catch(() => {
      return retval
    })
    if (ret) {
      retval= ret.data
    }
    
    // エリアのprofileにサブメニューをセットする
    retval = this.setAreaSubMenu(retval)

    return retval

  }
  setAreaSubMenu(target:wbsModel[]){
    let tmp=structuredClone(target)

    tmp.forEach((element:wbsModel) => {
      if(element.profiles===undefined){
        element.profiles={}
      }
      if(element.profiles===null){
        element.profiles={}
      }
      
      element.profiles.showSubMenu=true
      element.profiles.subMenu=[{ menuCd: 3, menuName: 'カセット表示' },
                                { menuCd: 4, menuName: 'カセット作成・移動・削除' },
                                { menuCd: 7, menuName: 'アップロードアイテム表示' }]
      element.profiles.icon='area'

      if(this.childDataKey==='nodeObjects'){
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            element.nodeObjects=this.setAreaSubMenu(element.nodeObjects)
          }
        }
      }
      if(this.childDataKey==='children'){
        if(element.children!==undefined){
          if(element.children.length>0){
            element.children=this.setAreaSubMenu(element.children)
          }
        }
      }     
    });
    return tmp
  }
  getAreaInfo(areaId:string,wbsData:wbsModel[]):any{
    
    let data=structuredClone(wbsData)

    for (let i = 0; i < data.length; i++) {
      if (data[i].id===areaId) {
        return data[i]          
      }
      if(data[i].nodeObjects!==undefined){
        if(data[i].nodeObjects.length>0){
          let child=this.getAreaInfo(areaId,data[i].nodeObjects)
          if(child!==null){
            return child
          }
        }
      }
    }
    return null

  }

  async createIframe():Promise<any>{
    this.loading=true

    this.settingFileId=''

    let payload={
        "fileId": this.wbsIds,
        "wbsData":this.wbsList,
        "filter": this.filterOn,
        "canUpdateElement":this.canUpdateElement,
        "canAddTop":this.canAddTop,
        "draggableOn":this.draggableOn,
        "childDataKey":this.childDataKey,
        "showSubMenu": this.showSubMenu,
    }

    // ファイル作成(条件、紐づけデータ)
    this.settingFileId = await this.wbsService.createFile(payload)

    const url = `${this.relationSettingPath}/relation/#/wbs-view/${encodeURIComponent(this.settingFileId)}`
    this.targetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)    
  }

  ngOnDestroy(){
    this.wbsService.selectedWbsId=''
    if(this.settingFileId!==''){
      this.deleteFile()
    }    
  }
  async deleteFile(){  
    const deleteFile$ = this.wbsUiMacroApiService.deleteTempFile(this.settingFileId)
    const deleteFile = await lastValueFrom(deleteFile$).catch(() => {
      return null
    })

    return deleteFile
  }
  iframeloaded(value: any) {
    //iframeのloadが2度走る場合があるため、srcを見る
    if (value.target.src !== '') {
      this.loading = false
    }
  }  
  dialogClose(){    
    this.createWbsDialog?.close()
    this.isDialogOpen=false
  }
  @HostListener('window:message', ['$event']) async onMessage(msgEvent: MessageEvent) {
    // 呼び出し元確認入れる
    if (msgEvent.origin === window.location.protocol + this.relationSettingPath) {
      //カセット一覧を表示
      if(msgEvent.data.selectedMenu===3){
        this.router.navigate([ `/wbs-viewer/${msgEvent.data.selectedData.id}/1`]);    //0:閲覧のみ、1:追加可   
      }
      //カセットを作成
      if(msgEvent.data.selectedMenu===4){
        this.router.navigate(["/create-cassette"],{ queryParams: { id: msgEvent.data.selectedData.id } });
      }
      //アップロードしたアイテムの一覧を表示
      if(msgEvent.data.selectedMenu===7){
        this.router.navigate([ `/select-wbs-item/${msgEvent.data.selectedData.id}`]);    //0:閲覧のみ、1:追加可   
      }
    }   
  }
}
