<!-- <app-isbeauth></app-isbeauth> -->
<div class="l-app" *ngIf="dispContent; else elseBlock">
  <app-page-header (logout)="logout()"></app-page-header>
  <app-side-nav-menu #nav *ngIf="showNav"></app-side-nav-menu>
  <main class="l-main" [@navOpen]="navDisp ? '' : 'close'">
    <app-check-server-update></app-check-server-update>
    <router-outlet></router-outlet>
  </main>
</div>
<ng-template #elseBlock>
  <div class="loading-mask">
    <div class="loading-msg">ログイン中...</div>
  </div>
</ng-template>