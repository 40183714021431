<!-- <igx-dialog #modaldialog [closeOnOutsideSelect]="true" (closed)="_closed()">
  <div class="c-modal c-modal--800">
    <div class="c-modal__content">
      <div *ngIf="isShowCloseButton" class="c-modal__close">
        <a class="js-modal-close" (click)="modaldialog.close()" data-id=""
          ><i class="c-icon-close"></i
        ></a>
      </div>
      <app-petabo #petabo></app-petabo>
    </div>
  </div>
</igx-dialog> -->

<igx-dialog #modaldialog [closeOnOutsideSelect]="true" (closed)="_closed()">
  <app-petabo #petabo [isDialog]="true" (closed)="modaldialog.close()"></app-petabo>
</igx-dialog>