import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { WBSManagementApiService } from 'src/app/api/wbs-management-api.service';
import { SubMenuModel, WBSCellModel, WBSModel, WBSRequestModel, wbsModel, relationModel, ApiSettings } from 'src/app/interfaces/wbs.model';
import { ActivatedRoute, Router } from '@angular/router';
import { YesNoDialogComponent } from 'src/app/components/dialogs/yes-no-dialog/yes-no-dialog.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { GridSelectionMode, IgxTreeGridComponent } from '@infragistics/igniteui-angular';
import { CommonService } from 'src/app/services/common.service';
import { WbsService } from 'src/app/services/wbs.service';
import { WbsUiMacroApiService } from 'src/app/api/wbs-ui-macro-api';
import { UploadDialogComponent } from 'src/app/components/dialogs/upload-dialog/upload-dialog.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IndexCoreMyideaApiService } from 'src/app/api/indexcore-myidea-api.service';
import { BackendUiMacroApiService } from 'src/app/api/backend-ui-macro-api.service';
import { AREA_WBS, CATALOG_LIST_BC_KANRI,CATALOG_LIST_BC_ALL_AREA,CATALOG_LIST_AREA_1 } from 'src/app/utils/localdata';
import { AuthService } from 'src/app/services/auth.service';
import { BackendWbsProtoApiService } from 'src/app/api/backend-wbs-proto-api';

@Component({
  selector: 'app-create-cassette-v1',
  templateUrl: './create-cassette-v1.component.html',
  styleUrls: ['./create-cassette-v1.component.scss']
})
export class CreateCassetteV1Component implements OnInit {
  @ViewChild('createWbsDialog') createWbsDialog?: YesNoDialogComponent
  @ViewChild('uploadFileDialog') uploadFileDialog?: UploadDialogComponent;
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  @ViewChild('wbsGrid', { read: IgxTreeGridComponent, static: true }) public wbsGrid!: IgxTreeGridComponent
  // @ViewChild('appcondition', { read: CallHubConditionComponent, static: true }) public appcondition!: CallHubConditionComponent
  @ViewChild('dialog') dialog?: YesNoDialogComponent

  public title = 'ドキュメント管理／カセット作成・移動・削除'
  public apiLoading=false
  public loading=false

  public wbsIds: string[] = [];
  public wbsRightIds: string[] = [];
  public wbsList: wbsModel[] = [];
  public wbsRightList: wbsModel[] = [];
  public catalogList: wbsModel[] = [];
  public canUpdateElement:boolean=false
  public canAddTop:boolean=false
  public filterOn:boolean=false  
  public showSubMenu:boolean=false
  public draggableOn:boolean=false
  public childDataKey:string='nodeObjects'

  public targetUrl: SafeUrl | undefined
  private relationSettingPath = environment.relationSetting
  private settingFileLeftId:string=''
  private settingFileRightId: string = '';
  private hubSettingFileId: string = '';
  private catalogSettingFileId: string = '';
  
  public customerId=''
  public organizaionId=''
  public areaId=''

  public dialogParams =
  {
    messageCd: '',
    title: '',
    message: '',
    buttonId: '',
    yesButtonCaption:'',
    noButtonCaption:''
  }
  public dialogParams_wbs_setting =
  {
    messageCd: 'WbsSetting',
    title: 'WBSの設定',
    message: '',
    buttonId: 'WbsSetting',
    yesButtonCaption:'登録',
    noButtonCaption:'キャンセル'
  }
  public dialogParams_add_wbs =
  {
    messageCd: 'addWbs',
    title: 'WBSの追加',
    message: '',
    buttonId: 'addWbs',
    yesButtonCaption:'登録',
    noButtonCaption:'キャンセル'
  }
  public dialogParams_register =
  {
    messageCd: 'register',
    title: 'カセット作成・移動・削除',
    message: '実行しますか？',
    buttonId: 'register',
    yesButtonCaption:'はい',
    noButtonCaption:'キャンセル'
  }
  public buttonData: any = [
    {
      "key": "revert",
      "name": "元に戻す",
      "color": "blue"
    },
    {
      "key": "register",
      "name": "保存",
      "color": "primary"
    },
  ]

  public mappingLeftApiSettings:ApiSettings={
    methodType: 'get',
    url: '//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/cassette/areamixed/list/{customerId}',
    param: {"customerId":"","areaId":""},
    payload:null,
    returnType: 'multiple',
    returnModel: 'wbsModel'
  }
  public catalogApiSettings:ApiSettings={
    methodType: 'get',
    url: '//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/area/{customerId}',
    param: {"customerId":"","targetId":""},
    payload:null,
    returnType: 'multiple',
    returnModel: 'wbsModel'
  }
  public catalogAreaMixedApiSettings:ApiSettings={
    methodType: 'get',
    url: '//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/areamixed/{areaId}',
    param: {"customerId":"","areaId":""},
    payload:null,
    returnType: 'multiple',
    returnModel: 'wbsModel'
  }
  public catalogElementApiSettings:ApiSettings={
    methodType: 'get',
    url: '//backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/{catalogId}',
    param: {"customerId":"","areaId":"","catalogId":""},
    payload:null,
    returnType: 'multiple',
    returnModel: 'wbsModel'
  }

  constructor(
    private wbsUiMacroApiService:WbsUiMacroApiService,
    private wbsService:WbsService,
    private sanitizer: DomSanitizer,
    private backendWbsProtoApiService:BackendWbsProtoApiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.customerId=this.authService.Account?.customerId??''

    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.areaId=params['id']
      }
    });
    this.initialize() 
  }
  async initialize(wbsId?:string){
    this.loading=true
    try {
        const wbsList = await this.getData()
        this.wbsList=structuredClone(wbsList)        
        this.wbsRightList =[]

        this.catalogList=await this.getCatarogData()

        await this.createIframe()
        this.loading=false

    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }
  async getData():Promise<wbsModel[]>{
    
    let retval: any=null

    const getFileData$ = this.backendWbsProtoApiService.GetAreaCassettes(this.customerId,this.areaId)
    let ret = await lastValueFrom(getFileData$).catch(() => {
      return retval
    })
    if (ret) {
      retval= ret.data
    }
    
    retval = this.wbsService.setIcon(retval,this.childDataKey)
    retval = this.wbsService.setUnMovablWbsType(retval,['area'],this.childDataKey)
    // retval = this.excludeFiles(retval)　//ファイルを非表示にする？

    return retval

  }
  async getCatarogData():Promise<wbsModel[]>{
    let retval: any=null

    const getFileData$ = this.backendWbsProtoApiService.GetCatalog(this.customerId,this.organizaionId)
    let ret = await lastValueFrom(getFileData$).catch(() => {
      return retval
    })
    if (ret===null) {
      retval=[]
    }
    else{
      retval= ret.data
    }
    // retval=[
    //     {
    //         "wbsType": "template",
    //         "parentId": "4a534690-65b9-424d-ab26-30aa3ab8a413",
    //         "ownerId": "9e5fe126-c443-4003-8d89-bbe05fd90743",
    //         "authDivision": 1,
    //         "creatorId": "9e5fe126-c443-4003-8d89-bbe05fd90743",
    //         "createdAt": "2023-09-14T03:52:46.8463175+00:00",
    //         "lastUpdatedAt": "2023-09-14T03:52:46.8463175+00:00",
    //         "profiles": {},
    //         "nodes": [],
    //         "nodeObjects": [],
    //         "mappings": {},
    //         "limitedNodes": [],
    //         "children": [],
    //         "id": "59fcffd1-6096-4ac3-97d4-f67426275667",
    //         "name": "4階層  会社―事業―部―部門"
    //     },
    //     {
    //         "wbsType": "template",
    //         "parentId": "4a534690-65b9-424d-ab26-30aa3ab8a413",
    //         "ownerId": "15905acb-dbdb-4912-a3e2-fdcff8d362f1",
    //         "authDivision": 1,
    //         "creatorId": "15905acb-dbdb-4912-a3e2-fdcff8d362f1",
    //         "createdAt": "2023-09-14T03:53:36.4956102+00:00",
    //         "lastUpdatedAt": "2023-09-14T03:53:36.4956102+00:00",
    //         "profiles": {},
    //         "nodes": [],
    //         "nodeObjects": [],
    //         "mappings": {},
    //         "limitedNodes": [],
    //         "children": [],
    //         "id": "2c4ad0d1-0b24-4d06-b671-1a40a998cbd1",
    //         "name": "3階層  会社―部―部門"
    //     },
    //     {
    //         "wbsType": "template",
    //         "parentId": "4a534690-65b9-424d-ab26-30aa3ab8a413",
    //         "ownerId": "15905acb-dbdb-4912-a3e2-fdcff8d362f1",
    //         "authDivision": 1,
    //         "creatorId": "15905acb-dbdb-4912-a3e2-fdcff8d362f1",
    //         "createdAt": "2023-09-14T03:53:36.4956102+00:00",
    //         "lastUpdatedAt": "2023-09-14T03:53:36.4956102+00:00",
    //         "profiles": {},
    //         "nodes": [],
    //         "nodeObjects": [],
    //         "mappings": {},
    //         "limitedNodes": [],
    //         "children": [],
    //         "id": "fe073365-abc1-40a9-bf76-17f2906748c8",
    //         "name": "3階層  会社―部―課"
    //     },
    //     {
    //         "wbsType": "template",
    //         "parentId": "4a534690-65b9-424d-ab26-30aa3ab8a413",
    //         "ownerId": "15905acb-dbdb-4912-a3e2-fdcff8d362f1",
    //         "authDivision": 1,
    //         "creatorId": "15905acb-dbdb-4912-a3e2-fdcff8d362f1",
    //         "createdAt": "2023-09-14T03:53:36.4956102+00:00",
    //         "lastUpdatedAt": "2023-09-14T03:53:36.4956102+00:00",
    //         "profiles": {},
    //         "nodes": [],
    //         "nodeObjects": [],
    //         "mappings": {},
    //         "limitedNodes": [],
    //         "children": [],
    //         "id": "5843ee4d-4cf5-4418-a8fa-c8b91a600d56",
    //         "name": "その他（カスタム入力）"
    //     }
    // ]
    retval = this.wbsService.setIcon(retval,this.childDataKey)

    return retval
  }
  // カセット作成画面なので、ファイルを除く(API修正してもらった方がいいかも？)
  excludeFiles(target:wbsModel[]){
    let tmp=structuredClone(target)

    tmp=tmp.filter((x:wbsModel)=>x.wbsType!=='file')

    tmp.forEach((element:wbsModel) => {
        if(element.nodeObjects.length>0){
          element.nodeObjects = this.excludeFiles(element.nodeObjects)
        }
      }
    )
    return tmp
  }

  async createIframe():Promise<any>{
    this.loading=true

    // 左側ファイル作成(条件、紐づけデータ)
    this.settingFileLeftId=''
    let payload={
        type:'wbsLeft',
        // fileId: this.wbsIds, //これなんだっけ。いらないかも
        wbsData:this.wbsList,
        filter: this.filterOn,
        canUpdateElement:this.canUpdateElement,
        canAddTop:this.canAddTop,
        addObjectType:'',
        canChangeOrder: false,        
        draggableOn:this.draggableOn,
        childDataKey:this.childDataKey,
        canMapMultiple: false,
        canMoveToLeft:true,
        canMoveToRight:true,
        hideRowSelectors:true,
        showHubButton:false,
        showCatalogButton:false,
        hubSettingFileId:'',
        catalogSettingFileId:'',
        isImmediateUpdate:false,
        customerId:this.customerId,
        catalogGetApiInfo:this.catalogAreaMixedApiSettings,
        catalogElementGetApiInfo:this.catalogElementApiSettings
    }

    // ファイル作成(条件、紐づけデータ)
    this.settingFileLeftId = await this.wbsService.createFile(payload)

    // 右側に含まれるHUBファイル作成
    payload.type='hub',
    // payload.fileId = []; //これなんだっけ。いらないかも
    payload.wbsData = [];
    payload.canAddTop=true
    payload.addObjectType='cassette'
    payload.hideRowSelectors=false
    this.hubSettingFileId = await this.wbsService.createFile(payload);

    // 右側に含まれるHUB（カタログ選択）ファイル作成
    payload.type='catalog',    
    // payload.fileId = []; //これなんだっけ。いらないかも
    // payload.wbsData = CATALOG_LIST_BC_ALL_AREA;
    // payload.wbsData = CATALOG_LIST_AREA_1[0].nodeObjects;
    payload.wbsData = this.catalogList
    payload.canAddTop=false
    payload.hideRowSelectors=false    
    this.catalogSettingFileId = await this.wbsService.createFile(payload);

    // 右側ファイル作成(条件、紐づけデータ)
    payload.type='wbsRight',
    // payload.fileId = this.wbsRightIds; //これなんだっけ。いらないかも
    payload.wbsData = this.wbsRightList;
    payload.canAddTop=false
    payload.hideRowSelectors=true
    payload.showHubButton=true
    payload.showCatalogButton=true
    payload.hubSettingFileId=this.hubSettingFileId
    payload.catalogSettingFileId=this.catalogSettingFileId
    this.settingFileRightId = await this.wbsService.createFile(payload);

    if (this.settingFileRightId === '') {
      this.snackbar.error('紐づけ設定画面の初期化が失敗しました');
      this.loading = false;
      return;
    }
    // const url = `${this.relationSettingPath}/relation/#/wbs-view/${encodeURIComponent(this.settingFileId)}`
    const url = `${this.relationSettingPath}/relation/#/mapping/${encodeURIComponent(this.settingFileLeftId)}/${encodeURIComponent(this.settingFileRightId)}`;
    this.targetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)    
  }

  ngOnDestroy(){
    this.wbsService.selectedWbsId = '';
    if (this.settingFileLeftId !== '') {
      this.deleteFile(this.settingFileLeftId);
    }
    if (this.settingFileRightId !== '') {
      this.deleteFile(this.settingFileRightId);
    }  
  }
  async deleteFile(id: string) {
    const deleteFile$ = this.wbsUiMacroApiService.deleteTempFile(id);
    const deleteFile = await lastValueFrom(deleteFile$).catch(() => {
      return null;
    });

    return deleteFile;
  }
  iframeloaded(value: any) {
    //iframeのloadが2度走る場合があるため、srcを見る
    if (value.target.src !== '') {
      this.loading = false
    }
  }  
  @HostListener('window:message', ['$event']) async onMessage(
    msgEvent: MessageEvent
  ) {
    // 呼び出し元確認
    if (
      msgEvent.origin ===
      window.location.protocol + this.relationSettingPath
    ) {
      // 右枠で要素が追加されたら、まだ登録はしないけど、ワークデータだけ更新されてる
      if (msgEvent.data === 'upsertWbs_file_created') {
        // let isDirectL = false;
        // let isDirectR = false;
        // let ret = this.wbsService.registerOrgProfileRelation(
        //   this.settingFileLeftId,
        //   this.organizationId,
        //   isDirectL,
        //   isDirectR
        // );
        // if (ret === null) {
        //   // this.snackbar.error('処理が失敗しました')
        // } else {
        //   // this.snackbar.success('登録しました')
        //   this.initialize();
        // }
        let ret = await this.wbsService.getFileData(
          this.settingFileRightId
        );
        if (ret === null) {
          this.snackbar.error('処理が失敗しました')
        }
        else{
          this.wbsRightList.push(ret)
        }        

        return;
      }
      //紐づけ画面のファイル保存が終わったら
      if(msgEvent.data === 'mapped_file_created'){
        this.registerRelation()
      }
    }
  }
  buttonClick(id: string){
    if(id==='revert'){
      this.initialize() 
    }
    if(id==='register'){
      this.dialogParams = this.dialogParams_register
      this.dialog?.open()      
    }
  }
  dialogCancel(){
    if(this.apiLoading) return    
    this.dialog?.close()
  }
  clickRun(){
    const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement
    const iframe = element.contentWindow
    if (iframe !== null) {
      iframe.postMessage('register', '*')
    }
  }
  async registerRelation(){
    this.apiLoading=true
    //追加カセット取得（左側のWBSから追加されたデータを拾う）
    let ret = await this.wbsService.getFileData(this.settingFileLeftId);
    if (ret === null) {
      this.snackbar.error('登録処理が失敗しました')
      this.apiLoading=false
      return      
    } 

    //カセット登録処理
    let newTree=JSON.parse(ret.data).wbsData
    let newData=this.getNewData(newTree,newTree)

    if(newData.length>0){
      const postCassettes$ = this.backendWbsProtoApiService.PostCassetteMany(newData)
      const postCassettes = await lastValueFrom(postCassettes$).catch(() => {
        this.apiLoading=false
        return null
      })
      if(postCassettes){
          // fileId=postSettings.data
      }    
    }

    //紐づけ削除処理
    //削除カセット取得（右側のWBSから削除データを拾う）
    let delret = await this.wbsService.getFileData(this.settingFileRightId);
    if (delret === null) {
      this.snackbar.error('削除処理が失敗しました')
      this.apiLoading=false
      return      
    } 

    //カセット削除処理
    let delTree=JSON.parse(delret.data).wbsData
    let delData=this.wbsService.getDelData(delTree)

    if(delTree.length>0){
      const delCassettes$ = this.backendWbsProtoApiService.DeleteCassetteMany(delData)
      const delCassettes = await lastValueFrom(delCassettes$).catch(() => {
        this.apiLoading=false
        return null
      })
      if(delCassettes){
          // fileId=postSettings.data
      }    
    }

    this.snackbar.success('登録しました')
    this.dialog?.close()
    this.apiLoading=false
    this.initialize()
  }

  getParentInfo(data:wbsModel[],targetId:string):wbsModel|null{
    for (let i = 0; i < data.length; i++) {
      if (data[i].id===targetId) {
          return data[i]          
      }
      if(data[i].nodeObjects.length>0){
        let child=this.getParentInfo(data[i].nodeObjects,targetId)
        if(child!==null){
          return child
        }
      }
    }
    return null
  }
  getNewData(newTree:wbsModel[],topWbs:wbsModel[]){
    let newData:any[]=[]
    newTree.forEach((element: wbsModel) => {
      if(element.id.substring(0,3)==='new'){
        let parentData=this.getParentInfo(topWbs,element.parentId)

        //★★暫定 ownerIdは親がエリアの時は親と同じId。親がカセットの時はログイン顧客Id
        //         ownerIdは本当は画面で選択できるようにしたい。
        let item={id:null,
                  parentId:element.parentId,
                  name:element.name,
                  ownerId:parentData?.wbsType===element.wbsType?parentData?.ownerId:this.customerId}
        newData.push(item)
      }
      else{
        if(element.nodeObjects!==undefined){
          if(element.nodeObjects.length>0){
            let child = this.getNewData(element.nodeObjects,topWbs)
            if(child!==null){
              newData=newData.concat(child)
            }
          }
        }
      }
    });
    return newData
  }
  // ↓wbs.serviceに移動　※動作確認終わったら消す
  // getDelData(delTree:wbsModel[]){
  //   let delData:any[]=[]
  //   delTree.forEach((element: wbsModel) => {
  //     if(element.id.substring(0,3)!=='new'){
  //       let item={id:element.id,
  //                 parentId:element.parentId}
  //       delData.push(item)
  //     }
  //     else{
  //       if(element.nodeObjects!==undefined){
  //         if(element.nodeObjects.length>0){
  //           let child = this.getDelData(element.nodeObjects)
  //           if(child!==null){
  //             delData=delData.concat(child)
  //           }
  //         }
  //       }
  //     }
  //   });
  //   return delData
  // }
  gotoArea(){
    this.router.navigate([ `/select-area`]);
    // this.router.navigate([ `/select-area-v1`]);
  }
  gotoCassetteList(){
    this.router.navigate([ `/wbs-viewer/${this.areaId}/1`]);    //0:閲覧のみ、1:追加可  
  }
}
