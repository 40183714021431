import { Component } from '@angular/core';

@Component({
  selector: 'app-loding-circle',
  templateUrl: './loding-circle.component.html',
  styleUrls: ['./loding-circle.component.scss']
})

export class lodingCircleComponent {

  constructor() { }

}
