import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { catchError } from 'rxjs/operators';
import { Actionfile, ResultInfo, ResultInfoFile, ResultInfoSingle} from 'src/app/interfaces'
import { WBSModel, WBSRequestModel, wbsModel } from '../interfaces/wbs.model';
import { ApiErrorHandlingService } from './api-error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class BackendWbsProtoApiService {
  private path = environment.backendWbsProtoApi
  private basePath = this.path + '/'
  constructor(
    private http: HttpClient,
    private apiErrorHandlingService: ApiErrorHandlingService
    ) { }  

  /**
   * ファイルUL用のHttpOpttion
   */
  private uploadHttpOptions: any = {
    headers: new HttpHeaders({
      // Authorization: this.authService.Token,
      'X-Requested-With': 'XMLHttpRequest',
    })
  };  
  /**
   * ファイルDL用のHttpOpttion
   */
  private fileGethttpOptions: any = {
    headers: new HttpHeaders({
      Accept: 'plain/text',
      observe: 'response',
    }),
    observe: 'response' as 'body',
    responseType: 'blob' as 'json', // We accept plain text as response.
  };
  
  /**
  * エリア取得
  * @param customerId
  * @param organizationId
  */
  GetAreas(customerId:string, organizationId?:string): Observable<ResultInfo<wbsModel>> {
    // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/area/e8dc2fce-adcf-4e19-af0a-5ed7d3644c0a
     let requestUrl = `${this.path}/api/area/${customerId}`
     if(organizationId!==undefined){
      requestUrl=requestUrl + `?targetId=${organizationId}`
     }

    //  let requestUrl ="https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/wbsItem/management/list?wbsItemId=d24086a9-55f3-4329-a230-797ca71e05bb"
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }

  /**
  * カセット取得
  * @param customerId
  * @param organizationId
  */
  GetCassettes(areaId:string, customerId:string,authDivision:number): Observable<ResultInfo<wbsModel>> {
    // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/cassette/805ed10e-7ee5-4c4e-a6ab-8d8a52a41d7f/list?customerId=15905acb-dbdb-4912-a3e2-fdcff8d362f1&authDivision=1'
     let requestUrl = `${this.path}/api/cassette/${areaId}/list?customerId=${customerId}&authDivision=${authDivision}`
     
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  * エリア＆カセット取得
  * @param customerId
  * @param organizationId
  */
  GetAreaCassettes(customerId:string, areaId?:string): Observable<ResultInfo<wbsModel>> {
  // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/cassette/areamixed/list/858f4a6c-680f-44ca-b4e9-a380127f891e
    let requestUrl = `${this.path}/api/cassette/areamixed/list/${customerId}`
    if(areaId!==undefined){
      requestUrl=requestUrl + `?areaId=${areaId}`
      }
      
    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  * ファイルアップロード  ★★1/24切替済み
  * @param fileSystemId 
  */
  PostFileUpload(fileSystemId : string, file: FormData,name?:string):Observable<Actionfile> {    
    let requestUrl = `${this.path}/api/indexWbs/${fileSystemId }/file/upload`  
    if(name!==undefined){
      requestUrl=requestUrl + `?name=${name}`
      }
    return this.http
      .post<Actionfile>(requestUrl, file, this.uploadHttpOptions)
      .pipe(catchError(this.apiErrorHandlingService.handleError<Actionfile>('postFileUpload')));
  }
  /**
  * ファイルダウンロード  ★★1/24切替済み
  * @param fileId
  */
  GetFileDownload(fileSystemId:string): Observable<ResultInfoFile> {    
    const requestUrl = `${this.path}/api/indexWbs/file/${fileSystemId}/download`

    return this.http.get<ResultInfoFile>(requestUrl, this.fileGethttpOptions)
            .pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfoFile>('GetFileDownload')));   
  }
  /**
  *エリア・エリア紐づけ登録(複数)
  * @param payload
  */
  PostAreaMany(payload: any): Observable<ResultInfo<wbsModel>> {
    // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/area/children
    let requestUrl = `${this.basePath}api/area/children`    
    return this.http.post<ResultInfo<wbsModel>>(requestUrl, payload)
  }
  /**
  *エリア・エリア紐づけ削除(複数)
  * @param payload
  */
  DeleteAreaMany(payload: any): Observable<any> {
    // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/area/children
    let requestUrl = `${this.basePath}api/area/children`    
    return this.http.request('delete', requestUrl, { body: payload});
  }
  /**
  *カセット・カセット紐づけ登録(複数)
  * @param payload
  */
  PostCassetteMany(payload: any): Observable<ResultInfo<wbsModel>> {
    // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/cassette/many
    let requestUrl = `${this.basePath}api/cassette/many`    
    return this.http.post<ResultInfo<wbsModel>>(requestUrl, payload)
  }
  /**
  *カセット・カセット紐づけ削除(複数)
  * @param payload
  */
  DeleteCassetteMany(payload: any): Observable<any> {
    // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/cassette/relation/many
    let requestUrl = `${this.basePath}api/cassette/relation/many`    
    return this.http.request('delete', requestUrl, { body: payload});
  }
    /**
  * エリア＆カセット取得
  * @param customerId
  * @param targetId
  */
    GetCatalog(customerId:string, targetId?:string): Observable<ResultInfo<wbsModel>> {
        // https://backend-healthcheckupmacroapi.backend.out.nakamenosakura.com/api/v1/catalog/area/858f4a6c-680f-44ca-b4e9-a380127f891e?targetId=9e5fe126-c443-4003-8d89-bbe05fd90743
        let requestUrl = `${this.path}/api/v1/catalog/area/${customerId}`
        if(targetId!==undefined){
          requestUrl=requestUrl + `?targetId=${targetId}`
          }
          
        return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
      }
  
  /**
  * Viewer用データ取得
  * @param cassetteId
  */  
    GetWbsForViewer(fileId:string,rootOnly:boolean,map:string): Observable<ResultInfo<wbsModel>> {
      // 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/indexWbs/nodeObjects/all?fileSystemId=86cf520e-982e-41fd-97e0-b68398ae5ef5&rootOnly=false'
      let requestUrl = `${this.path}/api/indexWbs/nodeObjects/all/?fileSystemId=${fileId}&rootOnly=${rootOnly}&map=${map}`

      return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
    }
  /**
  * エリアからWbsアイテム取得
  * @param areaId
  */  
  GetWbsItemByArea(areaId:string): Observable<ResultInfo<wbsModel>> {
    // https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/wbsItem/list?fileSystemId=d24086a9-55f3-4329-a230-797ca71e05bb
    let requestUrl = `${this.path}/api/wbsItem/list?fileSystemId=${areaId}`

    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
  /**
  * WbsアイテムViewer用データ取得
  * @param fileId
  */  
  GetWbsItemForViewer(fileId:string): Observable<ResultInfo<wbsModel>> {
    // 'https://backend-wbsprotoapi.backend.out.nakamenosakura.com/api/wbsItem/wbs?wbsItemId=1e58829e-cf7e-4e8d-b856-8fc3193089c2'
    let requestUrl = `${this.path}/api/wbsItem/wbs?wbsItemId=${fileId}`

    return this.http.get<ResultInfo<wbsModel>>(requestUrl)    
  }
}
