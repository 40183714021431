import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss'],
})
export class YesNoDialogComponent implements OnInit {
  @ViewChild('modaldialog', { read: IgxDialogComponent, static: true })
  modaldialog: IgxDialogComponent | undefined;
  @Input() headerMessage = '';
  @Input() message = '';
  @Input() buttonId = '';
  @Input() isShowCloseButton = true;
  @Input() yesButtonCaption = 'はい';
  @Input() noButtonCaption = 'いいえ';
  @Output() clickYesButton = new EventEmitter();
  @Output() clickNoButton = new EventEmitter();
  @Output() closed = new EventEmitter();
  isOpen = false;
  constructor() { }

  ngOnInit(): void { }
  open() {
    if (this.modaldialog) this.modaldialog.open();
  }
  close() {
    if (this.modaldialog) this.modaldialog.close();
  }
  _clickYesButton() {
    this.clickYesButton.emit(this.buttonId);
  }
  _clickNoButton() {
    this.clickNoButton.emit(this.buttonId);
  }
  /**
   * 閉じたあとのイベント
   */
  _closed() {
    this.closed.emit()
  }

}
