import { Component,  OnInit, ViewChild } from '@angular/core';
import { AreaMappingData, AreaMappingHubData } from '../localdata';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { WbsService } from 'src/app/services/wbs.service';
import { FrontEndParams } from '@interserve/igx-angular';

@Component({
  selector: 'app-create-arear',
  templateUrl: './create-area.component.html',
  styleUrls: ['./create-area.component.scss']
})
export class CreateAreaComponent implements OnInit {
  @ViewChild('snackbar', { static: true }) private snackbar!: SnackbarComponent
  
  public frontendCd="areaMappingV2"
  public frontendCdHub="areaMappingHub"
  public title = 'エリア作成'

  public apiLoading=false
  public loading=false

  public customerId=''

  //紐づけライブラリ用
  public mappingParams!: FrontEndParams;
  public hubParams!: FrontEndParams;

  constructor(
    private wbsService:WbsService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.customerId=this.authService.Account?.customerId??''
    
    this.initialize() 
  }
  async initialize(){
    this.loading=true    
    try {
        // // 設定ファイルver.1
        // // this.mappingData = structuredClone(AreaMappingData) 
        // let settings = await this.wbsService.getFrontendSettings(this.appCd, this.frontendCd)
        // if(settings!==null){
        //   settings.actions.GetAreas.apiInfo.urlObject.customerId = this.customerId     
        //   this.mappingData=settings
        // } 
        // // this.mappingDataHub = structuredClone(AreaMappingHubData) 
        // let hubsettings = await this.wbsService.getFrontendSettings(this.appCd, this.frontendCdHub)
        // if(hubsettings!==null){
        //   this.mappingDataHub=hubsettings
        // } 

        //フロントエンド設定を紐づけに渡す
        let param={customerId:this.customerId}
        this.mappingParams = this.wbsService.setFrontendParams(this.frontendCd, param)
        this.hubParams = this.wbsService.setFrontendParams(this.frontendCdHub, param)

        this.loading=false
    } catch (error) {
        this.snackbar.error('画面の初期化が失敗しました')
        this.loading=false
    }
  }  
  clickSubMenu(value: any){
    console.log('clickSubMenu:' + value)
  }
  clickPlusElement(value: any){
    //デフォルトの要素追加ダイアログを使わない場合はここで独自ダイアログを呼び出す（My-ideaの顧客登録などと連携）
    console.log('clickPlusElement:'+value)
  }
}
