<div class="l-main__contents">
  <div id="wrapper">
    <div class="c-text-1 h-margin-b24">{{title}}</div>
    <div class="grid-area">
      <div class="loading" *ngIf="loading">
        <app-loding-circle></app-loding-circle>
      </div>
      <div class="list-header">
        <a class="goto-area" (click)="gotoArea()">
          エリア選択に戻る
        </a>
      </div>
      <div class="update-wbs-item-wbs-item">
        <is-igx-mapping-wbs-item *ngIf="!loading" 
          [mappingData]="mappingData" 
          [mappingDataHub]="mappingDataHub"
          [mappingDataCatalogHub]="mappingDataCatalogHub"
          (clickSubMenu)="clickSubMenu($event)" 
          (clickPlusElement)="clickPlusElement($event)">
        </is-igx-mapping-wbs-item>
      </div>
    </div>
  </div>
</div>
<app-snackbar #snackbar [autoHide]="true"></app-snackbar>